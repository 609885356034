import axios from "axios";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import HospitalSurvey from "../components/HospitalSurvey";
import UserMailOtpVerification from "../components/UserMailOtpVerification";

class BeforeLoginMenu extends Component {
  constructor(props) {
    super(props);
    this.refClose = React.createRef();
    this.state =
    {
      DonationPopup: false,
      UserMailNeed: false,
      OtpNeed: false,
      usermail: '',
      userOtp: '',
      isSuccess: false,
      message: "",
      inProgress: false,
      SurveyUserMail: sessionStorage.getItem("SurveyUserMail"),
      SurveyUserMailVeryfied: false
    }
    this.goTocheckout = this.goTocheckout.bind(this);
    this.openmailform = this.openmailform.bind(this);
    this.sendOTP = this.sendOTP.bind(this);
    this.openotpform = this.openotpform.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.alertChange = this.alertChange.bind(this);
    this.openOpoup = this.openOpoup.bind(this);
  }

  openmailform() {
    this.setState(prevState => ({
      UserMailNeed: !prevState.UserMailNeed,
      OtpNeed: false,
      inProgress: false
    }))
    this.alertChange()
  }

  goTocheckout() {
    document.body.classList.remove('donation_open');
    sessionStorage.setItem("email", this.state.usermail);
    this.props.history.push("/donation");
  }

  sendOTP(e) {
    e.preventDefault()
    this.alertChange()
    if (this.state.usermail != null && this.state.userOtp != null) {
      const val1 = document.querySelector(
        `input[name=otp-1]`
      );
      const val2 = document.querySelector(
        `input[name=otp-2]`
      );
      const val3 = document.querySelector(
        `input[name=otp-3]`
      );
      const val4 = document.querySelector(
        `input[name=otp-4]`
      );
      const data = {
        email: this.state.usermail,
        Otp: val1.value.toString() + val2.value.toString() + val3.value.toString() + val4.value.toString()
      }
      this.setState({
        inProgress: true
      });
      axios
        .post(
          `${process.env.REACT_APP_API_URL}api/Donation/donationUserOtpVerify`, data
        )
        .then((response) => {
          if (response.data.isSuccess) {
            this.goTocheckout()
          }
          else {
            this.setState({
              isSuccess: false,
              message: response.data.message,
              inProgress: false
            });
          }

        })
        .catch((error) => {
          this.setState({
            isSuccess: false,
            inProgress: false,
            message: "Mail not Sent!!"
          });
        });
    }
  }

  openotpform(e) {
    e.preventDefault();
    this.alertChange()
    const data = {
      email: this.state.usermail,
      Otp: ""
    }
    this.setState({
      inProgress: true
    });
    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/Donation/donationUserSendOtp`, data
      )
      .then((response) => {
        if (response.data.isSuccess) {
          this.setState(prevState => ({
            UserMailNeed: !prevState.UserMailNeed,
            OtpNeed: !prevState.OtpNeed,
            inProgress: false
          }));
        }
        else {
          this.setState({
            isSuccess: false,
            message: response.data.message,
            inProgress: false
          });
        }

      })
      .catch((error) => {
        this.setState({
          isSuccess: false,
          inProgress: false,
          message: "Mail not Sent!!"
        });
      });
  }

  // componentDidMount() {
  //   window.addEventListener('scroll', this.handleScroll);
  // }
  // componentWillUnmount() {
  //   window.removeEventListener('scroll', this.handleScroll);
  // }

  componentDidUpdate() {
    document.body.classList.toggle('donation_open', this.state.DonationPopup);
  }

  handleChange(e) {
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("-");
    let fieldIntIndex = parseInt(fieldIndex, 10);
    // Check if no of char in field == maxlength
    if (value.length >= maxLength && !isNaN(+value)) {

      // It should not be last input field

      if (fieldIntIndex < 4) {

        // Get the next input field using it's name
        const nextfield = document.querySelector(
          `input[name=otp-${fieldIntIndex + 1}]`
        );


        // If found, focus the next field
        if (nextfield !== null) {
          nextfield.removeAttribute("disabled")
          nextfield.focus();
        }
      }
    }

  };

  // handleScroll() {
  //   const donation = document.querySelector('.donation_btn');
  //   let height = Math.round(window.innerHeight + 30);
  //   if (document.body.getBoundingClientRect().bottom <= height) {
  //     donation.classList.add('is-bottom')
  //   } else {
  //     donation.classList.remove('is-bottom')
  //   }
  // }

  alertChange() {
    this.setState({
      isSuccess: false,
      inProgress: false,
      message: ""
    });
  }

  openOpoup() {
    this.setState({
      DonationPopup: !this.state.DonationPopup,
      UserMailNeed: false,
      OtpNeed: false,
      isSuccess: false,
      inProgress: false,
      message: ""
    });
  }

  handleCallback = (childData) => {
    console.log(childData)
    this.setState({ SurveyUserMailVeryfied: childData })
  }

  handleCallbackClose = (childData) => {
    this.refClose.current.click();
    // if(childData.isSuccess)
    // {

    // }
    window.alert(childData.message);
  }

  render() {
    return (
      <>
        <div className="top-header">
          <div className="container">
            <div className="row justify-content-end">
              {/* <div className="col-md-8 col-xs-12">
                <p></p>
              </div> */}
              <div className="col-12 text-right">
                <ul className="list-inline">
                  <li className="list-inline-item font-weight-bold">
                    <Link to="/registration">Sign up for Rates/Try Vindex</Link>
                  </li>
                  <li className="list-inline-item">|</li>
                  <li className="list-inline-item font-weight-bold">
                    <Link to="/login">Sign In</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <header>
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light ">
              <Link className="navbar-brand" to="/">
                <img src="images/main-logo.png" width="" height="" alt="" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarCollapse"
                aria-controls="navbarCollapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarCollapse">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item font-weight-bold ">
                    <Link to="/" className="nav-link text-uppercase">
                      Home
                    </Link>
                  </li>
                  <li className="nav-item font-weight-bold">
                    <Link
                      to="/inventoryList"
                      className="nav-link text-uppercase"
                    >
                      Inventory List
                    </Link>
                  </li>
                  <li className="nav-item font-weight-bold">
                    <Link
                      to="/rxwholesaler"
                      className="nav-link text-uppercase"
                    >
                      Rx Wholesaler
                    </Link>
                  </li>
                  {/* <li className="nav-item font-weight-bold">
                    <Link
                      to="/rxwholesaler"
                      className="nav-link text-uppercase"
                    >
                      Rx Wholesaler
                    </Link>
                  </li> */}
                  {/*  <li className="nav-item font-weight-bold">
                                        <Link
                                            to="/subscription"
                                            className="nav-link text-uppercase"
                                        >
                                            Subscription
                                        </Link>
                                    </li>*/}
                  <li className="nav-item font-weight-bold">
                    <Link
                      to="/productandservices"
                      className="nav-link text-uppercase"
                    >
                      Products & services
                    </Link>
                  </li>
                  {/* <li className="nav-item font-weight-bold">
                    <Link
                      to="/EOBAccuracySurvey"
                      className="nav-link text-uppercase"
                    >
                      EOB SURVEY
                    </Link>
                  </li>*/}
                  <li className="nav-item font-weight-bold">
                    <Link to="/ourMission" className="nav-link text-uppercase">
                      Our Mission
                    </Link>
                  </li>
                  <li className="nav-item font-weight-bold">
                    <Link
                      to="/partner"
                      className="nav-link text-uppercase "
                    >
                      Our Partners
                    </Link> 
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </header>

        {/* for donation */}
        {/* <div className="donation_btn floting_btn" onClick={() => this.openOpoup()}>
          Combat High Healthcare Costs
        </div>
        <div className="hospital_survey_btn floting_btn" data-toggle="modal" data-target="#Hospital_Survey" title="Hospital Survey">
          Hospital Survey
        </div> */}

        {/* <div className={`donation_popup ${this.state.DonationPopup ? "active" : ""}`}>
          <div className="donation_header">
            <h3>Help us fight the high cost of healthcare</h3>
            <button type="button" className="close_btn" onClick={() => this.openOpoup()}><i className="fa fa-times" aria-hidden="true"></i></button>
          </div>

          <div className="donation_content">
            {
              !this.state.UserMailNeed && !this.state.OtpNeed ?
                <>
                  <p>
                    Medical debt and unaffordable healthcare is a national crisis.  Worse, when we see the wild price variations at a hospital for the same treatment we may deduce that a person is being burdened with debt when their payment has already exceeded the lowest amount the hospital accepts as payment in full.  Much of this can be alleviated or eliminated when consumers are aware of the price of healthcare upfront and if lower cost alternatives exist in their metro area. Text messages aren't free and it requires us to ask for donations to provide this service. For two pennies you can help a person get the care they need at a price they can afford.  Please <span onClick={() => this.openmailform()}>click here</span> to donate.  Thank you.
                  </p>
                </> :
                this.state.UserMailNeed && !this.state.OtpNeed ?
                  <>
                    <div className="mt-5 text-center">
                      <form onSubmit={this.openotpform}>
                        <input type="email" required placeholder="Enter Your Email" className="form-control text-center mb-3" onChange={(e) => this.setState({ usermail: e.target.value })}></input>
                        <button type="submit" disabled={this.state.inProgress} className="btn btn-orange mb-2">Send OTP</button>
                        {
                          !this.state.isSuccess && this.state.message != "" ?
                            <div className="row justify-content-center"><div className="col-md-3"></div><div className="col-md-6"><div className="alert alert-danger alert-dismissible fade show" role="alert">
                              {this.state.message}
                              <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.alertChange}>
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div></div><div className="col-md-3"></div></div> : ''
                        }
                      </form>
                    </div>
                  </>
                  :
                  <>
                    <div className="mt-4 text-center" >
                      <p>&nbsp;&nbsp;&nbsp;Enter Your OTP</p>
                      <form onSubmit={this.sendOTP}>
                        <div className="d-flex mt-2">
                          <div className="col-sm-3">
                            <input type="tel" required maxLength="1" minLength="1" pattern="[0-9]" onChange={(e) => this.handleChange(e)} name="otp-1" placeholder="0" className="form-control text-center"></input>
                          </div>
                          <div className="col-sm-3">
                            <input type="tel" required maxLength="1" minLength="1" pattern="[0-9]" onChange={(e) => this.handleChange(e)} name="otp-2" disabled placeholder="0" className="form-control text-center"></input>
                          </div>
                          <div className="col-sm-3">
                            <input type="tel" required maxLength="1" minLength="1" pattern="[0-9]" onChange={(e) => this.handleChange(e)} name="otp-3" disabled placeholder="0" className="form-control text-center"></input>
                          </div>
                          <div className="col-sm-3">
                            <input type="tel" required maxLength="1" minLength="1" pattern="[0-9]" onChange={(e) => this.handleChange(e)} name="otp-4" disabled placeholder="0" className="form-control text-center"></input>
                          </div>
                        </div>
                        <button type="submit" className="btn btn-orange mt-3">Verify OTP</button>
                        {
                          !this.state.isSuccess && this.state.message != "" ?
                            <div className="row justify-content-center mt-2"><div className="col-12"><div className="alert alert-danger alert-dismissible fade show" role="alert">
                              Please enter valid OTP
                              <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.alertChange}>
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div></div></div> : ''
                        }
                      </form>
                    </div>
                  </>
            }


          </div>
        </div> */}

        {/* Survey Notes  */}
        {/* <div className="modal fade" id="Hospital_Survey">
          <div className="modal-dialog  modal-lg modal-dialog-centered">
            <div className="modal-content ">
              <div className="modal-header">
                <h5 className="modal-title">Hospital Survey</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" ref={this.refClose}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body ">
                {
                  this.state.SurveyUserMailVeryfied ?
                    <HospitalSurvey parentCallback={this.handleCallbackClose}></HospitalSurvey>
                    :
                    <UserMailOtpVerification parentCallback={this.handleCallback}></UserMailOtpVerification>
                }


              </div>
            </div>
          </div>
        </div> */}


      </>
    );
  }
}
export default withRouter(BeforeLoginMenu);
