import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import BeforeLoginMenu from "../../routes/BeforeLoginMenu";
import AfterLoginMenu from "../../routes/AfterLoginMenu";
import axios from "axios";
import PartnersImg from "./PartnersImg";

class Partners extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: localStorage.getItem("token"),
      partnersData: null,
    };
    this.getPartners = this.getPartners.bind(this);
  }

  getPartners() {
    const url = "https://hclfilestorage.blob.core.windows.net/webjsons/partners.json";
    axios
      .get(
        `${url}`, {
        headers: {
          'Content-Type': 'application/json'
        },
      }
      )
      .then((response) => {
        this.setState({
          partnersData: response.data
        })
      })
      .catch((error) => {
        console.log(error)
      });
  }

  componentDidMount() {
    this.getPartners()
  }

  render() {
    return (
      <>
        {this.state.token !== null ? <AfterLoginMenu /> : <BeforeLoginMenu />}
        <main role="main">
          <div className="pt-5 mob-pg-pt our-mission">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="text-center text-dark text-bold boldFont">
                    Our Partners
                  </h3>
                </div>
              </div>

              <div className="row my-3 justify-content-center">
                {/* {
                  this.state.partnersData?.data.map(
                    (text, key) =>
                      <div className="col-md-3 col-sm-4 col-6 my-3 mob_partner_col" key={key}>
                        <div className={`our_partners_box ${this.state.partnersData?.data.length - 1 === key ? 'our_partners_last' : ''}`}>
                          <a href={text.website} target={"_blank"}>
                            <img src={text.imageurl} alt={text.imageur} className="img-fluid" />
                          </a>
                        </div>
                      </div>
                  )
                } */}

                {
                  this.state.partnersData?.data.map(
                    (text, key) =>
                      <PartnersImg website={text.website} imageurl={text.imageurl} last={this.state.partnersData?.data.length - 1 === key} key={key}></PartnersImg>
                  )
                }



              </div>
            </div>


          </div>
        </main>
      </>
    );
  }
}
export default withRouter(Partners);
