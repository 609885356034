import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import HospitalSurvey from "../components/HospitalSurvey";

class AfterLoginMenu extends React.PureComponent {
    constructor(props) {
        super(props);
        this.refClose = React.createRef();
        const uid = localStorage.getItem("userID");
        const rxWholesaler = localStorage.getItem("rxWholesaler");
        this.state =
        {
            userId: uid,
            rxWholesaler: JSON.parse(rxWholesaler),
            DonationPopup: false
        }
        this.goTocheckout = this.goTocheckout.bind(this)
    }
    // validating nadac user 
    getNadacvalidUser(uid) {
        axios
            .get(`${process.env.REACT_APP_API_URL}api/Nadac/NadacvalidUser?userid=${uid}`, {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                    secret_key: localStorage.getItem("secret_key"),
                },
            })
            .then((response) => {
                this.setState({ rxWholesaler: response.data.rxWholeSeller })
                localStorage.setItem("rxWholesaler", response.data.rxWholeSeller)
                console.log(response, this.state.rxWholesaler)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    handleCallbackClose = (childData) => {
        this.refClose.current.click();
        window.alert(childData.message);
    }
    goTocheckout() {
        document.body.classList.remove('donation_open');
        this.props.history.push("/donation");

    }
    componentDidMount() {
        // check if localstorage has already rxWholesaler
        // const rxWholesaler = localStorage.getItem("rxWholesaler");
        // if (rxWholesaler === null) {
        //     this.getNadacvalidUser(this.state.userId);

        // }

        // window.addEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate() {
        document.body.classList.toggle('donation_open', this.state.DonationPopup);
    }

    // componentWillUnmount() {
    //     window.removeEventListener('scroll', this.handleScroll);
    // }

    // handleScroll() {
    //     const donation = document.querySelector('.donation_btn');
    //     let height = Math.round(window.innerHeight + 30);
    //     if (document.body.getBoundingClientRect().bottom <= height) {
    //         donation.classList.add('is-bottom')
    //     } else {
    //         donation.classList.remove('is-bottom')
    //     }
    // }


    render() {
        return (
            <>
                <div className="top-header">
                    <div className="container">
                        <div className="row justify-content-end">
                            <div className="col-12 text-right">
                                <ul className="list-inline">
                                    <li className="list-inline-item font-weight-bold">
                                        Hello,{" "}
                                        {localStorage.getItem("userName")}
                                    </li>
                                    <li className="list-inline-item">|</li>
                                    <li className="list-inline-item font-weight-bold">
                                        <Link to="/profile">Profile</Link>
                                    </li>
                                    <li className="list-inline-item font-weight-bold">
                                        |
                                    </li>
                                    <li className="list-inline-item font-weight-bold">
                                        <Link to="/logout">Sign out</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <header>
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-light ">
                            <Link className="navbar-brand" to="/">
                                <img
                                    src="images/main-logo.png"
                                    width=""
                                    height=""
                                    alt=""
                                />
                            </Link>
                            <button
                                className="navbar-toggler"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarCollapse"
                                aria-controls="navbarCollapse"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div
                                className="collapse navbar-collapse"
                                id="navbarCollapse"
                            >
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item font-weight-bold">
                                        <Link
                                            to="/"
                                            className="nav-link text-uppercase"
                                        >
                                            Home
                                        </Link>
                                    </li>
                                    <li className="nav-item font-weight-bold">
                                        <Link
                                            to="/inventoryList"
                                            className="nav-link text-uppercase"
                                        >
                                            Inventory List
                                        </Link>
                                    </li>
                                    {/* {
                                        this.state.rxWholesaler ? <li className="nav-item font-weight-bold">
                                            <Link
                                                to="/rxwholesaler"
                                                className="nav-link text-uppercase"
                                            >
                                                Rx Wholesaler
                                            </Link>
                                        </li> : ''
                                    } */}
                                    <li className="nav-item font-weight-bold">
                                        <Link
                                            to="/rxwholesaler"
                                            className="nav-link text-uppercase"
                                        >
                                            Rx Wholesaler
                                        </Link>
                                    </li>
                                    <li className="nav-item font-weight-bold">
                                        <Link
                                            to="/productandservices"
                                            className="nav-link text-uppercase"
                                        >
                                            Products & services
                                        </Link>
                                    </li>
                                    <li className="nav-item font-weight-bold">
                                        <Link
                                            to="/subscription"
                                            className="nav-link text-uppercase"
                                        >
                                            Subscription
                                        </Link>
                                    </li>
                                    <li className="nav-item font-weight-bold">
                                        <Link
                                            to="/pivotTable"
                                            className="nav-link text-uppercase"
                                        >
                                            Rates
                                        </Link>
                                    </li>
                                    {/*  <li className="nav-item font-weight-bold">
                                        <Link
                                        to="/EOBAccuracySurvey"
                                        className="nav-link text-uppercase"
                                        >
                                        EOB SURVEY
                                        </Link>
                                </li>*/}
                                    <li className="nav-item font-weight-bold">
                                        <Link
                                            to="/ourMission"
                                            className="nav-link text-uppercase "
                                        >
                                            Our Mission
                                        </Link>
                                    </li>
                                    <li className="nav-item font-weight-bold">
                                        <Link
                                            to="/partner"
                                            className="nav-link text-uppercase "
                                        >
                                            Our Partners
                                        </Link>
                                    </li>
                                    <li className="nav-item font-weight-bold">
                                                <Link
                                                    to="/vindex"
                                                    className="nav-link text-uppercase "
                                                >
                                                    Vindex
                                                </Link>
                                            </li>
                                   



                                </ul>
                            </div>
                        </nav>
                    </div>
                </header>

                {/* for donation */}
                {/* <div className="donation_btn floting_btn" onClick={() => this.setState({
                    DonationPopup: !this.state.DonationPopup
                })}>
                    Combat High Healthcare Costs
                </div> */}
                {/* <div className="hospital_survey_btn floting_btn" data-toggle="modal" data-target="#Hospital_Survey" title="Hospital Survey">
                    Hospital Survey
                </div> */}

                {/* <div className={`donation_popup ${this.state.DonationPopup ? "active" : ""}`}>
                    <div className="donation_header">
                        <h3>Help us fight the high cost of healthcare</h3>
                        <button type="button" className="close_btn" onClick={() => {
                            this.setState({
                                DonationPopup: !this.state.DonationPopup
                            })
                        }}><i className="fa fa-times" aria-hidden="true"></i></button>
                    </div>

                    <div className="donation_content">
                        <p>
                            Medical debt and unaffordable healthcare is a national crisis.  Worse, when we see the wild price variations at a hospital for the same treatment we may deduce that a person is being burdened with debt when their payment has already exceeded the lowest amount the hospital accepts as payment in full.  Much of this can be alleviated or eliminated when consumers are aware of the price of healthcare upfront and if lower cost alternatives exist in their metro area. Text messages aren't free and it requires us to ask for donations to provide this service. For two pennies you can help a person get the care they need at a price they can afford.  Please <span onClick={() => this.goTocheckout()} style={{ cursor: "pointer" }}>click here</span> to donate.  Thank you.
                        </p>

                    </div>
                </div> */}

                {/* Survey Notes  */}
                {/* <div className="modal fade" id="Hospital_Survey">
                    <div className="modal-dialog  modal-lg modal-dialog-centered">
                        <div className="modal-content ">
                            <div className="modal-header">
                                <h5 className="modal-title">Hospital Survey</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" ref={this.refClose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body ">
                                <HospitalSurvey parentCallback={this.handleCallbackClose}></HospitalSurvey>
                            </div>
                        </div>
                    </div>
                </div> */}
            </>
        );
    }
}
export default withRouter(AfterLoginMenu);
