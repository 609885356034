import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import BeforeLoginMenu from "../../routes/BeforeLoginMenu";
import AfterLoginMenu from "../../routes/AfterLoginMenu";
import axios from "axios";
class RxWholesaler extends Component {
    constructor(props) {
        super(props);

        const token = localStorage.getItem("token");
        const rxWholesaler = localStorage.getItem("rxWholesaler");
        // if (!token) {
        //   this.props.history.push("/login");
        // }

        this.state = {
            token: token,
            items: [],
            rxWholesaler: JSON.parse(rxWholesaler)
        };
        // if (this.state.rxWholesaler === false || this.state.rxWholesaler === null) {
        //     this.props.history.push("/");
        // }
    }


    // getInventoryList() {
    //     axios
    //         .get(`${process.env.REACT_APP_API_URL}api/Nadac/NadacListings`, {
    //             headers: {
    //                 Authorization: `Bearer ${this.state.token}`,
    //                 secret_key: localStorage.getItem("secret_key"),
    //             },
    //         })
    //         .then((response) => {
    //             console.log(response)
    //         })
    //         .catch((error) => {
    //             console.log(error)
    //             // if (
    //             //   error.response.status === 400 ||
    //             //   error.response.status === 401 ||
    //             //   error.response.status === 403
    //             // ) {
    //             //   localStorage.removeItem("token");
    //             //   localStorage.removeItem("email");
    //             //   localStorage.removeItem("userID");
    //             //   localStorage.removeItem("userName");
    //             //   localStorage.removeItem("secret_key");
    //             //   localStorage.removeItem("refreshToken");
    //             //   localStorage.removeItem("expired");
    //             //   this.props.history.push("/login");
    //             // }
    //         });
    // }

    componentDidMount() {
        const script = document.createElement("script");
        script.async = true;
        script.src = "/js/RxWholesalercustom.js";
        const lotiscript = document.createElement("script");
        lotiscript.async = true;
        lotiscript.src = "https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js";
        this.div.appendChild(script);
        this.div.appendChild(lotiscript);
    }

    render() {
        return (
            <>
                {this.state.token !== null ? <AfterLoginMenu /> : <BeforeLoginMenu />}
                <main role="main">
                    <div className="gredient-bg pt-3 pb-5" ref={(el) => (this.div = el)}>
                        <div className="container">
                            <div className="row mt-1">

                                <div className="input-group">
                                    <div className="col-md-12">
                                        <table
                                            className="table table-borderless  table-blue table table-striped table-bordered table-sm"
                                            id="tbl_RxWholesaler"

                                        >
                                            <thead>
                                                <tr>
                                                    <th scope="col" width="8%">
                                                        NDC
                                                    </th>
                                                    <th scope="col" width="10%">
                                                        Wholesaler
                                                    </th>
                                                    <th scope="col" width="14%">
                                                        Drug
                                                    </th>
                                                    <th scope="col" width="10%">
                                                        Effective Date
                                                    </th>
                                                    <th scope="col" width="12%">
                                                        Wholesaler/Unit
                                                    </th>
                                                    {/* <th scope="col" width="6%">
                                                        Po Size
                                                        </th> */}
                                                    <th scope="col" width="9%">
                                                        NADAC/Unit
                                                    </th>
                                                    {/* <th scope="col" width="15%">
                                                        Wholesaler Pkg Cost
                                                        </th>
                                                        <th scope="col" width="15%">
                                                        NADAC Adj Pkg Cost
                                                        </th> */}
                                                    <th scope="col" >
                                                        Form
                                                    </th>
                                                    <th scope="col" >
                                                        Strength
                                                    </th>
                                                    <th scope="col" >
                                                        Hyperlink
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* upload edi button */}
                    <Link
                        to="/profile#uploadEdi"
                        className="edi-link-btn shadow"
                    >
                        <span className="tooltiptext">EDI Files Uploads</span>
                        <lottie-player src="images/lf30_editor_ry0j3sth.json" background="transparent" speed="1" loop autoplay></lottie-player>

                    </Link>
                </main>
            </>
        )
    }
}
export default withRouter(RxWholesaler);