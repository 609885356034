import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import BeforeLoginMenu from "../../routes/BeforeLoginMenu";
import AfterLoginMenu from "../../routes/AfterLoginMenu";

class Login extends Component {
    constructor(props) {
        super(props);

        const token = localStorage.getItem("token");
        if (token) {
            this.props.history.push("/profile");
        }

        this.state = {
            token: localStorage.getItem("token"),
            email: "",
            password: "",
            loginerror: "",
        };

        localStorage.removeItem("token");
        localStorage.removeItem("expired");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("secret_key");
        localStorage.removeItem("email");
        localStorage.removeItem("userID");
        localStorage.removeItem("userName");
        localStorage.removeItem("CouponPer");
        localStorage.removeItem("SubscriptionData");
        localStorage.removeItem("couponID");
        localStorage.removeItem("rxWholesaler");
        localStorage.clear()
        sessionStorage.clear()

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    handleSubmit(event) {
        const { email, password } = this.state;
        axios
            .post(`${process.env.REACT_APP_API_URL}api/Auth/login`, {
                UserName: email,
                password: password,
            })
            .then((response) => {
                if (response.data.isSuccess) {
                    localStorage.setItem("token", response.data.token);
                    localStorage.setItem("email", response.data.emailID);
                    localStorage.setItem("userID", response.data.userID);
                    localStorage.setItem("userName", response.data.userName);
                    localStorage.setItem("secret_key", response.data.secval);
                    localStorage.setItem(
                        "refreshToken",
                        response.data.refreshToken
                    );
                    localStorage.setItem("expired", response.data.expireDate);
                    // this.props.history.push("/profile");
                    this.props.history.push("/");
                }
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({
                        loginerror: error.response.data.message,
                    });
                }
            });
        event.preventDefault();
    }
    clearAlltoken() {
        localStorage.removeItem("token");
        localStorage.removeItem("expired");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("secret_key");
        localStorage.removeItem("email");
        localStorage.removeItem("userID");
        localStorage.removeItem("userName");
        localStorage.removeItem("rxWholesaler");
    }
    componentDidMount() {
        this.clearAlltoken();
    }
    componentDidUpdate() {
        this.clearAlltoken();
    }

    render() {
        localStorage.removeItem("token");
        localStorage.removeItem("expired");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("secret_key");
        localStorage.removeItem("email");
        localStorage.removeItem("userID");
        localStorage.removeItem("userName");
        localStorage.removeItem("CouponPer");
        localStorage.removeItem("rxWholesaler");

        return (
            <>
                {this.state.token !== null ? (
                    <AfterLoginMenu />
                ) : (
                    <BeforeLoginMenu />
                )}
                <main role="main">
                    <div className="login_bg">
                        <div className="pt-5 pb-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-10 col-12 mx-auto text-center">
                                        <h3 className="page-title text-white mb-5 font-weight-bold">
                                            Sign in
                                        </h3>
                                        {this.state.loginerror !== "" && (
                                            <div
                                                className="alert alert-danger alert-dismissible fade show"
                                                role="alert"
                                            >
                                                {this.state.loginerror}
                                                <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="alert"
                                                    aria-label="Close"
                                                ></button>
                                            </div>
                                        )}

                                        <form
                                            className=""
                                            onSubmit={this.handleSubmit}
                                        >
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        value={this.state.email}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        className="form-control text-center mb-3"
                                                        placeholder="Email address"
                                                        required
                                                        autoComplete="off"
                                                    />
                                                </div>

                                                <div className="form-group d-flex">
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        value={
                                                            this.state.password
                                                        }
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        className="form-control text-center mb-3"
                                                        placeholder="***********"
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group d-md-flex">
                                                <div className="w-100">
                                                    <Link
                                                        to="/forgotPassword"
                                                        className="text-white text-bold"
                                                    >
                                                        Forgot your Password ?
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <button
                                                    type="submit"
                                                    className="btn btn-orange mb-2"
                                                >
                                                    SIGN IN
                                                </button>
                                            </div>

                                            <div className="form-group d-md-flex">
                                                <div className="w-100 text-white underline">
                                                    <Link
                                                        to="/registration"
                                                        className="text-white text-bold"
                                                    >
                                                        Not registered yet,
                                                        please sign up here
                                                    </Link>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default withRouter(Login);
