import React, { Component } from "react";

class PartnersImg extends Component {
    constructor(props) {
        super(props);
    }


    render() {
        return (
            <>
                {
                    <div className="col-md-3 col-sm-4 col-6 my-3 mob_partner_col">
                        <div className={`our_partners_box ${this.props.last ? 'our_partners_last' : ''} `}>
                            <a href={this.props.website} target={"_blank"}>
                                <img src={this.props.imageurl} alt={this.props.imageur} className="img-fluid" 
                                />
                            </a>
                        </div>
                    </div>
                }
            </>
        );
    }
}
export default PartnersImg;
