import React, { Component } from "react";
import { withRouter } from "react-router-dom";

export class Logout extends Component {
    constructor(props) {
        super(props);

        localStorage.removeItem("token");
        localStorage.removeItem("email");
        localStorage.removeItem("userID");
        localStorage.removeItem("userName");
        localStorage.removeItem("secret_key");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("expired");
        localStorage.removeItem("CouponPer");
        localStorage.removeItem("SubscriptionData");
        localStorage.removeItem("couponID");
        localStorage.removeItem("rxWholesaler");
        localStorage.clear();
        this.props.history.push("/login");
    }

    render() {
        return <div></div>;
    }
}

export default withRouter(Logout);
