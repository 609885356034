import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import BeforeLoginMenu from "../../routes/BeforeLoginMenu";
import AfterLoginMenu from "../../routes/AfterLoginMenu";
import axios from "axios";

class Thankyou extends Component {
    constructor(props) {
        super(props);
        sessionStorage.removeItem("selectedState");
        sessionStorage.removeItem("subscriptionId");
        localStorage.removeItem("CouponPer");
        localStorage.removeItem("SubscriptionData");
        localStorage.removeItem("couponID");
        localStorage.removeItem("CouponPer");
        localStorage.removeItem("subsIdAllState");
        localStorage.removeItem("IsInstalmentPayment");
        localStorage.removeItem("MaintenanceCharges");
        localStorage.removeItem("InstallmenetAmount");
        localStorage.removeItem("subsId");
        const token = localStorage.getItem("token");
        // if (!token) {
        //     this.props.history.push("/login");
        // }
        this.state = {
            token: localStorage.getItem("token"),
            users: [],
        };
    }

    // refreshToken() {
    //     const token = localStorage.getItem("token");
    //     const refreshToken = localStorage.getItem("refreshToken");
    //     const expired = localStorage.getItem("expired");

    //     if (token !== null && refreshToken !== null && expired !== null) {
    //         axios
    //             .post(
    //                 `${process.env.REACT_APP_API_URL}api/auth/refreshToken`,
    //                 {
    //                     token: token,
    //                     refreshToken: refreshToken,
    //                     expiration: expired,
    //                 }
    //             )
    //             .then((response) => {
    //                 localStorage.removeItem("token");
    //                 localStorage.removeItem("refreshToken");
    //                 localStorage.removeItem("expired");
    //                 localStorage.setItem("token", response.data.token);
    //                 localStorage.setItem(
    //                     "refreshToken",
    //                     response.data.refreshToken
    //                 );
    //                 localStorage.setItem("expired", response.data.expiration);
    //             })
    //             .catch((error) => {
    //                 if (
    //                     error.response.status === 400 ||
    //                     error.response.status === 401 ||
    //                     error.response.status === 403
    //                 ) {
    //                     localStorage.removeItem("token");
    //                     localStorage.removeItem("email");
    //                     localStorage.removeItem("userID");
    //                     localStorage.removeItem("userName");
    //                     localStorage.removeItem("secret_key");
    //                     localStorage.removeItem("refreshToken");
    //                     localStorage.removeItem("expired");
    //                     this.props.history.push("/login");
    //                 }
    //             });
    //     }
    // }

    render() {
        const { users, subscriptionDetails } = this.state;
        return (
            <>
                {this.state.token !== null ? (
                    <AfterLoginMenu />
                ) : (
                    <BeforeLoginMenu />
                )}
                <main role="main">
                    <div className="container">
                        <div className="row justify-content-center">
                            <h1 className="font-weight-bold pt-5">Thank you</h1>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default withRouter(Thankyou);
