import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import BeforeLoginMenu from "../../routes/BeforeLoginMenu";
import AfterLoginMenu from "../../routes/AfterLoginMenu";

class OurMission extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: localStorage.getItem("token"),
      firstName: "",
      lastName: "",
      subject: "",
      email: "",
      message: "",
      uploadFile: "",
      fileUrl: "",
      errors: {
        firstName: "This field is required",
        lastName: "This field is required",
        email: "This field is required",
        subject: "This field is required",
        message: "This field is required",
      },
      successMessage: "",
      errorMessage: "",
      isLoaded: false,
      isValid: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
  }

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  validate(event) {
    const { name, value } = event.target;
    const errors = this.state.errors;

    switch (name) {
      case "firstName":
        if (/[^a-z A-Z ]/.test(value)) {
          errors.firstName = "First Name should be alphabetic.";
        } else if (value.trim() == "") {
          errors.firstName = "First Name should not be blank.";
        } else if (value.length > 50) {
          errors.firstName = "First Name should not more than 50 chracters.";
        } else {
          errors.firstName = "";
        }
        break;
      case "lastName":
        if (/[^a-z A-Z ]/.test(value)) {
          errors.lastName = "Last Name should be alphabetic.";
        } else if (value.trim() == "") {
          errors.lastName = "Last Name should not be blank.";
        } else if (value.length > 50) {
          errors.lastName = "Last Name should not more than 50 chracters.";
        } else {
          errors.lastName = "";
        }
        break;
      case "email":
        let regEmail =
          /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
        if (!regEmail.test(value)) {
          errors.email = "Email is not valid.";
        } else if (value.length > 50) {
          errors.email = "Email should not more than 50 chracters.";
        } else {
          errors.email = "";
        }
        break;
      case "subject":
        if (value.trim() == "") {
          errors.subject = "Subject should not be blank.";
        } else if (value.length > 30) {
          errors.subject = "Subject should not more than 30 chracters.";
        } else {
          errors.subject = "";
        }
        break;
      case "message":
        if (value.trim() == "") {
          errors.message = "Message should not be blank.";
        } else if (value.length > 500) {
          errors.message = "Message should not more than 500 chracters.";
        } else {
          errors.message = "";
        }
        break;

      default:
        break;
    }
    this.setState({
      errors,
      [name]: value,
    });
    if (
      errors.firstName.length === 0 &&
      errors.lastName.length === 0 &&
      errors.email.length === 0 &&
      errors.subject.length === 0 &&
      errors.message.length === 0
    ) {
      this.setState({
        isValid: true,
      });
    } else {
      this.setState({
        isValid: false,
      });
    }
  }

  handleFileChange(event) {
    this.setState({
      uploadFile: event.target.files[0],
    });
  }

  handleSubmit(event) {
    const {
      firstName,
      lastName,
      subject,
      email,
      message,
      fileUrl,
      uploadFile,
    } = this.state;

    if (uploadFile !== "") {
      const formData = new FormData();
      formData.append("File", this.state.uploadFile);
      axios
        .post(
          `${process.env.REACT_APP_API_URL}api/Feedback/uploadfeedbackfile`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          this.setState({
            fileUrl: response.data.message,
            isLoaded: true,
          });
          axios
            .post(
              `${process.env.REACT_APP_API_URL}api/Feedback/savefeedback`,
              {
                FirstName: firstName,
                LastName: lastName,
                email: email,
                subject: subject,
                message: message,
                fileName: response.data.message,
              }
            )
            .then((response) => {
              if (response.data.isSuccess) {
                this.setState({
                  successMessage: response.data.message,
                  errorMessage: "",
                  firstName: "",
                  lastName: "",
                  email: "",
                  subject: "",
                  message: "",
                  uploadFile: "",
                  fileUrl: "",
                  isLoaded: false,
                });
              }
            })
            .catch((error) => {
              if (error.response) {
                this.setState({
                  errorMessage: error.response.data.message,
                  successMessage: "",
                  isLoaded: false,
                });
              }
            });
        })
        .catch((error) => {
          this.setState({
            errorMessage: error.message,
          });
        });
    } else {
      axios
        .post(`${process.env.REACT_APP_API_URL}api/Feedback/savefeedback`, {
          FirstName: firstName,
          LastName: lastName,
          email: email,
          subject: subject,
          message: message,
          fileName: "",
        })
        .then((response) => {
          if (response.data.isSuccess) {
            this.setState({
              successMessage: response.data.message,
              errorMessage: "",
              firstName: "",
              lastName: "",
              email: "",
              subject: "",
              message: "",
              uploadFile: "",
              fileUrl: "",
              isLoaded: false,
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            this.setState({
              errorMessage: error.response.data.message,
              successMessage: "",
              isLoaded: false,
            });
          }
        });
    }

    event.preventDefault();
  }

  render() {
    return (
      <>
        {this.state.token !== null ? <AfterLoginMenu /> : <BeforeLoginMenu />}
        <main role="main">
          <div className="pt-5 pb-5 mob-pg-pt our-mission">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="text-center mb-5 text-dark text-bold boldFont">
                    Our Mission
                  </h3>
                </div>
                <div className="col-md-12">
                  <p className="text-justify">
                    {" "}
                    All industries compete on price and efficiency, but the
                    healthcare industry exists to maximize billings and revenue.
                    They do not compete on price and efficiency. Knowing prices
                    creates competition. Competition creates efficiencies,
                    supply chain management, and opportunities for new
                    businesses to enter the marketplace to provide lower cost
                    alternatives. Prices are determined by supply and demand.
                    That is how capitalism works. The basic principles of
                    economics do not currently exist in healthcare making it one
                    of the most costly and inefficient industries in the world.
                  </p>
                  <p className="text-justify">
                    {" "}
                    Our vision is to empower the stakeholders of healthcare
                    (doctors, hospitals, and patients) to discover the true cost
                    of care so that they can be the custodians of their own
                    future. To be sure, healthcare is complicated and needs
                    experts to guide people to best health and financial
                    outcomes. We want to empower all players in the healthcare
                    ecosystem with knowledge to be successful. When all
                    stakeholders are working in concert to keep costs under
                    control, money will be redistributed back into businesses’
                    and families’ budgets. This will have a profound impact on
                    growing a healthy economy, as well as, helping people
                    achieve economic freedom. We wish everybody health, peace,
                    and prosperity.
                  </p>
                </div>
              </div>
            </div>
            <div className="page-bg-light-gray mt-5 pt-5">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="text-center mb-5 text-dark boldFont">
                      About Us
                    </h3>
                  </div>
                  <div className="col-md-6">
                  <div className="about_img">
                    <img
                        src="images/leo.JPG"
                        className="mx-auto d-block"
                        alt="..."
                      />
                  </div>
                    <p className="text-center">
                      
                      <h4 className="boldFont pt-3">Leon Wisniewski</h4>
                      <h5>Co-Founder and Chief Information Officer</h5>
                      <a
                        href=""
                        target
                        data-toggle="modal"
                        data-target="#myModal"
                        className="text-orange font-weight-bold mb-5 mt-5"
                      >
                        {" "}
                        Learn More &gt;&gt;
                      </a>
                    </p>
                  </div>

                  <div className="col-md-6">
                    <div className="about_img">
                      <img
                        src="images/Pharmacy_Stan_Photo.png"
                        className="mx-auto d-block"
                        alt="..."
                      />
                    </div>
                    <p className="text-center">
                      
                      <h4 className="boldFont pt-3">Stan Warren</h4>
                      <h5>Vice President Pharmacy Pricing</h5>
                      <a
                        href=""
                        target
                        data-toggle="modal"
                        data-target="#Pharmacy_StanyModal"
                        className="text-orange font-weight-bold mb-5 mt-5"
                      >
                        {" "}
                        Learn More &gt;&gt;
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal" id="myModal">
              <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content ">
                  <div className="modal-header">
                    <p>
                      <h5 className="modal-title text-center pl-5 font-weight-bold text-gray">
                        Leon Wisniewski
                      </h5>

                      <h5 className="text-center pl-4 font-weight-bold text-gray">
                        Co-Founder and Chief Information Officer
                      </h5>
                    </p>

                    <button type="button" className="close" data-dismiss="modal">
                      &times;
                    </button>
                  </div>

                  <div className="modal-body ">
                    <p>
                      {" "}
                      I worked at large Blue Cross for over 15 years as an
                      actuarial analyst, contract analyst, in payment integrity
                      and in informatics. In these roles I modeled claims data
                      to project spend, created fee schedules, supported
                      contract negotiations with doctors and hospitals,
                      identified recoveries by finding claims that did not pay
                      correctly and modeled payment policy impact changes. More
                      recently I’ve worked at a population health company making
                      benchmarks to inform companies of opportunities to make
                      better decisions. With full experience in the managed care
                      space I believe nothing is more effective to lowering cost
                      and reducing doctor abrasion than knowing the price for
                      treatment and creating data fluidity which will greatly
                      reduce over-utilization and misdiagnosis.
                    </p>
                    <p>
                      {" "}
                      I have BA in Information Management from Holy Family
                      University, live in the Philadelphia area, am married,
                      have two kids and belong to my church.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="modal" id="Pharmacy_StanyModal">
              <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content ">
                  <div className="modal-header">
                    <p className="w-100">
                      <h5 className="modal-title text-center pl-5 font-weight-bold text-gray">
                      Stan Warren
                      </h5>

                      <h5 className="text-center pl-4 font-weight-bold text-gray">
                      Vice President Pharmacy Pricing
                      </h5>
                    </p>

                    <button type="button" className="close" data-dismiss="modal">
                      &times;
                    </button>
                  </div>

                  <div className="modal-body ">
                    <p>
                      {" "}
                      I have worked in the retail pharmacy space for over 19 years and my most recent role was Vice President of Pharmacy Operations for a small chain pharmacy in New York. In my career, I have worn many hats and gained many perspectives on the industry.
                    </p>
                    <p>
                      {" "}
                      The one consistent theme is that healthcare does not have patients’ interests at heart. I spent the last 3 years mitigating losses to Pharmacy Benefit Managers and growing an independent pharmacy business in a climate that is forcing many to shut their doors altogether.
                    </p>
                    <p>
                    I believe the only answer to improving the system is transparency.
                    </p>
                    <p>I attended Roger Williams University and have a BS in Finance despite having never left the pharmacy industry. I now call Miami home after living the majority of my life in New York.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="col-lg-6 col-md-8 mx-auto text-center no-bg">
                <h4 className="boldFont pt-3 pb-3">Contact Us</h4>
                <form className="login-form" onSubmit={this.handleSubmit}>
                  <div className="form-row mb-4">
                    <div className="col text-left">
                      <label>First Name*</label>
                      <input
                        type="text"
                        name="firstName"
                        value={this.state.firstName}
                        onChange={(e) => {
                          this.handleChange(e);
                          this.validate(e);
                        }}
                        className={
                          this.state.errors.firstName &&
                          this.state.errors.firstName !==
                            "This field is required"
                            ? "form-control rounded-left is-invalid"
                            : "form-control rounded-left"
                        }
                        required
                      />
                      <div className="invalid-feedback text-left">
                        {this.state.errors.firstName !== "" &&
                          this.state.errors.firstName !==
                            "This field is required" &&
                          this.state.errors.firstName}
                      </div>
                    </div>
                    <div className="col text-left">
                      <label>Last Name*</label>
                      <input
                        type="text"
                        name="lastName"
                        value={this.state.lastName}
                        onChange={(e) => {
                          this.handleChange(e);
                          this.validate(e);
                        }}
                        className={
                          this.state.errors.lastName &&
                          this.state.errors.lastName !==
                            "This field is required"
                            ? "form-control rounded-left is-invalid"
                            : "form-control rounded-left"
                        }
                        required
                      />
                      <div className="invalid-feedback text-left">
                        {this.state.errors.lastName !== "" &&
                          this.state.errors.lastName !==
                            "This field is required" &&
                          this.state.errors.lastName}
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-4 text-left">
                    <label>Email Address*</label>
                    <input
                      type="email"
                      name="email"
                      value={this.state.email}
                      onChange={(e) => {
                        this.handleChange(e);
                        this.validate(e);
                      }}
                      className={
                        this.state.errors.email &&
                        this.state.errors.email !== "This field is required"
                          ? "form-control rounded-left is-invalid"
                          : "form-control rounded-left"
                      }
                      required
                    />
                    <div className="invalid-feedback text-left">
                      {this.state.errors.email !== "" &&
                        this.state.errors.email !== "This field is required" &&
                        this.state.errors.email}
                    </div>
                  </div>
                  <div className="form-group mb-4 text-left">
                    <label>Subject*</label>
                    <input
                      type="text"
                      name="subject"
                      value={this.state.subject}
                      onChange={(e) => {
                        this.handleChange(e);
                        this.validate(e);
                      }}
                      className={
                        this.state.errors.subject &&
                        this.state.errors.subject !== "This field is required"
                          ? "form-control rounded-left is-invalid"
                          : "form-control rounded-left"
                      }
                      required
                    />
                    <div className="invalid-feedback text-left">
                      {this.state.errors.subject !== "" &&
                        this.state.errors.subject !==
                          "This field is required" &&
                        this.state.errors.subject}
                    </div>
                  </div>
                  <div className="form-group text-left">
                    <label>Message*</label>
                    <textarea
                      name="message"
                      value={this.state.message}
                      onChange={(e) => {
                        this.handleChange(e);
                        this.validate(e);
                      }}
                      className={
                        this.state.errors.message &&
                        this.state.errors.message !== "This field is required"
                          ? "form-control rounded-left is-invalid"
                          : "form-control rounded-left"
                      }
                      required
                    ></textarea>
                    <div className="invalid-feedback text-left">
                      {this.state.errors.message !== "" &&
                        this.state.errors.message !==
                          "This field is required" &&
                        this.state.errors.message}
                    </div>
                  </div>
                  <h4 className="font-weight-bold text-center mt-5">
                    Deidentified Claims
                  </h4>
                  <p>
                    <em>Send us your deidentified claims to reprice</em>
                  </p>
                  <div className="mt-2 d-flex justify-content-center">
                    <label
                      for="fileUpload"
                      className="file-upload btn bg-orange btn-block rounded-pill shadow text-white w-50 "
                    >
                      Upload
                      <i className="fa fa-upload ml-2"></i>
                      <input
                        id="fileUpload"
                        type="file"
                        name="uploadFile"
                        onChange={this.handleFileChange}
                      />
                    </label>
                  </div>
                  <div className="form-group">
                    <small className="mb-3">{this.state.uploadFile.name}</small>
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      disabled={this.state.isValid === false}
                      className="btn btn-orange mb-2"
                    >
                      Submit
                    </button>
                  </div>
                  <div className="form-group">
                    <a
                      //href="https://healthcostlabs.us2.list-manage.com/subscribe?u=cdaac6d4e5141fdc217dcd47a&id=09e1a676bf"
                      href="http://eepurl.com/hJ4Vzv"
                      className="text-orange font-weight-bold"
                      target="_blank()"
                    >
                      {" "}
                      Join Our Mailing List
                    </a>
                  </div>
                  {this.state.errorMessage !== "" && (
                    <div
                      className="alert alert-danger alert-dismissible fade show"
                      role="alert"
                    >
                      {this.state.errorMessage}
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      ></button>
                    </div>
                  )}
                  {this.state.successMessage !== "" && (
                    <div
                      className="alert alert-success alert-dismissible fade show"
                      role="alert"
                    >
                      {this.state.successMessage}
                      <button
                        type="button"
                        className="close"
                        data-dismiss="alert"
                        aria-label="Close"
                      ></button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}
export default withRouter(OurMission);
