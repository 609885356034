import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import BeforeLoginMenu from "../../routes/BeforeLoginMenu";
import AfterLoginMenu from "../../routes/AfterLoginMenu";
import axios from "axios";
import swal from 'sweetalert2';



class SubscriptionView extends Component {
    constructor(props) {
        super(props);
        const token = localStorage.getItem("token");
        this.UserPopup = React.createRef();
        this.UserPopupClose = React.createRef();
        if (process.env.NODE_ENV !== 'development') {
            document.addEventListener('contextmenu', (e) => {
                e.preventDefault();
            }
            );
            document.onkeydown = function (e) {
                if (e.keyCode == 123) {
                    return false;
                }
                if (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
                    return false;
                }
                if (e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
                    return false;
                }
                if (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
                    return false;
                }
                if (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
                    return false;
                }
            }
        }

        this.state = {
            token: token,
            items: {
                item1: [],
                item2: [],
                item3: [],
                item4: [],
                item5: [],
                item6: [],
                item7: [],
                item8: [],
            },
            states: [],
            selectedState: 1,
            customSubscription: [],
            selectedStateName: 'Alabama (AL)',
            MaintenanceCharges: null,
            UserName: '',
            UserEmail: '',
            MailSendingInProcess: false
        };

        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.UserSubmitForm = this.UserSubmitForm.bind(this);
        // this.GetMaintancesCharges = this.GetMaintancesCharges(this)
    }


    alertnotification() {

        swal.fire({
            icon: 'error',
            text: 'Please create account or sign in to purchase.',
            //footer: '<a href=""></a>'
        })
        this.props.history.push("/login");

    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    getAllState() {
        axios
            .get(`${process.env.REACT_APP_API_URL}api/master/getallstates`, {})
            .then((response) => {
                this.setState({
                    states: Object.values(response.data),
                });
            })
            .catch((error) => {
                if (
                    error.response.status === 400 ||
                    error.response.status === 401 ||
                    error.response.status === 403
                ) {
                    // localStorage.removeItem("token");
                    // localStorage.removeItem("email");
                    // localStorage.removeItem("userID");
                    // localStorage.removeItem("userName");
                    // localStorage.removeItem("secret_key");
                    // localStorage.removeItem("refreshToken");
                    // localStorage.removeItem("expired");
                    this.props.history.push("/logout");
                }
            });
    }

    getSubscriptionList() {
        axios
            .get(
                `${process.env.REACT_APP_API_URL}api/master/getsubscription?stateId=1`,
                {
                    //uncomment header for now
                    headers: {
                        Authorization: `Bearer ${this.state.token}`,
                        secret_key: localStorage.getItem("secret_key"),
                    },
                }
            )
            .then((response) => {
                this.setState({
                    items: {
                        item1: Object.values(response.data.data)[0],
                        item2: Object.values(response.data.data)[1],
                        item3: Object.values(response.data.data)[2],
                        item4: Object.values(response.data.data)[3],
                        item5: Object.values(response.data.data)[6],
                        item6: Object.values(response.data.data)[7],
                        item7: Object.values(response.data.data)[4],
                        item8: Object.values(response.data.data)[5],
                    },
                });
            })
            .catch((error) => {
                if (
                    error.response.status === 400 ||
                    error.response.status === 401 ||
                    error.response.status === 403
                ) {
                    // localStorage.removeItem("token");
                    // localStorage.removeItem("email");
                    // localStorage.removeItem("userID");
                    // localStorage.removeItem("userName");
                    // localStorage.removeItem("secret_key");
                    // localStorage.removeItem("refreshToken");
                    // localStorage.removeItem("expired");
                    this.props.history.push("/logout");
                }
            });
    }

    handleStateChange(event) {
        //console.log(this.state.states.filter(res => res.stateID === +event.target.value).map(res => res.stateName))
        if (event.target.value !== "") {
            this.setState({
                selectedState: event.target.value,
                selectedStateName: this.state.states.filter(res => res.stateID === +event.target.value).map(res => res.stateName + ' ' + '(' + res.stateCode + ')')
            });
            axios
                .get(
                    `${process.env.REACT_APP_API_URL}api/master/getsubscription?stateId=${event.target.value}`,
                    {
                        //Uncomment header for now
                        headers: {
                            Authorization: `Bearer ${this.state.token}`,
                            secret_key: localStorage.getItem("secret_key"),
                        },
                    }
                )
                .then((response) => {
                    this.setState({
                        items: {
                            item1: Object.values(response.data.data)[0],
                            item2: Object.values(response.data.data)[1],
                            item3: Object.values(response.data.data)[2],
                            item4: Object.values(response.data.data)[3],
                            item5: Object.values(response.data.data)[6],
                            item6: Object.values(response.data.data)[7],
                            item7: Object.values(response.data.data)[4],
                            item8: Object.values(response.data.data)[5],
                        },
                    });
                })
                .catch((error) => {
                    this.setState({
                        items: {
                            item1: "",
                            item2: "",
                            item3: "",
                            item4: "",
                            item5: "",
                            item6: "",
                            item7: "",
                            item8: "",
                        },
                    });
                });
        } else {
            this.getSubscriptionList();
            this.setState({
                selectedState: event.target.value,
                selectedStateName: 'Alabama (AL)'
            });
        }
    }



    GetMaintancesCharges(id) {
        axios
            .get(
                `${process.env.REACT_APP_API_URL
                }api/Subscription/GetMaintancesCharges?filterInput={"mySubscriptionID":"${id}"}`
            )
            .then((response) => {
                console.log(response)
                this.setState({
                    MaintenanceCharges: response.data[0].maintancesCharges,
                });
            })
            .catch((error) => {
                console.log(error)
                if (
                    error.response.status === 400 ||
                    error.response.status === 401 ||
                    error.response.status === 403
                ) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("email");
                    localStorage.removeItem("userID");
                    localStorage.removeItem("userName");
                    localStorage.removeItem("secret_key");
                    localStorage.removeItem("refreshToken");
                    localStorage.removeItem("expired");
                    // this.props.history.push("/login");
                }
            });
    }

    UserSubmitForm(e) {
        e.preventDefault();
        this.setState({
            MailSendingInProcess: true
        });
        const { UserName, UserEmail } = this.state;
        console.log(UserName, UserEmail);
        // var bodyFormData = new FormData();
        // bodyFormData.append('userName', UserName);
        // bodyFormData.append('userEmail', UserEmail);
        // const data = {
        //     userName: UserName,
        //     userEmail: UserEmail
        // }
        axios
            .post(
                `${process.env.REACT_APP_API_URL}api/master/subscriptionViewMailSend?userName=${UserName}&userEmail=${UserEmail}`
            )
            .then((response) => {
                this.setState({
                    MailSendingInProcess: false
                });
                this.UserPopupClose.current.click();
            })
            .catch((error) => {
                this.setState({
                    MailSendingInProcess: false
                });
                this.UserPopupClose.current.click();
            });

    }

    componentDidMount() {
        this.getAllState();
        this.getSubscriptionList();
        this.UserPopup.current.click();
    }

    render() {

        return (
            <>
                {this.state.token !== null ? <AfterLoginMenu /> : <BeforeLoginMenu />}
                <main role="main" className="subscription-bg">
                    <div className="pt-3 pb-5">

                        {/* on load popup start */}
                        <span style={{ visibility: "hidden" }} data-toggle="modal" data-target="#UserDetail_Modal" ref={this.UserPopup}></span>
                        <div className="modal fade" tabIndex="-1" role="dialog" id="UserDetail_Modal" data-backdrop="static">
                            <div className="modal-dialog modal-dialog-scrollable oop_modal">
                                <div className="modal-content ">
                                    <div className="modal-body ">
                                        <button type="button" className="close" data-dismiss="modal" disabled={this.state.MailSendingInProcess}>
                                            &times;
                                        </button>

                                        <form onSubmit={this.UserSubmitForm}>

                                            <div className="form-group">
                                                <label className="oopLabel">Enter Your Name: </label>
                                                <input type="text" className="form-control mt-1" placeholder="Your Name"
                                                    value={this.state.UserName || ''} name="UserName" required
                                                    onChange={
                                                        this.handleChange
                                                    }
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label className="oopLabel">Enter Your Email: </label>
                                                <input type="email" className="form-control mt-1" placeholder="Your Email"
                                                    value={this.state.UserEmail || ''} name="UserEmail" required
                                                    onChange={
                                                        this.handleChange
                                                    }
                                                />
                                            </div>

                                            <button type="submit" className="btn btn-orange bannerseachPlaceHoldercolor m-auto d-block" onClick={this.oopCustomSubmit} disabled={this.state.MailSendingInProcess}>Submit</button>
                                            <span style={{ visibility: "hidden" }} data-dismiss="modal" ref={this.UserPopupClose}></span>
                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* on load popup end */}

                        <div className="container">
                            <div className="row">
                                <div className="col-md-6 col-sm-12 mb-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="bg-gray mb-3 pb-5">
                                                <h6 className="text-white text-center">
                                                    State Subscriptions
                                                </h6>
                                                <div className="row subscription-state">
                                                    <p className="text-white col-4 text-right">
                                                        Select State:
                                                    </p>
                                                    <select
                                                        className="col-8"
                                                        onChange={this.handleStateChange}
                                                    >
                                                        {this.state.states.map((stateArr, index) => (
                                                            <option value={stateArr.stateID} key={index}>
                                                                {stateArr.stateName} ({stateArr.stateCode})
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-white px-3 pt-3">
                                        <div className="row">
                                            <div className="col-md-6 subscription-col">
                                                <div className="bg-orange tiles mb-3">
                                                    <h2 className="subscriptionBoxInnerHeader">
                                                        {this.state.items.item1.subscriptionTitle}
                                                    </h2>
                                                    <h3 className="font-weight-bold text-white">
                                                        $
                                                        {parseFloat(
                                                            this.state.items.item1.totalPrice
                                                        ).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                        })}
                                                    </h3>
                                                    <p className="text-body">
                                                        {this.state.items.item1.subscriptionType}
                                                    </p>
                                                    <p className="font-weight-bold">
                                                        $
                                                        {parseFloat(
                                                            this.state.items.item1.price
                                                        ).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                        })}
                                                        /File
                                                    </p>
                                                    <p className="font-weight-bold">
                                                        {this.state.items.item1.filterStates}
                                                        &nbsp;:{" "}
                                                        {
                                                            this.state.items.item1
                                                                .totalFilesCountSpecificState
                                                        }{" "}
                                                        Files
                                                    </p>
                                                    <p className="font-weight-bold">
                                                        {this.state.items.item1.hospitalCount}
                                                        &nbsp; Hospitals/Clinics

                                                    </p>
                                                    <button
                                                        className="btn bg-white checkout-btn"
                                                        onClick={() => this.props.history.push("/login")}
                                                    >
                                                        Checkout
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-md-6 subscription-col">
                                                <div className="bg-orange tiles mb-3">
                                                    <h2 className="subscriptionBoxInnerHeader">
                                                        {this.state.items.item3.subscriptionTitle}
                                                    </h2>
                                                    <h3 className="font-weight-bold text-white">
                                                        $
                                                        {parseFloat(
                                                            this.state.items.item3.totalPrice
                                                        ).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                        })}
                                                    </h3>
                                                    <p className="text-body">
                                                        {this.state.items.item3.subscriptionType}
                                                    </p>
                                                    <p className="font-weight-bold">
                                                        $
                                                        {parseFloat(
                                                            this.state.items.item3.price
                                                        ).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                        })}
                                                        /File
                                                    </p>
                                                    <p className="font-weight-bold">
                                                        {this.state.items.item3.filterStates}
                                                        &nbsp;:{" "}
                                                        {
                                                            this.state.items.item3
                                                                .totalFilesCountSpecificState
                                                        }{" "}
                                                        Files
                                                    </p>
                                                    <p className="font-weight-bold">
                                                        {this.state.items.item1.hospitalCount}
                                                        &nbsp; Hospitals/Clinics

                                                    </p>
                                                    {/* <button
                            className="btn bg-white checkout-btn"
                            onClick={() => {
                              this.handleCheckout(
                                this.state.items.item3.subscriptionID,
                                this.state.items.item3.filterStates,
                                this.state.items.item3.accessType
                              );
                            }}
                          >
                            Checkout
                          </button> */}
                                                    <button
                                                        className="btn bg-white checkout-btn"
                                                        data-toggle="modal" data-target="#Modalone"
                                                        onClick={(e) => {
                                                            this.GetMaintancesCharges(this.state.items.item3.subscriptionID)

                                                        }}
                                                    >
                                                        Checkout
                                                    </button>
                                                </div>

                                                {/* Modal */}
                                                <div className="modal fade" id="Modalone" tabIndex="-1" aria-labelledby="ModaloneLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-md">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="ModaloneLabel">How do you want to pay? </h5>
                                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <h2 className="bold_text_no_text_transform mb-4 text-center">
                                                                    {this.state.items.item3.subscriptionTitle}
                                                                </h2>
                                                                <div className="row">

                                                                    <div className="col-sm-6">
                                                                        <div className="bg-orange tiles mb-3 height-auto">
                                                                            <h2 className="subscriptionBoxInnerHeader justify-content-center height-auto">
                                                                                Pay Once
                                                                            </h2>
                                                                            <h3 className="font-weight-bold text-white">
                                                                                $
                                                                                {parseFloat(
                                                                                    this.state.items.item3.totalPrice
                                                                                ).toLocaleString(undefined, {
                                                                                    minimumFractionDigits: 2,
                                                                                })}
                                                                            </h3>
                                                                            <p className="text-body">
                                                                                {this.state.items.item3.subscriptionType}
                                                                            </p>
                                                                            <p className="font-weight-bold modalLine">
                                                                                $0 Maintenance Fee
                                                                            </p>

                                                                            <button
                                                                                className="btn bg-white checkout-btn" data-dismiss="modal" aria-label="Close"
                                                                                onClick={() => this.props.history.push("/login")}
                                                                            >
                                                                                Select
                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-6">
                                                                        <div className="bg-orange tiles mb-3 height-auto">
                                                                            <h2 className="subscriptionBoxInnerHeader justify-content-center height-auto">
                                                                                Via 12 Months Installment
                                                                            </h2>
                                                                            <h3 className="font-weight-bold text-white">
                                                                                $
                                                                                {parseFloat(
                                                                                    this.state.items.item3.totalPrice / 12
                                                                                ).toFixed(2)}
                                                                                <span className="perMonth">/month</span>
                                                                            </h3>
                                                                            <p className="text-body">
                                                                                {this.state.items.item3.subscriptionType}
                                                                            </p>
                                                                            <p className="font-weight-bold">
                                                                                ${this.state.MaintenanceCharges} Maintenance Fee
                                                                            </p>

                                                                            <button
                                                                                className="btn bg-white checkout-btn" data-dismiss="modal" aria-label="Close"
                                                                                onClick={() => this.props.history.push("/login")}
                                                                            >
                                                                                Select
                                                                            </button>
                                                                        </div>
                                                                    </div>


                                                                </div>



                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>


                                            <div className="col-md-6 subscription-col">
                                                <div className="bg-orange tiles mb-3">
                                                    <h2 className="subscriptionBoxInnerHeader">
                                                        {this.state.items.item2.subscriptionTitle}
                                                    </h2>
                                                    <h3 className="font-weight-bold text-white">
                                                        $
                                                        {parseFloat(
                                                            this.state.items.item2.totalPrice
                                                        ).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                        })}
                                                    </h3>
                                                    <p className="text-body">
                                                        {this.state.items.item2.subscriptionType}
                                                    </p>
                                                    <p className="font-weight-bold">
                                                        $
                                                        {parseFloat(
                                                            this.state.items.item2.price
                                                        ).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                        })}
                                                        /File
                                                    </p>
                                                    <p className="font-weight-bold">
                                                        {this.state.items.item2.filterStates} &nbsp;:{" "}
                                                        {
                                                            this.state.items.item2
                                                                .totalFilesCountSpecificState
                                                        }{" "}
                                                        Files
                                                    </p>
                                                    <p className="font-weight-bold">
                                                        {this.state.items.item1.hospitalCount}
                                                        &nbsp; Hospitals/Clinics

                                                    </p>
                                                    <button
                                                        className="btn bg-white checkout-btn"
                                                        onClick={() => this.props.history.push("/login")}
                                                    >
                                                        Checkout
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-md-6 subscription-col">
                                                <div className="bg-orange tiles mb-3">
                                                    <h2 className="subscriptionBoxInnerHeader">
                                                        {this.state.items.item4.subscriptionTitle}
                                                    </h2>
                                                    <h3 className="font-weight-bold text-white">
                                                        $
                                                        {parseFloat(
                                                            this.state.items.item4.totalPrice
                                                        ).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                        })}
                                                    </h3>
                                                    <p className="text-body">
                                                        {this.state.items.item4.subscriptionType}
                                                    </p>
                                                    <p className="font-weight-bold">
                                                        $
                                                        {parseFloat(
                                                            this.state.items.item4.price
                                                        ).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                        })}
                                                        /File
                                                    </p>
                                                    <p className="font-weight-bold">
                                                        {this.state.items.item4.filterStates}
                                                        &nbsp;:{" "}
                                                        {
                                                            this.state.items.item4
                                                                .totalFilesCountSpecificState
                                                        }{" "}
                                                        Files
                                                    </p>
                                                    <p className="font-weight-bold">
                                                        {this.state.items.item1.hospitalCount}
                                                        &nbsp; Hospitals/Clinics

                                                    </p>
                                                    {/* <button
                            className="btn bg-white checkout-btn"
                            onClick={() => {
                              this.handleCheckout(
                                this.state.items.item4.subscriptionID,
                                this.state.items.item4.filterStates,
                                this.state.items.item4.accessType
                              );
                            }}
                          >
                            Checkout
                          </button> */}
                                                    <button
                                                        className="btn bg-white checkout-btn"
                                                        data-toggle="modal" data-target="#Modaltwo"
                                                        onClick={(e) => {
                                                            this.GetMaintancesCharges(this.state.items.item4.subscriptionID)
                                                        }}
                                                    // onClick={() => {
                                                    //   this.GetMaintancesCharges(this.state.items.item4.subscriptionID)
                                                    // }}
                                                    >
                                                        Checkout
                                                    </button>
                                                </div>

                                                {/* Modal */}
                                                <div className="modal fade" id="Modaltwo" tabIndex="-1" aria-labelledby="ModaltwoLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-md">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="ModaltwoLabel">How do you want to pay? </h5>
                                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <h2 className="bold_text_no_text_transform mb-4 text-center">
                                                                    {this.state.items.item4.subscriptionTitle}
                                                                </h2>
                                                                <div className="row">

                                                                    <div className="col-sm-6">
                                                                        <div className="bg-orange tiles mb-3 height-auto">
                                                                            <h2 className="subscriptionBoxInnerHeader justify-content-center height-auto">
                                                                                Pay Once
                                                                            </h2>
                                                                            <h3 className="font-weight-bold text-white">
                                                                                $
                                                                                {parseFloat(
                                                                                    this.state.items.item4.totalPrice
                                                                                ).toLocaleString(undefined, {
                                                                                    minimumFractionDigits: 2,
                                                                                })}
                                                                            </h3>
                                                                            <p className="text-body">
                                                                                {this.state.items.item4.subscriptionType}
                                                                            </p>
                                                                            <p className="font-weight-bold modalLine">
                                                                                $0 Maintenance Fee
                                                                            </p>

                                                                            <button
                                                                                className="btn bg-white checkout-btn" data-dismiss="modal" aria-label="Close"
                                                                                onClick={() => this.props.history.push("/login")}
                                                                            >
                                                                                Select
                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-6">
                                                                        <div className="bg-orange tiles mb-3 height-auto">
                                                                            <h2 className="subscriptionBoxInnerHeader justify-content-center height-auto">
                                                                                Via 12 Months Installment
                                                                            </h2>
                                                                            <h3 className="font-weight-bold text-white">
                                                                                $
                                                                                {parseFloat(
                                                                                    this.state.items.item4.totalPrice / 12
                                                                                ).toFixed(2)}
                                                                                <span className="perMonth">/month</span>
                                                                            </h3>
                                                                            <p className="text-body">
                                                                                {this.state.items.item4.subscriptionType}
                                                                            </p>
                                                                            <p className="font-weight-bold">
                                                                                ${this.state.MaintenanceCharges} Maintenance Fee
                                                                            </p>

                                                                            <button
                                                                                className="btn bg-white checkout-btn" data-dismiss="modal" aria-label="Close"
                                                                                onClick={() => this.props.history.push("/login")}
                                                                            >
                                                                                Select
                                                                            </button>
                                                                        </div>
                                                                    </div>


                                                                </div>



                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-12">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="bg-gray mb-3">
                                                <h6 className="text-white text-center">
                                                    Annual subscriptions get Rx Wholesaler data for free
                                                </h6>
                                                <p className="text-center font-weight-bold text-white">
                                                    {/* (All States) */}&nbsp;
                                                    <h6>
                                                        {" "}
                                                        <Link to="/ourMission" className="text-orange">
                                                            {" "}
                                                            Contact us for multi-state discounts
                                                        </Link>
                                                    </h6>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bg-white px-3 pt-3">
                                        <div className="row">
                                            <div className="col-md-6 subscription-col">
                                                <div className="bg-orange tiles mb-3 static-sups custom-offer">
                                                    <h3>Exciting<br></br>Offer<br></br>Coming Soon</h3>

                                                </div>
                                                {/* <div className="bg-orange tiles mb-3 static-sups ">
                          <h2 className="subscriptionBoxInnerHeader">
                          Exciting Offer Coming Soon
                          </h2>
                          
                        </div> */}
                                            </div>
                                            <div className="col-md-6 subscription-col">
                                                <div className="bg-orange tiles mb-3 ">
                                                    <h2 className="subscriptionBoxInnerHeader">
                                                        {this.state.items.item7.subscriptionTitle}
                                                    </h2>
                                                    <h3 className="font-weight-bold text-white">
                                                        $
                                                        {parseFloat(
                                                            this.state.items.item7.totalPrice
                                                        ).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                        })}
                                                    </h3>
                                                    <p className="text-body">
                                                        {this.state.items.item7.subscriptionType} (Includes
                                                        Data Feed)
                                                    </p>
                                                    <p className="font-weight-bold">
                                                        $
                                                        {parseFloat(
                                                            this.state.items.item7.price
                                                        ).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                        })}
                                                        /File
                                                    </p>
                                                    <p className="font-weight-bold">
                                                        All States :{" "}
                                                        {this.state.items.item7.totalFilesCountAllStates}{" "}
                                                        Files
                                                    </p>
                                                    <p className="font-weight-bold">
                                                        {this.state.items.item1.totalHospitalCountAllStates}
                                                        &nbsp; Hospitals/Clinics

                                                    </p>
                                                    {/* <button
                            className="btn bg-white checkout-btn"
                            onClick={() => {
                              this.handleCheckout(
                                this.state.items.item7.subscriptionID,
                                this.state.items.item7.filterStates,
                                this.state.items.item7.accessType
                              );
                            }}
                          >
                            Checkout
                          </button> */}
                                                    <button
                                                        className="btn bg-white checkout-btn"
                                                        data-toggle="modal" data-target="#Modalthree"
                                                        onClick={(e) => {
                                                            this.GetMaintancesCharges(this.state.items.item7.subscriptionID)
                                                        }}
                                                    // onClick={() => {
                                                    //   this.GetMaintancesCharges(this.state.items.item7.subscriptionID)
                                                    // }}
                                                    >
                                                        Checkout
                                                    </button>
                                                </div>

                                                {/* Modal */}
                                                <div className="modal fade" id="Modalthree" tabIndex="-1" aria-labelledby="ModalthreeLabel" aria-hidden="true">
                                                    <div className="modal-dialog modal-md">
                                                        <div className="modal-content">
                                                            <div className="modal-header">
                                                                <h5 className="modal-title" id="ModalthreeLabel">How do you want to pay? </h5>
                                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                    <span aria-hidden="true">&times;</span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-body">
                                                                <h2 className="bold_text_no_text_transform mb-4 text-center">
                                                                    {this.state.items.item7.subscriptionTitle}
                                                                </h2>
                                                                <div className="row">

                                                                    <div className="col-sm-6">
                                                                        <div className="bg-orange tiles mb-3 height-auto">
                                                                            <h2 className="subscriptionBoxInnerHeader justify-content-center height-auto">
                                                                                Pay Once
                                                                            </h2>
                                                                            <h3 className="font-weight-bold text-white">
                                                                                $
                                                                                {parseFloat(
                                                                                    this.state.items.item7.totalPrice
                                                                                ).toLocaleString(undefined, {
                                                                                    minimumFractionDigits: 2,
                                                                                })}
                                                                            </h3>
                                                                            <p className="text-body">
                                                                                {this.state.items.item7.subscriptionType}
                                                                            </p>
                                                                            <p className="font-weight-bold modalLine">
                                                                                $0 Maintenance Fee
                                                                            </p>

                                                                            <button
                                                                                className="btn bg-white checkout-btn" data-dismiss="modal" aria-label="Close"
                                                                                onClick={() => this.props.history.push("/login")}
                                                                            >
                                                                                Select
                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-6">
                                                                        <div className="bg-orange tiles mb-3 height-auto">
                                                                            <h2 className="subscriptionBoxInnerHeader justify-content-center height-auto">
                                                                                Via 12 Months Installment
                                                                            </h2>
                                                                            <h3 className="font-weight-bold text-white">
                                                                                $
                                                                                {parseFloat(
                                                                                    this.state.items.item7.totalPrice / 12
                                                                                ).toFixed(2)}
                                                                                <span className="perMonth">/month</span>
                                                                            </h3>
                                                                            <p className="text-body">
                                                                                {this.state.items.item7.subscriptionType}
                                                                            </p>
                                                                            <p className="font-weight-bold">
                                                                                ${this.state.MaintenanceCharges} Maintenance Fee
                                                                            </p>

                                                                            <button
                                                                                className="btn bg-white checkout-btn" data-dismiss="modal" aria-label="Close"
                                                                                onClick={() => this.props.history.push("/login")}
                                                                            >
                                                                                Select
                                                                            </button>
                                                                        </div>
                                                                    </div>


                                                                </div>



                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 subscription-col">
                                                <div className="bg-orange tiles mb-3 static-sups">
                                                    <h2 className="subscriptionBoxInnerHeader">
                                                        Pick specific hospitals for Cost Comparison Tool
                                                    </h2>
                                                    <h3 className="font-weight-bold text-white d-none d-md-block">
                                                        &nbsp;
                                                    </h3>
                                                    <p className="text-body d-none d-md-block">
                                                        &nbsp;
                                                    </p>
                                                    <h3 className="font-weight-bold text-white">
                                                        $75/File
                                                    </h3>
                                                    {/* <p className="font-weight-bold">
                            States : {this.state.selectedStateName}

                          </p> */}
                                                    {/* <p className="font-weight-bold">
                            {this.state.items.item1.totalHospitalCountAllStates}
                            &nbsp; Hospitals/Clinics
                            
                          </p> */}
                                                    {/* <button
                            className="btn bg-white checkout-btn"
                            onClick={() => {
                              this.handleCheckout(
                                this.state.items.item6.subscriptionID,
                                this.state.items.item6.filterStates,
                                this.state.items.item6.accessType
                              );
                            }}
                          >
                            Checkout
                          </button> */}
                                                    <button
                                                        type="button" title="Mail To:  info@healthcostlabs.com"
                                                        className="btn bg-white checkout-btn"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            window.location = "mailto:info@healthcostlabs.com?subject=Pick specific hospitals for Cost Comparison Tool";
                                                            //window.location.href=' http://eepurl.com/hJ4Vzv';

                                                        }}
                                                    >
                                                        <i aria-hidden="true" className="fa fa-envelope-o mr-2"></i>

                                                        Contact Us</button>
                                                </div>
                                            </div>
                                            <div className="col-md-6 subscription-col">
                                                <div className="bg-orange tiles mb-3">
                                                    <h2 className="subscriptionBoxInnerHeader">
                                                        {this.state.items.item8.subscriptionTitle}
                                                    </h2>
                                                    <h3 className="font-weight-bold text-white">
                                                        $
                                                        0
                                                        {/* {parseFloat(
                              this.state.items.item8.totalPrice
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}*/}
                                                    </h3>
                                                    <p className="text-body">
                                                        {this.state.items.item8.subscriptionType} (Includes
                                                        Data Feed)
                                                    </p>
                                                    <p className="font-weight-bold">
                                                        $
                                                        0
                                                        {/*{parseFloat(
                              this.state.items.item8.price
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })}*/}
                                                        /File
                                                    </p>
                                                    <p className="font-weight-bold">
                                                        All States :{" "}
                                                        {this.state.items.item8.totalFilesCountAllStates}{" "}
                                                        Files
                                                    </p>
                                                    <p className="font-weight-bold">
                                                        {this.state.items.item1.totalHospitalCountAllStates}
                                                        &nbsp; Hospitals/Clinics

                                                    </p>
                                                    {/*  <button
                            className="btn bg-white checkout-btn"
                            onClick={() => {
                              this.handleCheckout(
                                this.state.items.item8.subscriptionID,
                                this.state.items.item8.filterStates,
                                this.state.items.item8.accessType
                              );
                            }}
                          >
                            Checkout
                          </button>*/}
                                                    <div className="App">
                                                        <button
                                                            type="button" title="Mail To:  info@healthcostlabs.com"
                                                            className="btn bg-white checkout-btn"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                window.location = "mailto:info@healthcostlabs.com?subject=National Data Access";
                                                                //window.location.href=' http://eepurl.com/hJ4Vzv';

                                                            }}
                                                        >
                                                            <i aria-hidden="true" className="fa fa-envelope-o mr-2"></i>

                                                            Negotiable</button>


                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <h4 className="alert-info text-center pt-3 pb-3">
                                    &#9960; Contact us directly to setup a monthly recurring
                                    invoice.
                                </h4>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}
export default withRouter(SubscriptionView);
