import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import BeforeLoginMenu from "../../routes/BeforeLoginMenu";
import AfterLoginMenu from "../../routes/AfterLoginMenu";

class ProductAndServices extends Component {
    constructor(props) {
        super(props);

        this.state = {
            token: localStorage.getItem("token"),
            firstName: "",
            lastName: "",
            subject: "",
            email: "",
            message: "",
            uploadFile: "",
            fileUrl: "",
            errors: {
                firstName: "This field is required",
                lastName: "This field is required",
                email: "This field is required",
                subject: "This field is required",
                message: "This field is required",
            },
            successMessage: "",
            errorMessage: "",
            isLoaded: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFileChange = this.handleFileChange.bind(this);
    }
    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }
    handleFileChange(event) {
        this.setState({
            uploadFile: event.target.files[0],
        });

        const formData = new FormData();
        formData.append("File", this.state.uploadFile);
        axios
            .post(
                `${process.env.REACT_APP_API_URL}api/Feedback/uploadfeedbackfile`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            )
            .then((response) => {
                this.setState({
                    fileUrl: response.data.message,
                    isLoaded: true,
                });
            })
            .catch((error) => {
                this.setState({
                    errorMessage: error.message,
                });
            });
    }
    handleSubmit(event) {
        const { firstName, lastName, subject, email, message, fileUrl } =
            this.state;

        axios
            .post(
                `${process.env.REACT_APP_API_URL}api/Feedback/savefeedback`,
                {
                    FirstName: firstName,
                    LastName: lastName,
                    email: email,
                    subject: subject,
                    message: message,
                    File: fileUrl,
                }
            )
            .then((response) => {
                if (response.data.isSuccess) {
                    this.setState({
                        successMessage: response.data.message,
                        errorMessage: "",
                        firstName: "",
                        lastName: "",
                        email: "",
                        subject: "",
                        message: "",
                        uploadFile: "",
                        fileUrl: "",
                        isLoaded: false,
                    });
                }
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({
                        errorMessage: error.response.data.message,
                        successMessage: "",
                        isLoaded: false,
                    });
                }
            });
        event.preventDefault();
    }

    render() {
        return (
            <>
                {this.state.token !== null ? (
                    <AfterLoginMenu />
                ) : (
                    <BeforeLoginMenu />
                )}
                <main role="main">
                    <div className="page-bg-light-gray">
                        <div className="container">
                            <div className="col-md-12 pb-5">
                                <h3 className="text-center text-dark boldFont pb-3 pt-3">
                                    Healthcare - Why Price Transparency Matters
                                </h3>
                                <p className="text-center">
                                    <iframe
                                        width="50%"
                                        height="315"
                                        src="https://www.youtube.com/embed/7oDJSmaNu6Y"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowfullscreen
                                    ></iframe>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="text-center mb-5 text-dark text-bold boldFont pt-3">
                                    Use of Rates
                                </h3>
                            </div>
                            <div className="col-md-12">
                                <p className="text-justify">
                                    {" "}
                                    Knowledge of rates changes the playbook
                                    within Healthcare. Purchasers and hospitals
                                    have to react or respond to competitors'
                                    prices to keep or grow market share. Are you
                                    in medical debt? Use our reports to identify
                                    appropriate price points for treatment and
                                    negotiate a settlement. Employers have to
                                    supply a cost-effective benefit plan or lose
                                    their talented employees to other companies.
                                    Hospitals can now make a decision to price
                                    their treatments competitively using cash
                                    and jettison the administration cost of
                                    dealing with prior-auths and claim denials.
                                </p>
                                <p className="text-justify">
                                    Below are a few of the many use cases for
                                    the rates for different audiences.
                                </p>
                            </div>
                        </div>

                        {/* <div className="col-md-6 mx-auto text-center no-bg"></div> */}

                        <div className="col-md-12">
                            <div className="row justify-content-center productandservices-row">
                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-4">
                                    <div className="card border-none">
                                        <div className="card-body">
                                            <h1 className="card-title font-weight-bold text-center">
                                                <i
                                                    className="fa fa-users"
                                                    aria-hidden="true"
                                                ></i>
                                            </h1>
                                            <p className="card-text">
                                                <div className="font-weight-bold text-center pb-3">
                                                    Employer Groups
                                                </div>
                                                <h5 className="card-title text-orange font-weight-bold text-center">
                                                    <a
                                                        href=""
                                                        target
                                                        data-toggle="modal"
                                                        data-target="#empModal"
                                                        className="text-orange font-weight-bold mb-5 mt-5"
                                                    >
                                                        More&gt;{" "}
                                                    </a>
                                                </h5>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal" id="empModal">
                                    <div className="modal-dialog modal-dialog-scrollable">
                                        <div className="modal-content ">
                                            <div className="border-none">
                                                <button
                                                    type="button"
                                                    className="close mr-3"
                                                    data-dismiss="modal"
                                                >
                                                    &times;
                                                </button>
                                            </div>

                                            <div className="modal-body ">
                                                <p>
                                                    <strong>
                                                        Self-Insured{" "}
                                                    </strong>
                                                    <p>
                                                        Provide rates to
                                                        employees to save money
                                                        by going to low-cost
                                                        provider
                                                    </p>
                                                    <p>
                                                        Build optimal
                                                        cost-effective network
                                                    </p>
                                                    <p>
                                                        Analyze cost savings
                                                        opportunities
                                                    </p>
                                                    <p>
                                                        Identify centers of
                                                        excellence
                                                    </p>
                                                    <p>
                                                        Identify the lowest
                                                        price point for a
                                                        treatment in a market
                                                        and give the employee
                                                        100% of that cost. If
                                                        they want to go to a
                                                        more expensive hospital,
                                                        they pay the difference
                                                    </p>
                                                </p>
                                                <p>
                                                    <strong>
                                                        Fully Insured
                                                    </strong>
                                                    <p>
                                                        Use rates to determine
                                                        which carrier has the
                                                        best network rates
                                                    </p>
                                                    <p>
                                                        Know you are getting
                                                        best price for
                                                        out-of-pocket costs
                                                    </p>
                                                    <p>
                                                        Analyze impact of going
                                                        self-insured
                                                    </p>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-4">
                                    <div className="card border-none">
                                        <div className="card-body">
                                            <h1 className="card-title font-weight-bold text-center">
                                                <i className="fa fa-heart"></i>
                                            </h1>
                                            <p className="card-text">
                                                <div className="font-weight-bold text-center pb-3">
                                                    Health Insurance Companies
                                                </div>
                                                <h5 className="card-title text-orange font-weight-bold text-center">
                                                    <a
                                                        href=""
                                                        target
                                                        data-toggle="modal"
                                                        data-target="#healthModal"
                                                        className="text-orange font-weight-bold mb-5 mt-5"
                                                    >
                                                        More&gt;{" "}
                                                    </a>
                                                </h5>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal" id="healthModal">
                                    <div className="modal-dialog modal-dialog-scrollable">
                                        <div className="modal-content ">
                                            <div className="border-none">
                                                <button
                                                    type="button"
                                                    className="close mr-3"
                                                    data-dismiss="modal"
                                                >
                                                    &times;
                                                </button>
                                            </div>

                                            <div className="modal-body ">
                                                <p>
                                                    <p>
                                                        Use in negotiations with
                                                        hospitals
                                                    </p>
                                                    <p>
                                                        Better precision on
                                                        discounts
                                                    </p>
                                                    <p>
                                                        Use in marketing to new
                                                        customers. “Our rates
                                                        are cheaper, come to us”
                                                    </p>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-4">
                                    <div className="card border-none">
                                        <div className="card-body">
                                            <h1 className="card-title font-weight-bold text-center">
                                                <i
                                                    className="fa fa-usd"
                                                    aria-hidden="true"
                                                ></i>
                                            </h1>
                                            <p className="card-text">
                                                <div className="font-weight-bold text-center pb-3">
                                                    Benefit Companies
                                                </div>
                                                <h5 className="card-title text-orange font-weight-bold text-center">
                                                    <a
                                                        href=""
                                                        target
                                                        data-toggle="modal"
                                                        data-target="#benefitModal"
                                                        className="text-orange font-weight-bold mb-5 mt-5"
                                                    >
                                                        More&gt;{" "}
                                                    </a>
                                                </h5>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal" id="benefitModal">
                                    <div className="modal-dialog modal-dialog-scrollable">
                                        <div className="modal-content ">
                                            <div className="border-none">
                                                <button
                                                    type="button"
                                                    className="close mr-3"
                                                    data-dismiss="modal"
                                                >
                                                    &times;
                                                </button>
                                            </div>

                                            <div className="modal-body ">
                                                <p>
                                                    <p>Analyze Networks</p>
                                                    <p>
                                                        Identify cost saving
                                                        opportunities
                                                    </p>
                                                    <p>Use in negotiations</p>
                                                    <p>
                                                        Analyze impact of fully
                                                        insured vs self-insured
                                                    </p>
                                                    <p>
                                                        Show cost saving
                                                        opportunities for High
                                                        Deductible Plans and
                                                        Coinsurance
                                                    </p>
                                                    <p>
                                                        Create a cash only plan
                                                        backstopped with stop
                                                        loss insurance.
                                                        Eliminate the middlemen
                                                    </p>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-4 col-sm-6 col-12 mb-8">
                                    <div className="card border-none">
                                        <div className="card-body">
                                            <h1 className="card-title font-weight-bold text-center">
                                                <i className="fa fa-user-md"></i>
                                            </h1>
                                            <p className="card-text">
                                                <div className="font-weight-bold text-center pb-3">
                                                    Doctors and Hospitals
                                                </div>
                                                <h5 className="card-title text-orange font-weight-bold text-center">
                                                    <a
                                                        href=""
                                                        target
                                                        data-toggle="modal"
                                                        data-target="#docModal"
                                                        className="text-orange font-weight-bold mb-5 mt-5"
                                                    >
                                                        More&gt;{" "}
                                                    </a>
                                                </h5>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal" id="docModal">
                                    <div className="modal-dialog modal-dialog-scrollable">
                                        <div className="modal-content ">
                                            <div className="border-none">
                                                <button
                                                    type="button"
                                                    className="close mr-3"
                                                    data-dismiss="modal"
                                                >
                                                    &times;
                                                </button>
                                            </div>
                                            <div className="modal-body ">
                                                <p>Use in negotiations</p>
                                                <p>
                                                    Pouch market share from
                                                    higher cost hospitals
                                                </p>
                                                <p>
                                                    Discount your rates to puts
                                                    heads-in-beds if you have
                                                    vacancies
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-6 col-12 mb-4">
                                    <div className="card border-none">
                                        <div className="card-body">
                                            <h1 className="card-title font-weight-bold text-center">
                                                <i className="fa fa-desktop"></i>
                                            </h1>
                                            <p className="card-text">
                                                <div className="font-weight-bold text-center pb-3">
                                                    Data for Members
                                                </div>
                                                <h5 className="card-title text-orange font-weight-bold text-center">
                                                    <a
                                                        href=""
                                                        target
                                                        data-toggle="modal"
                                                        data-target="#dataModal"
                                                        className="text-orange font-weight-bold mb-5 mt-5"
                                                    >
                                                        More&gt;{" "}
                                                    </a>
                                                </h5>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal" id="dataModal">
                                    <div className="modal-dialog modal-dialog-scrollable">
                                        <div className="modal-content ">
                                            <div className="border-none">
                                                <button
                                                    type="button"
                                                    className="close mr-3"
                                                    data-dismiss="modal"
                                                >
                                                    &times;
                                                </button>
                                            </div>
                                            <div className="modal-body ">
                                                <p>
                                                    High Deductible Plans - Save
                                                    money by going to the
                                                    low-cost provider
                                                </p>
                                                <p>
                                                    Coinsurance - reduce
                                                    coinsurance bills by
                                                    identifying low-cost
                                                    provider in advance
                                                </p>
                                                <p>
                                                    No Insurance - Use rates to
                                                    identify cash prices or
                                                    negotiate cost of service
                                                </p>
                                                <p>
                                                    Question why your carrier
                                                    has higher rates and which
                                                    carrier has cheapest rates
                                                </p>
                                                <p>
                                                    Identify the lowest rate a
                                                    hospital will accept as
                                                    payment in full for a
                                                    treatment. Tell the hospital
                                                    you’ll go there but for only
                                                    that price.
                                                </p>
                                                {/* <p>
                                                    Are you in medical debt? Now
                                                    if you could see if the
                                                    hospital overcharged you.
                                                    Sue them in small claims
                                                    court with our reports to
                                                    support your claim.
                                                    <br />
                                                    <a
                                                        href="https://www.amazon.com/Never-Pay-First-Bill-Health/dp/0593190009"
                                                        target="_blank();"
                                                    >
                                                        https://www.amazon.com/Never-Pay-First-Bill-Health/dp/0593190009
                                                    </a>
                                                </p> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}
export default withRouter(ProductAndServices);
