import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import queryString from "query-string";
import BeforeLoginMenu from "../../routes/BeforeLoginMenu";
import AfterLoginMenu from "../../routes/AfterLoginMenu";

class ConfirmEmail extends Component {
    constructor(props) {
        super(props);

        const token = localStorage.getItem("token");
        if (!token) {
            this.props.history.push("/login");
        }

        this.state = {
            token: localStorage.getItem("token"),
            isVerify: false,
            errorMessage: "",
            successMessage: "",
        };
    }

    confirmEmail() {
        const { email, token } = queryString.parse(this.props.location.search);
        axios
            .post(`${process.env.REACT_APP_API_URL}api/auth/ConfirmEmail`, {
                Email: email,
                Token: token,
            })
            .then((response) => {
                if (response.data.isSuccess) {
                    this.setState({
                        isVerify: true,
                    });
                }
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({
                        errorMessage: error.response.message,
                    });
                }
            });
    }

    componentDidMount() {
        this.confirmEmail();
    }

    render() {
        if (this.state.isVerify) {
            this.message = (
                <div
                    className="alert alert-success alert-dismissible fade show"
                    role="alert"
                >
                    Your email has been verified successfully.
                    <br />
                    <Link to="/profile" className="btn btn-primary">
                        Go to Profile
                    </Link>
                </div>
            );
        } else if (this.state.errorMessage !== "") {
            this.message = (
                <div
                    className="alert alert-danger alert-dismissible fade show"
                    role="alert"
                >
                    {this.state.errorMessage}
                </div>
            );
        } else {
            this.message = (
                <div
                    className="alert alert-info alert-dismissible fade show"
                    role="alert"
                >
                    Loading...
                </div>
            );
        }
        return (
            <>
                {this.state.token !== null ? (
                    <AfterLoginMenu />
                ) : (
                    <BeforeLoginMenu />
                )}
                <main role="main">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12 col-lg-12">
                                <h1>Confirm Email</h1>
                                <div className="card mb-4">
                                    <div className="card-body">
                                        {this.message}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default withRouter(ConfirmEmail);
