import React from "react";
import AppRoute from "./routes/AppRoute";

function App() {


  return (
    <>
      <AppRoute />

      <div className="footer-background text-center">
        <footer className="container">
          <div className="row">
            <div className="col-5 text-left">
              <ul className="list-inline mt-3 footer-link">
                <li className="list-inline-item">
                  <a href="/terms-condition">Terms & Conditions</a>
                </li>
                <li className="list-inline-item">|</li>
                <li className="list-inline-item">
                  <a href="/privacy-policy">Privacy Policy</a>
                </li>
              </ul>
            </div>
            <div className="col-7 text-right">
              <div className="footer-text">Copyright © 2021 Health Cost Labs</div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default App;
