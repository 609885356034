import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import BeforeLoginMenu from "../../routes/BeforeLoginMenu";
import AfterLoginMenu from "../../routes/AfterLoginMenu";
import axios from "axios";

class UserList extends Component {
    constructor(props) {
        super(props);

        const token = localStorage.getItem("token");
        if (!token) {
            this.props.history.push("/login");
        }

        this.state = {
            token: token,
            items: [],
        };
    }

    refreshToken() {
        const token = localStorage.getItem("token");
        const refreshToken = localStorage.getItem("refreshToken");
        const expired = localStorage.getItem("expired");

        if (token !== null && refreshToken !== null && expired !== null) {
            axios
                .post(
                    `${process.env.REACT_APP_API_URL}api/auth/refreshToken`,
                    {
                        token: token,
                        refreshToken: refreshToken,
                        expiration: expired,
                    }
                )
                .then((response) => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("refreshToken");
                    localStorage.removeItem("expired");
                    localStorage.setItem("token", response.data.token);
                    localStorage.setItem(
                        "refreshToken",
                        response.data.refreshToken
                    );
                    localStorage.setItem("expired", response.data.expiration);
                })
                .catch((error) => {
                    if (
                        error.response.status === 400 ||
                        error.response.status === 401 ||
                        error.response.status === 403
                    ) {
                        localStorage.removeItem("token");
                        localStorage.removeItem("email");
                        localStorage.removeItem("userID");
                        localStorage.removeItem("userName");
                        localStorage.removeItem("secret_key");
                        localStorage.removeItem("refreshToken");
                        localStorage.removeItem("expired");
                        this.props.history.push("/login");
                    }
                });
        }
    }

    getUserList() {
        axios
            .get(`${process.env.REACT_APP_API_URL}api/user/getusers`, {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                    secret_key: localStorage.getItem("secret_key"),
                },
            })
            .then((response) => {
                this.setState({
                    items: Object.values(response.data),
                });
            })
            .catch((error) => {
                if (
                    error.response.status === 400 ||
                    error.response.status === 401 ||
                    error.response.status === 403
                ) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("email");
                    localStorage.removeItem("userID");
                    localStorage.removeItem("userName");
                    localStorage.removeItem("secret_key");
                    localStorage.removeItem("refreshToken");
                    localStorage.removeItem("expired");
                    this.props.history.push("/login");
                }
            });
    }

    convertDateTime(fullDate) {
        if (fullDate !== null) {
            var timestamp = Date.parse(fullDate);
            var newdate = new Date(timestamp);
            var dateString =
                newdate.getMonth() +
                1 +
                "-" +
                newdate.getDate() +
                "-" +
                newdate.getFullYear() +
                " " +
                newdate.getHours() +
                ":" +
                newdate.getMinutes() +
                ":" +
                newdate.getSeconds();

            return dateString;
        } else {
            return "";
        }
    }

    componentDidMount() {
        this.refreshToken();
        this.getUserList();
    }

    render() {
        return (
            <>
                {this.state.token !== null ? (
                    <AfterLoginMenu />
                ) : (
                    <BeforeLoginMenu />
                )}
                <main role="main">
                    <div className="container">
                        {this.state.token ? <AfterLoginMenu /> : ""}
                        <div className="row justify-content-center">
                            {this.state.items.length > 0 && (
                                <div className="col-md-12 col-lg-12">
                                    <h1>User List</h1>
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col">First Name</th>
                                                <th scope="col">Last Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Address</th>
                                                <th scope="col">Zip</th>
                                                <th scope="col">Last Login</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.items.map(
                                                (item, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {item.firstName}
                                                        </td>
                                                        <td>{item.lastName}</td>
                                                        <td>{item.emailID}</td>
                                                        <td>{item.address}</td>
                                                        <td>{item.zip}</td>
                                                        <td>
                                                            {this.convertDateTime(
                                                                item.lastLogin
                                                            )}
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default withRouter(UserList);
