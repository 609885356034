import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import BeforeLoginMenu from "../../routes/BeforeLoginMenu";
import AfterLoginMenu from "../../routes/AfterLoginMenu";

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem("token"),
   }

  }

  render() {
    return (
      <>
        {this.state.token !== null ? <AfterLoginMenu /> : <BeforeLoginMenu />}
        <main role="main">
          <div className="pt-5 pb-5">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="text-center mb-5 text-dark text-bold boldFont">
                  Privacy Policy
                  </h3>
                </div>
                <div className="col-md-12">
                  <h5>INTRODUCTION</h5>
                  <p className="text-justify">
                    {" "}
                    The mission of Health Cost Labs is to provide insurance, financial professionals and consumers with the tools to enable them to identify lower cost alternatives. To achieve our mission, we make services available through our website and mobile applications to help you learn, work, find opportunities and make decisions                  </p>
                    <p>Being part of Health Cost Labs means sharing information about you with Health Cost Labs and storing information on the Health Cost Labs website. But the amount and type of information you decide to share is up to you. You have control over what you share, and you can update your information at any time. Your personally identifiable information is private and confidential, and we do not share, distribute, rent, sell, or otherwise provide any personally identifiable information to others. All personally identifiable information that you provide will be protected with industry standard protocols and technology.</p>
                    <p>We reserve the right to modify this Privacy Policy at any time, so please review it frequently. If we make material changes to this policy, we will notify you on our homepage, by email, or by notice through contact information provided by you. By continuing to use the Health Cost Labs Service after notice of changes has been sent to you or published on the Health Cost Labs website, you are consenting to the changes.</p>
                    <p><i>A User</i> for purposes of this Privacy Policy is defined as any SUBSCRIBER as defined in our Online Subscription Agreement; or any authorized person who has been assigned a username and password to access the Services under a corporate enterprise Agreement.
Please read this policy and send us questions, concerns and suggestions at customerservice@healthcostlabs.com.
</p>
<ol>
  <li><h4>PERSONAL INFORMATION COLLECTED</h4>
    <p>By subscribing to Health Cost Labs, you voluntarily and willingly provide us certain information, including personally identifiable information, which we collect in order to provide the Services.</p>
    <p>We collect your personal information in the following ways:</p>
    <ol type="A">
      <li><i>Registration. </i>In order to become a User, you must provide certain information to create an account, including name, address, phone numbers, email address, employer, type of producer, and password. Like other passwords, you should choose one that is known only by you. You acknowledge that this information is personal to you, and by creating an account on Health Cost Labs, you allow Health Cost Labs to identify you.</li>
      <li><i>Profile Information.</i> Once you become a User, you may provide additional information to Health Cost Labs. Providing additional information about yourself beyond what is minimally required at registration is entirely up to you, but providing additional information enables you to derive more benefit from Health Cost Labs by helping Health Cost Labs to better customize your user experience. Any information you provide at registration or in the Profile section may be used by Health Cost Labs as described in the Subscription Agreement Terms and Conditions and this Privacy Policy (collectively referred to as "Terms of Service"), including for the purpose of serving advertisements through the service. If you decide not to share certain information, you may not receive the full benefit of being a Health Cost Labs User.</li>
      <li><i>Customer Service.</i> We may collect information through the Health Cost Labs website in order to, among other things, accurately categorize and respond to customer inquiries and investigate breaches of our terms.</li>
      <li><i>Site Usage.</i> We may collect information about the fact that you use certain features and functionality of Health Cost Labs, interact with third party applications, click on ads, or participate in research initiatives like polling and surveys on Health Cost Labs.</li>
      <li><i>Cookies.</i> Like most websites, we use cookies and web log files to track site usage and trends, to improve the quality of our service, to customize your experience on Health Cost Labs, implement security, as well as to deliver third party advertising to Users on the Health Cost Labs site. A cookie is a tiny data file that resides on your computer, mobile phone, or other device, and allows us to recognize you as an existing User when you return to the Health Cost Labs website using the same computer and web browser. You can remove or block cookies using the settings in your browser, but in some cases that may impact your ability to use Health Cost Labs.
In the course of serving advertisements or optimizing the Services to our Users, we may allow authorized third parties to place or recognize a unique cookie on your browser. Any information provided to third parties through cookies will not be personally identifiable but may provide general segment information (e.g., your producer type or geography, or information about your targeted industries or Partners) for greater customization of your user experience. Most browsers are initially set up to accept cookies, but you can reset your browser to refuse all cookies or to indicate when a cookie is being sent. Health Cost Labs does not store unencrypted personally identifiable information in the cookies.
</li>
<li> <i>Log files, IP addresses and information about your computer and mobile device.</i> We may collect additional information from you if you access the Services through a mobile device, for example we may collect your unique device identifier, your device’s operating system, mobile carrier, or your location when you opt in for us to do so. However the degree to which your location can be identified depends on the device you are using (e.g. laptop, smartphone, tablet) and how you are connected to the internet (e.g. via cable broadband connection, Wi-Fi, etc.). If you enable location services for the Services we may collect your regional location data periodically as you use or leave open the Services. We may also collect your location information from your IP address or zip code.</li>
<li> <i>Linked Accounts. </i>If you link your Health Cost Labs account to another account or service, or use other third party sites or services in conjunction with your Health Cost Labs account, we may collect information related to your use of third party sites and services accessed through Health Cost Labs or when you access Health Cost Labs through a third party site or service.</li>
<li><i>Rights to Access, Correct and Eliminate Information About You.</i> You have a right to access, modify, correct and eliminate the data you supplied to Health Cost Labs. If you update any of your information, we may keep a copy of the information that you originally provided to us in our archives for uses documented in this policy. You may request deletion of your information at any time by contacting Health Cost Labs customer service. We will respond to your request within thirty (30) days.</li>
<li><i>User History:</i> We may log certain usage information about your use of the Services, which may include a history of the pages you view. If you download and use any ALM software or application on your device, the log files will automatically be collected by the application and sent to our servers when your device syncs up for the latest content available.</li>
    </ol>
  </li>
  <li><h4>USES OF PERSONAL INFORMATION</h4>
      <ol type="A">
        <li><i>Consent to Health Cost Labs Processing Information About You.</i> The information you provide to Health Cost Labs may reveal your nationality, ethnic origin, religion, gender, age, geography, or other aspects of your private life. By providing information to us for the purposes of creating your User account or adding any additional details to your Health Cost Labs profile, you are expressly and voluntarily accepting the terms and conditions of this Privacy Policy and the Subscription Agreement that allow Health Cost Labs to process information about you. Supplying information to Health Cost Labs, including any information deemed "sensitive" by applicable law, is entirely voluntary on your part. You have the right to withdraw your consent to Health Cost Labs collection and processing of your information at any time, in accordance with the terms of this Privacy Policy and the Subscription Agreement by closing your account, but please note that your withdrawal of consent will not be retroactive.</li>
        <li><p><i>Health Cost Labs Communications.</i> As described in the Subscription Agreement, we will communicate with you through email and notices posted on the Health Cost Labs website, or through other means available through the service, including mobile text messages. We may send you a series of emails that help inform new Users about the features of the Services, and we will also send you service messages relating to the functioning of the Services. We may also send you messages with promotional information directly or on behalf of our partners, unless you have opted out of receiving promotional information.
You can change your e-mail and contact preferences at any time by contacting Health Cost Labs Customer Service at <a href="mailto:customerservice@healthcostlabs.com">customerservice@healthcostlabs.com</a>.  If you wish, you can also opt-out of receiving promotional emails by sending a request to Health Cost Labs at <a href="mailto:customerservice@healthcostlabs.com">customerservice@healthcostlabs.com</a> .</p>
<p>
Please be aware that you cannot opt-out of receiving service messages from Health Cost Labs. Also, if we send communications to you via the Partner service with which you have a mobile communications subscription or otherwise have access, you understand you will pay any service fees associated with your receipt of messages on your mobile device (including text messaging charges).
</p>
</li>
<li><p><i>Sharing Information with Third Parties.</i> Health Cost Labs takes the privacy of our Users very seriously. We do not sell, trade, or otherwise transfer to outside parties Users’ personally identifiable information unless we provide the User with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our Users, so long as those parties agree to keep this information confidential.  Non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.</p>
<p>We may provide aggregated anonymous data about the usage of the Services to third parties for purposes that we deem, in our sole discretion, to be appropriate, including to prospective advertisers on Health Cost Labs.</p>
</li>
<li><p><i>Third-party links. </i>Occasionally, at our discretion, we may include or offer third-party products or services on our web site. These third-party sites have separate and independent privacy policies (which we encourage you to review). We therefore have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.</p>
<p>We have implemented the following Google services on our web site:</p>
<ul>
  <li>Google Display Network Impression Reporting</li>
  <li>Demographics and Interests Reporting</li>
  <li>Google Analytics Advertising Features</li>
  <li>Google Adwords Remarketing</li>
  <li>Google Analytics Remarketing</li>
  <li>Google Maps/Google Earth</li>
</ul>
<p>We, along with third-party vendors such as Google, use first-party cookies (such as the Google Analytics cookies) and third-party cookies (such as the DoubleClick cookie) or other third-party identifiers together to compile data regarding user interactions with ad impressions and other ad service functions as they relate to our web site. This data may be used to display Health Cost Labs advertising content across the Internet by Google or other third-party vendors.</p>
<p>Google Analytics Advertising Features enables data to be collected via cookies (including the Google Analytics cookie and Google advertising cookies), about our web site's traffic. Such data is not personally identifiable.</p>
<p>Google Adwords Remarketing and Google Analytics Remarketing are services that use cookies (using information that is not personally identifiable) to serve ads based on someone's past visits to our web site. This may employ use of the DoubleClick cookie.
Opting out:
</p>
<p>Users can set preferences for how Google advertises to you using the Google Ad Settings page (including opting out of their cookies and/or cross-device remarketing). Alternatively, you can opt out by visiting the Network Advertising Initiative opt-out page or permanently using the Google Analytics Opt-out Browser Add-on.  Health Cost Labs Users are bound by Google Maps/Google Earth Additional Terms of Service (including the Google Privacy Policy)</p>
</li>
<li><p><i>Polls and Surveys.</i> Polls and Surveys may be conducted by Health Cost Labs or third parties. As a User, you may be invited to participate in polls and surveys from the Health Cost Labs website. Your selection may be random, or it may be based on your non-personally identifiable information, such as producer type, geography, and/or target industry. Whether or not you decide to participate in a poll or survey is completely up to you. After you complete a poll, you will be given access to the aggregate responses of the poll on a results page. Some third parties may target advertisements to you on the results page based on your answers in the poll.</p>
<p>We may use third parties to deliver incentives to you to participate in surveys or polls. If the delivery of incentives requires your contact information, you may be asked to provide personally identifiable information to the third party fulfilling the incentive offer, which will only be used for the purpose of delivering incentives and/or verifying your contact information. It is up to you whether you provide this information, or whether you desire to take advantage of an incentive.
We will not disclose any personally identifiable information to any third parties in connection with the conduct of any polls or surveys. Your consent to use any personally identifiable information for the purposes set forth in the poll or survey will be explicitly requested by the party conducting it. You may opt-out of participating in Surveys by contacting customer service to stop receiving these inquiries and requests.
</p>
</li>
   <li><i>Testimonials and Advertisements Placed through Health Cost Labs Ads Service.</i> If you provide any testimonials about the Health Cost Labs Services, we may post those testimonials in connection with our promotion of these services to third parties. Testimonials may include your name and other personal information that you have provided.</li>
   <li><i>Compliance with Legal Process.</i> It is possible that we may need to disclose personal information, profile information and/or information about your activities as a Health Cost Labs User when required by subpoena or other legal process, or if Health Cost Labs has a good faith belief that disclosure is necessary to (a) investigate, prevent or take action regarding suspected or actual illegal activities or to assist government enforcement agencies; (b) to enforce the User Agreement, to investigate and defend ourselves against any third party claims or allegations, or to protect the security or integrity of our site; and/or (c) to exercise or protect the rights, property or personal safety of Health Cost Labs, our Users, employees, or others.</li>
   <li><i>Disclosures to others. </i>We may also disclose your personal information and other information you provide to another third party as part of a reorganization or a sale of the assets of Health Cost Labs, a subsidiary or division. Any third party to which Health Cost Labs transfers or sells Health Cost Labs assets will have the right to continue to use the personal and other information that you provide to us.</li>
      </ol>
  </li>
  <li><h4>YOUR INFORMATION CHOICES</h4>
      <ol type="A">
        <li><i>Accessing and Changing Your Account Information.</i> You can review the personal information you provided to us and make any desired changes to your contact information, or to the settings for your Health Cost Labs account by contacting Health Cost Labs at <a href="mailto:customerservice@healthcostlabs.com">customerservice@healthcostlabs.com</a>. You can change your login password by accessing My Account while logged in, and you can change your billing information by accessing the Billing Portal while logged in. Please be aware that even after your request for a change is processed, Health Cost Labs may, for a time, retain residual information about you in its backup and/or archival copies of its database.</li>
        <li><i>Default Settings.</i> Health Cost Labs has established what we believe to be reasonable default settings that most professionals desire. Because Users may use and interact with Health Cost Labs in a variety of ways, and because those uses may change over time, we designed our settings to provide our Users granular control over the information they share. We encourage our Users to review their account settings and adjust them in accordance with their preferences.</li>
        <li><i>Closing Your Account.</i> You can close your account by contacting Health Cost Labs customer service at customerservice@healthcostlabs.com. If you close your account, we have no obligation to retain your information, and may delete any or all of your account information without liability. However, we may retain certain data contributed by you if Health Cost Labs believes it may be necessary to prevent fraud or future abuse, or for legitimate business purposes, such as analysis of aggregated, non-personally identifiable data, account recovery, or if required by law. Health Cost Labs may also retain and use your information if necessary to provide the Services to other Users. Health Cost Labs disclaims any liability in relation to the deletion or retention (subject to the terms herein) of information or any obligation not to delete the information.</li>
      </ol>
  </li>
  <li><h4>IMPORTANT INFORMATION</h4>
      <ol type="A">
        <li><i>Children and Minors.</i> Children are not eligible to use our Service and we ask that minors (under the age of 18) do not submit any personal information to us or use the Service.</li>
        <li><i>Changes to this Privacy Policy.</i> We may update this Privacy Policy at any time, with or without advance notice. In the event there are significant changes in the way we treat your personally identifiable information, or in the Privacy Policy document itself, we will display a notice on the Health Cost Labs website or send you an email, as provided for above. Unless stated otherwise, our current Privacy Policy applies to all information that Health Cost Labs has about you and your account.  Using the Health Cost Labs Services after a notice of changes has been sent to you or published on our Site shall constitute consent to the changed terms or practices.</li>
      </ol>
  </li>
  <li><h4>SECURITY</h4>
      <p>In order to help secure your personal information, access to your data on Health Cost Labs is password-protected, and sensitive data (such as credit card information) is protected by SSL encryption when it is exchanged between your web browser and the Health Cost Labs website. To protect any data you store on our servers, Health Cost Labs also regularly audits its system for possible vulnerabilities and attacks, and we use a tier-one, secured-access data center. However, since the internet is not a 100% secure environment, we cannot ensure or warrant the security of any information you transmit to Health Cost Labs. There is no guarantee that information may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards. It is your responsibility to protect the security of your login information.</p>
  </li>
  <li><h4>HOW TO CONTACT US</h4>
      <p>If you have questions or comments about this Privacy Policy, please email us at: <a href="mailto:customerservice@healthcostlabs.com">customerservice@healthcostlabs.com</a></p>
  </li>
</ol>

                </div>
              </div>
            </div>
     
  
          
              </div>
          
        </main>
      </>
    );
  }
}
export default withRouter(PrivacyPolicy);
