import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import BeforeLoginMenu from "../../routes/BeforeLoginMenu";
import AfterLoginMenu from "../../routes/AfterLoginMenu";

class Registration extends Component {
    constructor(props) {
        super(props);

        const token = localStorage.getItem("token");
        if (token) {
            this.props.history.push("/profile");
        }

        this.state = {
            token: localStorage.getItem("token"),
            firstName: "",
            lastName: "",
            fullName: "",
            address: "",
            zip: "",
            roleName: "",
            email: "",
            password: "",
            confirmPassword: "",
            securityQuestion: "",
            securityAnswer: "",
            errors: {
                firstName: "This field is required",
                lastName: "This field is required",
                fullName: "This field is required",
                email: "This field is required",
                password: "This field is required",
                confirmPassword: "This field is required",
                //roleName: "This field is required",
                //securityQuestion: "This field is required",
            },
            successMessage: "",
            errorMessage: "",
            isValidStep1: false,
            isValidStep2: false,
            step: 1,
            roles: [],
            questions: [],
            showLoader: false,
        };

        localStorage.removeItem("token");
        localStorage.removeItem("expired");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("secret_key");
        localStorage.removeItem("email");
        localStorage.removeItem("userID");
        localStorage.removeItem("userName");

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    // getRoles() {
    //     axios
    //         .get(`${process.env.REACT_APP_API_URL}api/auth/AllRoles`)
    //         .then((response) => {
    //             this.setState({
    //                 roles: Object.values(response.data),
    //             });
    //         })
    //         .catch((error) => {
    //             this.setState({
    //                 roles: "",
    //             });
    //         });
    // }

    // getSecurityQuestion() {
    //   axios
    //     .get(`${process.env.REACT_APP_API_URL}api/auth/getsecurityquestions`)
    //     .then((response) => {
    //       this.setState({
    //         questions: Object.values(response.data),
    //       });
    //     })
    //     .catch((error) => {
    //       this.setState({
    //         questions: "",
    //       });
    //     });
    // }

    // componentDidMount() {
    //   //this.getRoles();
    //   //this.getSecurityQuestion();
    // }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    nextStep = (e) => {
        e.preventDefault();
        const { step } = this.state;
        this.setState({
            step: step + 1,
        });
    };

    prevStep = () => {
        const { step } = this.state;
        this.setState({
            step: step - 1,
        });
    };

    validate(event) {
        const { name, value } = event.target;
        const errors = this.state.errors;

        switch (name) {
            case "firstName":
                if (/[^a-z A-Z ]/.test(value)) {
                    errors.firstName = "First Name should be alphabetic.";
                } else if (value.trim() == "") {
                    errors.firstName = "First Name should not be blank.";
                } else {
                    errors.firstName = "";
                }
                break;
            case "lastName":
                if (/[^a-z A-Z ]/.test(value)) {
                    errors.lastName = "Last Name should be alphabetic.";
                } else if (value.trim() == "") {
                    errors.lastName = "Last Name should not be blank.";
                } else {
                    errors.lastName = "";
                }
                break;
            case "fullName":
                if (/[^a-z A-Z ]/.test(value)) {
                    errors.fullName = "Full Name should be alphabetic.";
                } else if (value.trim() == "") {
                    errors.fullName = "Full Name should not be blank.";
                } else {
                    errors.fullName = "";
                }
                break;
            case "email":
                let regEmail =
                    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
                if (!regEmail.test(value)) {
                    errors.email = "Email is not valid.";
                } else {
                    errors.email = "";
                }
                break;
            case "password":
                var passErrors = [];

                if (value.search(/[A-Z]/) < 0) {
                    passErrors.push(
                        "Password must have at least one uppercase ('A'-'Z')."
                    );
                }
                if (value.search(/[a-z]/) < 0) {
                    passErrors.push(
                        "Password must have at least one lowercase ('a'-'z')."
                    );
                }
                if (value.search(/[0-9]/) < 0) {
                    passErrors.push(
                        "Password must have at least one digit ('0'-'9')."
                    );
                }
                if (value.search(/[!@#$%^&*]/) < 0) {
                    passErrors.push(
                        "Password must have at least one special character."
                    );
                }

                if (passErrors.length > 0) {
                    errors.password = passErrors.join("\n\t");
                } else {
                    errors.password = "";
                }
                break;
            case "confirmPassword":
                var password = this.state.password;
                if (password !== value) {
                    errors.confirmPassword =
                        "Password and Confirm Password must be same.";
                } else {
                    errors.confirmPassword = "";
                }
                break;
            // case "roleName":
            //     if (value === "") {
            //         errors.roleName = "Please choose a role.";
            //     } else {
            //         errors.roleName = "";
            //     }
            //     break;
            // case "securityQuestion":
            //   if (value === "") {
            //     errors.securityQuestion = "Please choose a question.";
            //   } else {
            //     errors.securityQuestion = "";
            //   }
            //break;
            default:
                break;
        }
        this.setState({
            errors,
            [name]: value,
        });
        if (
            //errors.firstName.length === 0 &&
            //errors.lastName.length === 0 &&
            //errors.fullName.length === 0 &&
            errors.email.length === 0 &&
            errors.password.length === 0 &&
            errors.confirmPassword.length === 0
            //errors.email.length === 0 &&
            //errors.roleName.length === 0
        ) {
            this.setState({
                isValidStep1: true,
            });
        } else {
            this.setState({
                isValidStep1: false,
            });
        }
        // if (
        //   errors.password.length === 0 &&
        //   errors.confirmPassword.length === 0
        //   // errors.securityQuestion.length === 0
        // ) {
        //   this.setState({
        //     isValidStep1: true,
        //   });
        // } else {
        //   this.setState({
        //     isValidStep1: false,
        //   });
        // }
    }

    handleSubmit(event) {
        const {
            firstName,
            lastName,
            fullName,
            address,
            zip,
            roleName,
            email,
            password,
            confirmPassword,
            securityQuestion,
            securityAnswer,
        } = this.state;
        this.setState({
            showLoader: true,
        });
        axios
            .post(`${process.env.REACT_APP_API_URL}api/Auth/Register`, {
                //FirstName: firstName,
                //LastName: lastName,
                FullName: email,
                //Address: "", //address,
                //ZIP: "", //zip,
                email: email,
                RoleName: "HCL Web User",
                Password: password,
                ConfirmPassword: confirmPassword,
                //QuestionID: 1, //Number(securityQuestion),
                // Answer: "", //securityAnswer,
            })
            .then((response) => {
                if (response.data.isSuccess) {
                    // after registration auto login 
                    this.setState({
                        successMessage: response.data.message,
                    });
                    axios
                        .post(`${process.env.REACT_APP_API_URL}api/Auth/login`, {
                            UserName: email,
                            password: password,
                        })
                        .then((response) => {
                            if (response.data.isSuccess) {
                                localStorage.setItem("token", response.data.token);
                                localStorage.setItem("email", response.data.emailID);
                                localStorage.setItem("userID", response.data.userID);
                                localStorage.setItem("userName", response.data.userName);
                                localStorage.setItem("secret_key", response.data.secval);
                                localStorage.setItem(
                                    "refreshToken",
                                    response.data.refreshToken
                                );
                                localStorage.setItem("expired", response.data.expireDate);
                                // this.props.history.push("/profile");
                                this.props.history.push("/");
                            }
                        })
                        .catch((error) => {
                            if (error.response) {
                                this.setState({
                                    loginerror: error.response.data.message,
                                });
                            }
                        });
                        
                        // this.setState({
                        //     successMessage: response.data.message,
                        //     errorMessage: "",
                        //     firstName: "",
                        //     lastName: "",
                        //     fullName: "",
                        //     address: "",
                        //     zip: "",
                        //     roleName: "",
                        //     email: "",
                        //     password: "",
                        //     confirmPassword: "",
                        //     QuestionID: "",
                        //     Answer: "",
                        //     step: 1,
                        //     isValidStep1: false,
                        //     isValidStep2: false,
                        //     showLoader: false,
                        // });
                    
                }
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({
                        errorMessage: error.response.data.message,
                        successMessage: "",
                        showLoader: false,
                    });
                }
            });
        event.preventDefault();
    }

    render() {
        return (
            <>
                {this.state.token !== null ? (
                    <AfterLoginMenu />
                ) : (
                    <BeforeLoginMenu />
                )}
                {this.state.showLoader && (
                    <div className="loader-bg" id="preloader">
                        <div className="loader"></div>
                    </div>
                )}
                <main role="main">
                    <div className="login_bg">
                        <div className="pt-5 pb-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6 col-sm-10 col-12 mx-auto text-center no-bg">
                                        <h3 className="page-title text-white mb-5 font-weight-bold">
                                            Sign up
                                        </h3>
                                        {this.state.errorMessage !== "" && (
                                            <div
                                                className="alert alert-danger alert-dismissible fade show"
                                                role="alert"
                                            >
                                                {this.state.errorMessage}
                                                <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="alert"
                                                    aria-label="Close"
                                                ></button>
                                            </div>
                                        )}
                                        {this.state.successMessage !== "" && (
                                            <div
                                                className="alert alert-success alert-dismissible fade show"
                                                role="alert"
                                            >
                                                {this.state.successMessage}
                                                <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="alert"
                                                    aria-label="Close"
                                                ></button>
                                            </div>
                                        )}
                                        <form
                                            className="login-form"
                                            onSubmit={this.handleSubmit}
                                        >
                                            {this.state.step === 1 && (
                                                <>
                                                    {/* <div className="form-row mb-4">
                                                        <div className="col">
                                                            <input
                                                                type="text"
                                                                name="firstName"
                                                                value={
                                                                    this.state
                                                                        .firstName
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    this.handleChange(
                                                                        e
                                                                    );
                                                                    this.validate(
                                                                        e
                                                                    );
                                                                }}
                                                                className={
                                                                    this.state
                                                                        .errors
                                                                        .firstName &&
                                                                    this.state
                                                                        .errors
                                                                        .firstName !==
                                                                        "This field is required"
                                                                        ? "form-control rounded-left is-invalid"
                                                                        : "form-control rounded-left"
                                                                }
                                                                placeholder="First Name"
                                                                required
                                                            />
                                                            <div className="invalid-feedback errMsg text-left">
                                                                {this.state
                                                                    .errors
                                                                    .firstName !==
                                                                    "" &&
                                                                    this.state
                                                                        .errors
                                                                        .firstName !==
                                                                        "This field is required" &&
                                                                    this.state
                                                                        .errors
                                                                        .firstName}
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <input
                                                                type="text"
                                                                name="lastName"
                                                                value={
                                                                    this.state
                                                                        .lastName
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    this.handleChange(
                                                                        e
                                                                    );
                                                                    this.validate(
                                                                        e
                                                                    );
                                                                }}
                                                                className={
                                                                    this.state
                                                                        .errors
                                                                        .lastName &&
                                                                    this.state
                                                                        .errors
                                                                        .lastName !==
                                                                        "This field is required"
                                                                        ? "form-control rounded-left is-invalid"
                                                                        : "form-control rounded-left"
                                                                }
                                                                placeholder="Last Name"
                                                                required
                                                            />
                                                            <div className="invalid-feedback errMsg text-left">
                                                                {this.state
                                                                    .errors
                                                                    .lastName !==
                                                                    "" &&
                                                                    this.state
                                                                        .errors
                                                                        .lastName !==
                                                                        "This field is required" &&
                                                                    this.state
                                                                        .errors
                                                                        .lastName}
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    {/* <div className="form-group">
                          <textarea
                            name="address"
                            value={this.state.address}
                            onChange={this.handleChange}
                            className="form-control rounded-left"
                            placeholder="Address"
                          ></textarea>
                        </div>
                        <div className="form-group">
                          <input
                            type="text"
                            name="zip"
                            value={this.state.zip}
                            onChange={this.handleChange}
                            className="form-control rounded-left"
                            placeholder="ZIP"
                          />
                        </div> */}
                                                    {/* <div className="form-group">
                                                <select
                                                    name="roleName"
                                                    onChange={(e) => {
                                                        this.handleChange(e);
                                                        this.validate(e);
                                                    }}
                                                    className={
                                                        this.state.errors
                                                            .roleName &&
                                                        this.state.errors
                                                            .roleName !==
                                                            "This field is required"
                                                            ? "form-control rounded-left is-invalid"
                                                            : "form-control rounded-left"
                                                    }
                                                    required
                                                >
                                                    <option value="">
                                                        -Select Role-
                                                    </option>
                                                    {this.state.roles.map(
                                                        (role, index) => (
                                                            <option
                                                                value={
                                                                    role.name
                                                                }
                                                                key={index}
                                                            >
                                                                {role.name}
                                                            </option>
                                                        )
                                                    )}
                                                </select>
                                                {this.state.errors.roleName !==
                                                    "" &&
                                                    this.state.errors
                                                        .roleName !==
                                                        "This field is required" && (
                                                        <div className="invalid-feedback">
                                                            {
                                                                this.state
                                                                    .errors
                                                                    .roleName
                                                            }
                                                        </div>
                                                    )}
                                            </div> */}
                                                    {/* <div className="form-group mb-4">
                                                        <input
                                                            type="text"
                                                            name="fullName"
                                                            value={
                                                                this.state
                                                                    .fullName
                                                            }
                                                            onChange={(e) => {
                                                                this.handleChange(
                                                                    e
                                                                );
                                                                this.validate(
                                                                    e
                                                                );
                                                            }}
                                                            className={
                                                                this.state
                                                                    .errors
                                                                    .fullName &&
                                                                this.state
                                                                    .errors
                                                                    .fullName !==
                                                                    "This field is required"
                                                                    ? "form-control rounded-left is-invalid"
                                                                    : "form-control rounded-left"
                                                            }
                                                            placeholder="Full Name"
                                                            required
                                                        />
                                                        <div className="invalid-feedback errMsg text-left">
                                                            {this.state.errors
                                                                .fullName !==
                                                                "" &&
                                                                this.state
                                                                    .errors
                                                                    .fullName !==
                                                                    "This field is required" &&
                                                                this.state
                                                                    .errors
                                                                    .fullName}
                                                        </div>
                                                    </div> */}
                                                    <div className="form-group mb-4">
                                                        <input
                                                            type="email"
                                                            name="email"
                                                            value={
                                                                this.state.email
                                                            }
                                                            onChange={(e) => {
                                                                this.handleChange(
                                                                    e
                                                                );
                                                                this.validate(
                                                                    e
                                                                );
                                                            }}
                                                            className={
                                                                this.state
                                                                    .errors
                                                                    .email &&
                                                                    this.state
                                                                        .errors
                                                                        .email !==
                                                                    "This field is required"
                                                                    ? "form-control rounded-left is-invalid"
                                                                    : "form-control rounded-left"
                                                            }
                                                            placeholder="Email Address"
                                                            required
                                                        />
                                                        <div className="invalid-feedback errMsg text-left">
                                                            {this.state.errors
                                                                .email !== "" &&
                                                                this.state
                                                                    .errors
                                                                    .email !==
                                                                "This field is required" &&
                                                                this.state
                                                                    .errors
                                                                    .email}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            {this.state.step === 1 && (
                                                <>
                                                    <div className="form-row mb-4">
                                                        <div className="col">
                                                            <input
                                                                type="password"
                                                                name="password"
                                                                value={
                                                                    this.state
                                                                        .password
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    this.handleChange(
                                                                        e
                                                                    );
                                                                    this.validate(
                                                                        e
                                                                    );
                                                                }}
                                                                className={
                                                                    this.state
                                                                        .errors
                                                                        .password &&
                                                                        this.state
                                                                            .errors
                                                                            .password !==
                                                                        "This field is required"
                                                                        ? "form-control rounded-left is-invalid"
                                                                        : "form-control rounded-left"
                                                                }
                                                                placeholder="Password"
                                                                required
                                                            />
                                                            <div className="invalid-feedback errMsg text-left mb-3">
                                                                {this.state
                                                                    .errors
                                                                    .password !==
                                                                    "" &&
                                                                    this.state
                                                                        .errors
                                                                        .password !==
                                                                    "This field is required" &&
                                                                    this.state
                                                                        .errors
                                                                        .password}
                                                            </div>
                                                        </div>
                                                        <div className="col">
                                                            <input
                                                                type="password"
                                                                name="confirmPassword"
                                                                value={
                                                                    this.state
                                                                        .confirmPassword
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    this.handleChange(
                                                                        e
                                                                    );
                                                                    this.validate(
                                                                        e
                                                                    );
                                                                }}
                                                                className={
                                                                    this.state
                                                                        .errors
                                                                        .confirmPassword &&
                                                                        this.state
                                                                            .errors
                                                                            .confirmPassword !==
                                                                        "This field is required"
                                                                        ? "form-control rounded-left is-invalid"
                                                                        : "form-control rounded-left"
                                                                }
                                                                placeholder="Confirm Password"
                                                                required
                                                            />{" "}
                                                            <div className="invalid-feedback errMsg text-left">
                                                                {this.state
                                                                    .errors
                                                                    .confirmPassword !==
                                                                    "" &&
                                                                    this.state
                                                                        .errors
                                                                        .confirmPassword !==
                                                                    "This field is required" &&
                                                                    this.state
                                                                        .errors
                                                                        .confirmPassword}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* {this.state.step === 1 && (
                          <ul className="errMsg text-left">
                            {this.state.errors.firstName !== "" &&
                              this.state.errors.firstName !==
                                "This field is required" && (
                                <li> {this.state.errors.firstName}</li>
                              )}
                            {this.state.errors.lastName !== "" &&
                              this.state.errors.lastName !==
                                "This field is required" && (
                                <li> {this.state.errors.lastName}</li>
                              )}
                            {this.state.errors.email !== "" &&
                              this.state.errors.email !==
                                "This field is required" && (
                                <li> {this.state.errors.email}</li>
                              )}
                            {this.state.errors.password !== "" &&
                              this.state.errors.password !==
                                "This field is required" && (
                                <li>{this.state.errors.password}</li>
                              )}
                            {this.state.errors.confirmPassword !== "" &&
                              this.state.errors.confirmPassword !==
                                "This field is required" && (
                                <li> {this.state.errors.confirmPassword}</li>
                              )}
                          </ul>
                        )} */}
                                                    <div className="form-row pt-4">
                                                        <div className="col mt-4">
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input bg-blue-checkbox"
                                                                    type="checkbox"
                                                                    value=""
                                                                    id="defaultCheck1"
                                                                    required
                                                                />
                                                                <label
                                                                    className="form-check-label btntext"
                                                                    data-toggle="modal"
                                                                    data-target="#myModal"
                                                                    for="defaultCheck1"
                                                                >
                                                                    <a href="javascript:void(0);">
                                                                        {" "}
                                                                        Terms &
                                                                        Conditions
                                                                    </a>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="form-group">
                                                        <button
                                                            type="submit"
                                                            disabled={
                                                                this.state
                                                                    .isValidStep1 ===
                                                                false
                                                            }
                                                            className="btn btn-orange mb-2"
                                                        >
                                                            SIGN UP
                                                        </button>
                                                    </div>
                                                </>
                                            )}
                                            <div className="form-group"></div>
                                            <div className="form-group"></div>
                                            <div className="form-group d-md-flex text-white">
                                                <div className="w-100">
                                                    Already have an
                                                    account?&nbsp;
                                                    <Link
                                                        to="/login"
                                                        className="text-white text-bold"
                                                    >
                                                        Login
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="modal" id="myModal">
                                                <div className="modal-dialog modal-dialog-scrollable">
                                                    <div className="modal-content ">
                                                        <div className="modal-header">
                                                            <h4 className="modal-title page-title text-dark">
                                                                Terms &
                                                                Conditions
                                                            </h4>
                                                            <button
                                                                type="button"
                                                                className="close"
                                                                data-dismiss="modal"
                                                            >
                                                                &times;
                                                            </button>
                                                        </div>

                                                        <div className="modal-body text-white text-left bg-orange">
                                                            <p>
                                                                {" "}
                                                                This Health Cost
                                                                Labs Online
                                                                Subscription
                                                                Agreement
                                                                ("Agreement") is
                                                                by and between
                                                                Health Cost
                                                                Labs, LLC
                                                                (hereinafter
                                                                referred to as
                                                                "Health Cost
                                                                Labs", "us",
                                                                "our" or "we")
                                                                and you
                                                                (hereinafter
                                                                referred to as
                                                                "SUBSCRIBER",
                                                                “you” and
                                                                “your”). This
                                                                Agreement shall
                                                                be effective as
                                                                of the date of
                                                                electronic
                                                                execution
                                                                ("Effective
                                                                Date").
                                                                Electronic
                                                                execution occurs
                                                                when SUBSCRIBER
                                                                indicates
                                                                agreement to
                                                                these Terms and
                                                                Conditions by
                                                                checking the box
                                                                on the Signup
                                                                Page.
                                                            </p>
                                                            <p>
                                                                <strong>
                                                                    INTRODUCTION
                                                                </strong>
                                                            </p>
                                                            <p>
                                                                {" "}
                                                                1. Purpose. The
                                                                mission of
                                                                Health Cost Labs
                                                                is to provide
                                                                insurance and
                                                                financial
                                                                professionals
                                                                with the tools
                                                                to enable them
                                                                to be more
                                                                productive and
                                                                successful. To
                                                                achieve our
                                                                mission, we make
                                                                services
                                                                available
                                                                through our
                                                                website ("Health
                                                                Cost Labs Site")
                                                                and mobile
                                                                applications to
                                                                help you learn,
                                                                work, find
                                                                opportunities
                                                                and make
                                                                decisions.
                                                            </p>
                                                            <p>
                                                                {" "}
                                                                2. Use.
                                                                SUBSCRIBER shall
                                                                be provided
                                                                access to the
                                                                Health Cost Labs
                                                                Site and/or
                                                                Services for
                                                                only the
                                                                information they
                                                                have subscribed
                                                                to.
                                                            </p>
                                                            <p>
                                                                <strong>
                                                                    YOUR
                                                                    OBLIGATIONS
                                                                </strong>
                                                            </p>
                                                            <p>
                                                                3. Applicable
                                                                laws and this
                                                                Agreement. You
                                                                must comply with
                                                                all applicable
                                                                laws and this
                                                                Agreement, as
                                                                may be amended
                                                                from time to
                                                                time. As a
                                                                condition to
                                                                access the
                                                                Health Cost Labs
                                                                Site, you agree
                                                                to this
                                                                Agreement and to
                                                                strictly observe
                                                                the following
                                                                DOs and DON'Ts:
                                                            </p>
                                                            <p>
                                                                <strong>
                                                                    DO undertake
                                                                    the
                                                                    following:
                                                                </strong>
                                                            </p>
                                                            <p>
                                                                1. Comply with
                                                                all applicable
                                                                laws, including,
                                                                without
                                                                limitation,
                                                                privacy laws,
                                                                intellectual
                                                                property laws,
                                                                and regulatory
                                                                requirements;
                                                            </p>
                                                            <p>
                                                                2. Provide
                                                                accurate
                                                                information to
                                                                us and update it
                                                                as necessary;
                                                            </p>
                                                            <p>
                                                                3. Review and
                                                                comply with our
                                                                Privacy Policy;
                                                            </p>
                                                            <p>
                                                                4. Review and
                                                                comply with
                                                                notices sent by
                                                                Health Cost Labs
                                                                concerning the
                                                                Services; and
                                                            </p>
                                                            <p>
                                                                5. Use the
                                                                Services in a
                                                                professional
                                                                manner.
                                                            </p>
                                                            <p>
                                                                <strong>
                                                                    DON'T
                                                                    undertake
                                                                    the
                                                                    following:
                                                                </strong>
                                                            </p>
                                                            <p>
                                                                1. Duplicate,
                                                                license,
                                                                sublicense,
                                                                publish,
                                                                broadcast,
                                                                transmit,
                                                                distribute,
                                                                perform,
                                                                display, sell,
                                                                rebrand, or
                                                                otherwise
                                                                transfer
                                                                information
                                                                found on the
                                                                Health Cost Labs
                                                                Site, except as
                                                                permitted in
                                                                this Agreement
                                                                or as expressly
                                                                authorized by
                                                                Health Cost
                                                                Labs;
                                                            </p>
                                                            <p>
                                                                2. Reverse
                                                                engineer,
                                                                decompile,
                                                                disassemble,
                                                                decipher or
                                                                otherwise
                                                                attempt to
                                                                derive the
                                                                source code for
                                                                any underlying
                                                                intellectual
                                                                property used to
                                                                provide the
                                                                Services, or any
                                                                part thereof;
                                                            </p>
                                                            <p>
                                                                3. Create a user
                                                                profile for
                                                                anyone other
                                                                than a natural
                                                                person;
                                                            </p>
                                                            <p>
                                                                4. Utilize
                                                                information,
                                                                content or any
                                                                data you view on
                                                                and/or obtain
                                                                from the Health
                                                                Cost Labs Site
                                                                to provide any
                                                                service that is
                                                                competitive, in
                                                                Health Cost
                                                                Labs’ sole
                                                                discretion, with
                                                                the Health Cost
                                                                Labs Site;
                                                            </p>
                                                            <p>
                                                                5. Imply or
                                                                state, directly
                                                                or indirectly,
                                                                that you are
                                                                affiliated with
                                                                or endorsed by
                                                                Health Cost Labs
                                                                unless you have
                                                                entered into a
                                                                written
                                                                agreement with
                                                                Health Cost Labs
                                                                (this includes,
                                                                but is not
                                                                limited to
                                                                representing
                                                                yourself as an
                                                                accredited
                                                                Health Cost Labs
                                                                trainer if you
                                                                have not been
                                                                certified by
                                                                Health Cost Labs
                                                                as such);
                                                            </p>
                                                            <p>
                                                                6. Adapt, modify
                                                                or create
                                                                derivative works
                                                                based on the
                                                                Health Cost Labs
                                                                Site or the
                                                                technology
                                                                underlying the
                                                                Services;
                                                            </p>
                                                            <p>
                                                                7. Rent, lease,
                                                                loan, trade,
                                                                sell/re-sell
                                                                access to the
                                                                Health Cost Labs
                                                                Site or any
                                                                information
                                                                therein, or the
                                                                equivalent, in
                                                                whole or part;
                                                            </p>
                                                            <p>
                                                                8. Use manual or
                                                                automated
                                                                software,
                                                                devices, script
                                                                robots, or other
                                                                means or
                                                                processes to
                                                                access,
                                                                "scrape,"
                                                                "crawl" or
                                                                "spider" any
                                                                data, web pages,
                                                                or other
                                                                services
                                                                contained in the
                                                                site;
                                                            </p>
                                                            <p>
                                                                9. Engage in
                                                                "framing,"
                                                                "mirroring," or
                                                                otherwise
                                                                simulating the
                                                                appearance or
                                                                function of the
                                                                Health Cost Labs
                                                                Site;
                                                            </p>
                                                            <p>
                                                                10. Attempt to
                                                                or actually
                                                                access the
                                                                Health Cost Labs
                                                                Site by any
                                                                means other than
                                                                through the
                                                                interfaces
                                                                provided by
                                                                Health Cost
                                                                Labs;
                                                            </p>
                                                            <p>
                                                                11. Attempt to
                                                                or actually
                                                                override any
                                                                security
                                                                component
                                                                included in or
                                                                underlying the
                                                                Health Cost Labs
                                                                Site;
                                                            </p>
                                                            <p>
                                                                12. Engage in
                                                                any action that
                                                                directly or
                                                                indirectly
                                                                interferes with
                                                                the proper
                                                                working of or
                                                                places an
                                                                unreasonable
                                                                load on our
                                                                infrastructure,
                                                                including but
                                                                not limited to
                                                                unsolicited
                                                                communications
                                                                to other users
                                                                or Health Cost
                                                                Labs personnel,
                                                                attempts to gain
                                                                unauthorized
                                                                access, or
                                                                transmission or
                                                                activation of
                                                                computer
                                                                viruses;
                                                            </p>
                                                            <p>
                                                                13. Remove any
                                                                copyright,
                                                                trademark or
                                                                other
                                                                proprietary
                                                                rights notices
                                                                contained in or
                                                                on the Health
                                                                Cost Labs Site
                                                                or any exports
                                                                therefrom,
                                                                including those
                                                                of Health Cost
                                                                Labs and any of
                                                                its licensors;
                                                            </p>
                                                            <p>
                                                                14. Remove,
                                                                cover or
                                                                otherwise
                                                                obscure any form
                                                                of advertisement
                                                                included on the
                                                                Health Cost Labs
                                                                Site;
                                                            </p>
                                                            <p>
                                                                15. Interfere
                                                                with or disrupt
                                                                the Health Cost
                                                                Labs Site,
                                                                including but
                                                                not limited to
                                                                any servers or
                                                                networks
                                                                connected to the
                                                                Health Cost Labs
                                                                Site;
                                                            </p>
                                                            <p>
                                                                16. Use or
                                                                attempt to use
                                                                another's
                                                                account without
                                                                authorization
                                                                from Health Cost
                                                                Labs; and
                                                            </p>
                                                            <p>
                                                                17. Infringe or
                                                                use Health Cost
                                                                Labs’ brand,
                                                                logos and/or
                                                                trademarks,
                                                                including,
                                                                without
                                                                limitation,
                                                                using the word
                                                                "Health Cost
                                                                Labs" in any
                                                                business name,
                                                                email, or URL or
                                                                including Health
                                                                Cost Labs’
                                                                trademarks and
                                                                logos except as
                                                                expressly
                                                                permitted by
                                                                Health Cost
                                                                Labs.
                                                            </p>
                                                            <p>
                                                                1. License and
                                                                warranty for
                                                                your submissions
                                                                to Health Cost
                                                                Labs. Any
                                                                information you
                                                                submit to us is
                                                                at your own risk
                                                                of loss. By
                                                                providing
                                                                information to
                                                                us, you
                                                                represent and
                                                                warrant that you
                                                                are entitled to
                                                                submit the
                                                                information and
                                                                that the
                                                                information is
                                                                accurate, not
                                                                confidential,
                                                                and not in
                                                                violation of any
                                                                contractual
                                                                restrictions or
                                                                other third
                                                                party rights. It
                                                                is your
                                                                responsibility
                                                                to keep your
                                                                Health Cost Labs
                                                                profile
                                                                information
                                                                accurate and
                                                                updated.
                                                            </p>
                                                            <p>
                                                                2. Service
                                                                Eligibility. To
                                                                be eligible to
                                                                use the Service,
                                                                SUBSCRIBER must
                                                                meet the
                                                                following
                                                                criteria and
                                                                represent and
                                                                warrant that
                                                                they: (1) are 18
                                                                years of age or
                                                                older; (2) are
                                                                not currently
                                                                restricted from
                                                                the Services, or
                                                                not otherwise
                                                                prohibited from
                                                                having a Health
                                                                Cost Labs
                                                                account, (3) are
                                                                not a competitor
                                                                of Health Cost
                                                                Labs or are not
                                                                using the
                                                                Services for
                                                                reasons that are
                                                                in competition
                                                                with Health Cost
                                                                Labs; and (4)
                                                                will only
                                                                maintain one
                                                                Health Cost Labs
                                                                account at any
                                                                given time.
                                                            </p>
                                                            <p>
                                                                Each party
                                                                hereby
                                                                represents and
                                                                warrants to the
                                                                other that: (1)
                                                                entering into
                                                                this Agreement
                                                                will not violate
                                                                any other
                                                                agreement to
                                                                which it is a
                                                                party; (2) will
                                                                not violate any
                                                                rights of the
                                                                other party,
                                                                including
                                                                intellectual
                                                                property rights
                                                                such as patent,
                                                                copyright or
                                                                trademark
                                                                rights; and (3)
                                                                agrees to
                                                                provide at its
                                                                cost all
                                                                equipment,
                                                                software, and
                                                                internet access
                                                                necessary with
                                                                respect to such
                                                                party's use of
                                                                the Services.
                                                            </p>
                                                            <p>
                                                                1. Sign-In
                                                                Credentials.
                                                                SUBSCRIBER
                                                                agrees that they
                                                                shall: (1) keep
                                                                their passwords
                                                                secure and
                                                                confidential;
                                                                (2) not permit
                                                                others to use
                                                                their account;
                                                                (3) refrain from
                                                                using other
                                                                Users' accounts;
                                                                and (4) refrain
                                                                from selling,
                                                                trading, or
                                                                otherwise
                                                                transferring
                                                                their Health
                                                                Cost Labs
                                                                account to
                                                                another party.
                                                                SUBSCRIBER shall
                                                                refrain from
                                                                charging anyone
                                                                for access to
                                                                any portion of
                                                                the Health Cost
                                                                Labs Site, or
                                                                any information
                                                                therein.
                                                                Further,
                                                                SUBSCRIBER is
                                                                responsible for
                                                                anything that
                                                                happens through
                                                                its account
                                                                until it closes
                                                                the account or
                                                                proves that
                                                                Health Cost
                                                                Labs’ account
                                                                security was
                                                                compromised due
                                                                to no fault of
                                                                SUBSCRIBER. To
                                                                close your
                                                                account, please
                                                                email Customer
                                                                Service at
                                                                customerservice@healthcostlabs.com.
                                                            </p>
                                                            <p>
                                                                2. Payment.
                                                                SUBSCRIBER
                                                                agrees to pay
                                                                Health Cost
                                                                Labs’ monthly
                                                                Subscription Fee
                                                                for an annual
                                                                term as agreed
                                                                to when
                                                                SUBSCRIBER
                                                                completed the
                                                                registration
                                                                process and
                                                                accepted these
                                                                terms and
                                                                conditions by
                                                                providing their
                                                                Credit Card
                                                                information.
                                                                Monthly receipts
                                                                are provided in
                                                                the Billing
                                                                Portal section
                                                                or can be
                                                                requested at
                                                                customerservice@healthcostlabs.com.
                                                            </p>
                                                            <p>
                                                                Your annual
                                                                subscription
                                                                will be renewed
                                                                automatically
                                                                each year unless
                                                                you terminate
                                                                your Health Cost
                                                                Labs
                                                                subscription
                                                                with written
                                                                notice to Health
                                                                Cost Labs sixty
                                                                (60) or more
                                                                days prior to
                                                                the renewal
                                                                date. All fees
                                                                and charges are
                                                                nonrefundable
                                                                and there are no
                                                                full, partial,
                                                                or prorated
                                                                refunds or
                                                                credits for
                                                                partially used
                                                                periods unless
                                                                Health Cost Labs
                                                                terminates your
                                                                subscription as
                                                                set forth in
                                                                Section 7.A. If
                                                                you choose to
                                                                purchase
                                                                additional
                                                                products or
                                                                services from
                                                                Health Cost Labs
                                                                as part of your
                                                                subscription,
                                                                those additional
                                                                products or
                                                                services will
                                                                become part of
                                                                your annual term
                                                                and shall be
                                                                renewed
                                                                automatically
                                                                with your
                                                                subscription
                                                                unless
                                                                terminated as
                                                                above.
                                                            </p>
                                                            <p>
                                                                1. Notify us of
                                                                acts contrary to
                                                                the Agreement.
                                                                If you believe
                                                                that you are
                                                                entitled or
                                                                obligated to act
                                                                contrary to this
                                                                Agreement under
                                                                any mandatory
                                                                law, you agree
                                                                to provide us
                                                                with detailed
                                                                and
                                                                substantiated
                                                                explanation of
                                                                your reasons in
                                                                writing at least
                                                                thirty (30) days
                                                                before you act
                                                                contrary to this
                                                                Agreement, to
                                                                allow us to
                                                                assess whether
                                                                we may, at our
                                                                sole discretion,
                                                                provide an
                                                                alternative
                                                                remedy for the
                                                                situation,
                                                                though we are
                                                                under no
                                                                obligation to do
                                                                so.
                                                            </p>
                                                            <p>
                                                                2. Notifications
                                                                and Service
                                                                Messages. For
                                                                purposes of
                                                                service messages
                                                                and notices
                                                                about the
                                                                Services to you,
                                                                notice shall
                                                                consist of an
                                                                email from
                                                                Health Cost Labs
                                                                to the email
                                                                address
                                                                associated with
                                                                your account,
                                                                even if we have
                                                                other contact
                                                                information. You
                                                                also agree that
                                                                Health Cost Labs
                                                                may communicate
                                                                with you through
                                                                your Health Cost
                                                                Labs account or
                                                                through other
                                                                means including
                                                                email, mobile
                                                                number,
                                                                telephone, or
                                                                delivery
                                                                services
                                                                including the US
                                                                Postal Service
                                                                about your
                                                                Health Cost Labs
                                                                account or
                                                                services
                                                                associated with
                                                                Health Cost
                                                                Labs. You
                                                                acknowledge and
                                                                agree that we
                                                                shall have no
                                                                liability
                                                                associated with
                                                                or arising from
                                                                your failure to
                                                                maintain
                                                                accurate contact
                                                                or other
                                                                information,
                                                                including, but
                                                                not limited to,
                                                                your failure to
                                                                receive critical
                                                                information
                                                                about the
                                                                Service.
                                                            </p>
                                                            <p>
                                                                3. Mobile
                                                                Services. Health
                                                                Cost Labs may
                                                                offer the
                                                                Services through
                                                                mobile
                                                                applications. If
                                                                SUBSCRIBER uses
                                                                the Services
                                                                through a mobile
                                                                device, they
                                                                agree that
                                                                information
                                                                about the use of
                                                                the Services
                                                                through the
                                                                mobile device
                                                                may be
                                                                communicated to
                                                                us, including
                                                                but not limited
                                                                to the
                                                                SUBSCRIBER's:
                                                                mobile Partner,
                                                                mobile device,
                                                                or physical
                                                                location. In
                                                                addition, use of
                                                                the Services
                                                                through a mobile
                                                                device may cause
                                                                data to be
                                                                displayed on and
                                                                through your
                                                                mobile device.
                                                                In the event
                                                                SUBSCRIBER
                                                                changes or
                                                                deactivates
                                                                their mobile
                                                                account, they
                                                                must promptly
                                                                update their
                                                                Health Cost Labs
                                                                account
                                                                information to
                                                                ensure that
                                                                messages are not
                                                                sent to the
                                                                person that
                                                                acquires the old
                                                                number and
                                                                failure to do so
                                                                is SUBSCRIBER's
                                                                responsibility.
                                                                SUBSCRIBER is
                                                                responsible for
                                                                all charges and
                                                                necessary
                                                                permissions
                                                                related to
                                                                accessing the
                                                                Health Cost Labs
                                                                Site through a
                                                                mobile access
                                                                provider.
                                                            </p>
                                                            <p>
                                                                4. Third Party
                                                                Pass-Through
                                                                Terms of
                                                                Service. Health
                                                                Cost Labs may
                                                                offer services
                                                                from third party
                                                                companies. In
                                                                some cases,
                                                                those third
                                                                parties require
                                                                Health Cost Labs
                                                                SUBSCRIBERS to
                                                                comply with
                                                                their terms and
                                                                conditions.
                                                                Health Cost Labs
                                                                offers services
                                                                from Google
                                                                Maps/Google
                                                                Earth. Health
                                                                Cost Labs
                                                                SUBSCRIBERS are
                                                                bound by Google
                                                                Maps/Google
                                                                Earth Additional
                                                                Terms of Service
                                                                (including the
                                                                Google Privacy
                                                                Policy).
                                                            </p>
                                                            <p>
                                                                5. Privacy.
                                                                SUBSCRIBERS
                                                                should carefully
                                                                read our full
                                                                Privacy Policy
                                                                before deciding
                                                                to become a
                                                                SUBSCRIBER as it
                                                                governs our
                                                                treatment of any
                                                                information,
                                                                including
                                                                personally
                                                                identifiable
                                                                information you
                                                                submit to us.
                                                                Please note that
                                                                certain
                                                                information,
                                                                statements, data
                                                                and content
                                                                which
                                                                SUBSCRIBERS may
                                                                submit to Health
                                                                Cost Labs may
                                                                reveal gender,
                                                                ethnic origin,
                                                                nationality,
                                                                age, religion
                                                                and/or sexual
                                                                orientation,
                                                                and/or other
                                                                personal
                                                                information
                                                                about the
                                                                SUBSCRIBER. You
                                                                acknowledge that
                                                                submission of
                                                                any information,
                                                                statements,
                                                                data, and
                                                                content to us is
                                                                voluntary.
                                                            </p>
                                                            <p>
                                                                6.
                                                                Indemnification.
                                                                You expressly
                                                                agree to
                                                                indemnify us and
                                                                hold us harmless
                                                                for all damages,
                                                                losses and costs
                                                                (including, but
                                                                not limited to,
                                                                reasonable
                                                                attorneys' fees
                                                                and costs)
                                                                related to all
                                                                third party
                                                                claims, charges,
                                                                and
                                                                investigations,
                                                                caused by your
                                                                acts including,
                                                                without
                                                                limitation, (1)
                                                                your failure to
                                                                comply with this
                                                                Agreement,
                                                                including,
                                                                without
                                                                limitation, your
                                                                submission of
                                                                content that
                                                                violates third
                                                                party rights or
                                                                applicable laws,
                                                                (2) any content
                                                                you submit to
                                                                the Services,
                                                                (3) any activity
                                                                in which you
                                                                engage on, by or
                                                                through the
                                                                Health Cost Labs
                                                                Site, (4) your
                                                                use of any data
                                                                provided by
                                                                Health Cost
                                                                Labs, and (5)
                                                                any claim by any
                                                                governmental
                                                                entity for
                                                                unpaid taxes or
                                                                fees in any way
                                                                related to your
                                                                subscription
                                                                with Health Cost
                                                                Labs.
                                                            </p>
                                                            <p>YOUR RIGHTS</p>
                                                            <p>
                                                                On the condition
                                                                that you comply
                                                                with all your
                                                                obligations
                                                                under this
                                                                Agreement, we
                                                                grant you a
                                                                limited,
                                                                revocable,
                                                                nonexclusive,
                                                                non-assignable,
                                                                non-sublicensable
                                                                right to access
                                                                information,
                                                                through a
                                                                generally
                                                                available web
                                                                browser or
                                                                mobile device or
                                                                application,
                                                                view
                                                                information, and
                                                                use the Services
                                                                that we provide
                                                                on the Health
                                                                Cost Labs Site
                                                                and in
                                                                accordance with
                                                                this Agreement.
                                                                Any other use of
                                                                the Health Cost
                                                                Labs Site
                                                                contrary to our
                                                                mission and
                                                                purpose is
                                                                strictly
                                                                prohibited and a
                                                                violation of
                                                                this Agreement.
                                                                We reserve all
                                                                rights not
                                                                expressly
                                                                granted in this
                                                                Agreement,
                                                                including,
                                                                without
                                                                limitation,
                                                                title,
                                                                ownership,
                                                                intellectual
                                                                property rights,
                                                                and all other
                                                                rights and
                                                                interest in the
                                                                Health Cost Labs
                                                                Site and all
                                                                related items.
                                                            </p>
                                                            <p>
                                                                <strong>
                                                                    OUR RIGHTS
                                                                    AND
                                                                    OBLIGATIONS{" "}
                                                                </strong>{" "}
                                                            </p>
                                                            <p></p>
                                                            <p>
                                                                1. Services
                                                                Availability.
                                                                For as long as
                                                                Health Cost Labs
                                                                continues to
                                                                offer the
                                                                Services, Health
                                                                Cost Labs shall
                                                                provide and seek
                                                                to update,
                                                                improve and
                                                                expand the
                                                                Services. As a
                                                                result, we allow
                                                                you to access
                                                                the Health Cost
                                                                Labs Site as it
                                                                may exist and be
                                                                available on any
                                                                given day and
                                                                have no other
                                                                obligations,
                                                                except as
                                                                expressly stated
                                                                in this
                                                                Agreement. We
                                                                may modify,
                                                                replace, refuse
                                                                access to,
                                                                suspend or
                                                                discontinue the
                                                                Health Cost Labs
                                                                Site, partially
                                                                or entirely. In
                                                                the event access
                                                                is refused or
                                                                the Health Cost
                                                                Labs Site is
                                                                suspended or
                                                                discontinued,
                                                                Health Cost Labs
                                                                shall provide
                                                                SUBSCRIBER with
                                                                a prorated
                                                                refund of its
                                                                aggregated
                                                                Annual
                                                                Subscription
                                                                Fee. Health Cost
                                                                Labs may change
                                                                and modify
                                                                prices for all
                                                                or part of the
                                                                Services in our
                                                                sole discretion
                                                                once per year
                                                                and shall notify
                                                                SUBSCRIBER at
                                                                least sixty (60)
                                                                days prior to
                                                                the end of the
                                                                then current
                                                                subscription
                                                                term to give
                                                                SUBSCRIBER time
                                                                to evaluate the
                                                                price change and
                                                                decide if a
                                                                renewal is
                                                                warranted.
                                                                Health Cost Labs
                                                                further reserves
                                                                the right to
                                                                withhold, remove
                                                                and or discard
                                                                any content
                                                                available as
                                                                part of your
                                                                account, with or
                                                                without notice
                                                                if deemed by
                                                                Health Cost Labs
                                                                to be contrary
                                                                to this
                                                                Agreement. For
                                                                avoidance of
                                                                doubt, Health
                                                                Cost Labs has no
                                                                obligation to
                                                                store, maintain
                                                                or provide you a
                                                                copy of any
                                                                content that you
                                                                or other
                                                                SUBSCRIBERS
                                                                provide when
                                                                using the
                                                                Services. Any
                                                                questions or
                                                                issues
                                                                surrounding the
                                                                functionality of
                                                                the Health Cost
                                                                Labs Site or
                                                                application
                                                                should be
                                                                directed to
                                                                customerservice@healthcostlabs.com.
                                                            </p>
                                                            <p>
                                                                1. Third
                                                                Parties. Health
                                                                Cost Labs may
                                                                include links to
                                                                third party web
                                                                sites ("Third
                                                                Party Sites") on
                                                                www.Health Cost
                                                                Labs.biz and
                                                                elsewhere. You
                                                                are responsible
                                                                for evaluating
                                                                whether you want
                                                                to access or use
                                                                a Third-Party
                                                                Site. You should
                                                                review any
                                                                applicable terms
                                                                and/or privacy
                                                                policy of a
                                                                Third Party Site
                                                                before using it
                                                                or sharing any
                                                                information with
                                                                it, because you
                                                                may give the
                                                                operator
                                                                permission to
                                                                use your
                                                                information in
                                                                ways we would
                                                                not.
                                                            </p>
                                                            <p>
                                                                Health Cost Labs
                                                                is not
                                                                responsible for
                                                                and does not
                                                                endorse any
                                                                features,
                                                                content,
                                                                advertising,
                                                                products or
                                                                other materials
                                                                on or available
                                                                from Third Party
                                                                Sites.
                                                                Accordingly, if
                                                                you decide to
                                                                access Third
                                                                Party Sites, you
                                                                do so at your
                                                                own risk and
                                                                agree that that
                                                                this Agreement
                                                                does not apply
                                                                to your use of
                                                                any Third-Party
                                                                Site.
                                                            </p>
                                                            <p>
                                                                Please note: If
                                                                you allow a
                                                                Third-Party Site
                                                                to authenticate
                                                                to or connect
                                                                with your Health
                                                                Cost Labs
                                                                account, that
                                                                application or
                                                                website can
                                                                access
                                                                information on
                                                                Health Cost Labs
                                                                related to you
                                                                and your
                                                                connections.
                                                            </p>
                                                            <p>
                                                                1. Disclosure of
                                                                SUBSCRIBER
                                                                Information. You
                                                                acknowledge,
                                                                consent and
                                                                agree that we
                                                                may access,
                                                                preserve, and
                                                                disclose your
                                                                registration and
                                                                any other
                                                                information you
                                                                provide if
                                                                required to do
                                                                so by law or in
                                                                a good faith
                                                                belief that such
                                                                access
                                                                preservation or
                                                                disclosure is
                                                                reasonably
                                                                necessary in our
                                                                opinion to: (1)
                                                                comply with
                                                                legal process,
                                                                including but
                                                                not limited to
                                                                civil and
                                                                criminal
                                                                subpoenas, court
                                                                orders or other
                                                                compulsory
                                                                disclosures; (2)
                                                                enforce this
                                                                Agreement; (3)
                                                                respond to
                                                                claims of a
                                                                violation of the
                                                                rights of third
                                                                parties, whether
                                                                or not the third
                                                                party is a user,
                                                                individual, or
                                                                government
                                                                Partner; (4)
                                                                respond to
                                                                customer service
                                                                inquiries; or
                                                                (5) protect the
                                                                rights,
                                                                property, or
                                                                personal safety
                                                                of Health Cost
                                                                Labs, our users
                                                                or the public.
                                                            </p>
                                                            <p>
                                                                2. Equal
                                                                Opportunity. The
                                                                parties hereto
                                                                and their
                                                                respective
                                                                subcontractors
                                                                shall abide by
                                                                the requirements
                                                                of 41 CFR
                                                                60-300.5(a) and
                                                                60-741.5(a).
                                                                These
                                                                regulations
                                                                prohibit
                                                                discrimination
                                                                against
                                                                qualified
                                                                individuals on
                                                                the basis of
                                                                protected
                                                                veteran status
                                                                or disability
                                                                and require
                                                                affirmative
                                                                action by
                                                                covered prime
                                                                contractors and
                                                                subcontractors
                                                                to employ and
                                                                advance in
                                                                employment
                                                                qualified
                                                                protected
                                                                veterans and
                                                                individuals with
                                                                disabilities.
                                                            </p>
                                                            <p>DISCLAIMER</p>
                                                            <p>
                                                                SUBSCRIBER
                                                                ACKNOWLEDGES
                                                                THAT THE DATA
                                                                CONTAINED ON THE
                                                                HEALTH COST LABS
                                                                SITE IS DATA
                                                                OBTAINED FROM
                                                                THIRD PARTIES
                                                                AND WILL CONTAIN
                                                                A DEGREE OF
                                                                ERROR AND THAT
                                                                SUBSCRIBER IS
                                                                RESPONSIBLE FOR
                                                                DETERMINING THAT
                                                                THE DATA
                                                                CONTAINED ON THE
                                                                HEALTH COST LABS
                                                                SITE IS
                                                                SUFFICIENTLY
                                                                ACCURATE FOR
                                                                SUBSCRIBER'S
                                                                PURPOSES. THE
                                                                DATA CONTAINED
                                                                ON THE HEALTH
                                                                COST LABS SITE
                                                                AND PROVIDED
                                                                HEREUNDER IS
                                                                PROVIDED ON AN
                                                                AS-IS,
                                                                AS-AVAILABLE
                                                                BASIS AND HEALTH
                                                                COST LABS DOES
                                                                NOT MAKE AND
                                                                HEREBY DISCLAIMS
                                                                ANY WARRANTY,
                                                                EXPRESS OR
                                                                IMPLIED, WITH
                                                                RESPECT TO THE
                                                                LICENSED DATA,
                                                                INCLUDING, BUT
                                                                NOT LIMITED TO,
                                                                THE ACCURACY,
                                                                COMPLETENESS,
                                                                TIMELINESS,
                                                                MERCHANTABILITY
                                                                OR FITNESS FOR A
                                                                PARTICULAR
                                                                PURPOSE OF THE
                                                                DATA.
                                                            </p>
                                                            <p>
                                                                DO NOT RELY ON
                                                                THE HEALTH COST
                                                                LABS SITE, ANY
                                                                INFORMATION
                                                                THEREIN, OR ITS
                                                                CONTINUATION. WE
                                                                PROVIDE THE
                                                                PLATFORM FOR
                                                                HEALTH COST LABS
                                                                AND ALL
                                                                INFORMATION AND
                                                                SERVICES ON AN
                                                                "AS IS" AND "AS
                                                                AVAILABLE"
                                                                BASIS. WE DO NOT
                                                                PROVIDE ANY
                                                                EXPRESS
                                                                WARRANTIES OR
                                                                REPRESENTATIONS.
                                                            </p>
                                                            <p>
                                                                TO THE FULLEST
                                                                EXTENT
                                                                PERMISSIBLE
                                                                UNDER APPLICABLE
                                                                LAW, WE DISCLAIM
                                                                ANY AND ALL
                                                                IMPLIED
                                                                WARRANTIES AND
                                                                REPRESENTATIONS,
                                                                INCLUDING,
                                                                WITHOUT
                                                                LIMITATION, ANY
                                                                WARRANTIES OF
                                                                MERCHANTABILITY,
                                                                FITNESS FOR A
                                                                PARTICULAR
                                                                PURPOSE, TITLE,
                                                                AND
                                                                NONINFRINGEMENT.
                                                            </p>
                                                            <p>
                                                                HEALTH COST LABS
                                                                NEITHER WARRANTS
                                                                NOR REPRESENTS
                                                                THAT YOUR USE OF
                                                                THE SERVICE WILL
                                                                NOT INFRINGE THE
                                                                RIGHTS OF THIRD
                                                                PARTIES. ANY
                                                                MATERIAL,
                                                                SERVICE, OR
                                                                TECHNOLOGY
                                                                DESCRIBED OR
                                                                USED ON THE
                                                                HEALTH COST LABS
                                                                SITE MAY BE
                                                                SUBJECT TO
                                                                INTELLECTUAL
                                                                PROPERTY RIGHTS
                                                                OWNED BY THIRD
                                                                PARTIES WHO HAVE
                                                                LICENSED SUCH
                                                                MATERIAL,
                                                                SERVICE OR
                                                                TECHNOLOGY TO
                                                                US.
                                                            </p>
                                                            <p>
                                                                HEALTH COST LABS
                                                                DOES NOT
                                                                GUARANTEE THAT
                                                                THE SERVICES IT
                                                                PROVIDES WILL
                                                                FUNCTION WITHOUT
                                                                INTERRUPTION OR
                                                                ERRORS IN
                                                                FUNCTIONING. IN
                                                                PARTICULAR, THE
                                                                OPERATION OF THE
                                                                SERVICES MAY BE
                                                                INTERRUPTED DUE
                                                                TO MAINTENANCE,
                                                                UPDATES, OR
                                                                SYSTEM OR
                                                                NETWORK
                                                                FAILURES. HEALTH
                                                                COST LABS
                                                                DISCLAIMS ALL
                                                                LIABILITY FOR
                                                                DAMAGES CAUSED
                                                                BY ANY SUCH
                                                                INTERRUPTION OR
                                                                ERRORS IN
                                                                FUNCTIONING.
                                                                FURTHERMORE,
                                                                HEALTH COST LABS
                                                                DISCLAIMS ALL
                                                                LIABILITY FOR
                                                                ANY
                                                                MALFUNCTIONING,
                                                                IMPOSSIBILITY OF
                                                                ACCESS, OR POOR
                                                                USE CONDITIONS
                                                                OF THE HEALTH
                                                                COST LABS SITE
                                                                DUE TO
                                                                DISTURBANCES
                                                                RELATED TO
                                                                INTERNET SERVICE
                                                                PROVIDERS, TO
                                                                THE SATURATION
                                                                OF THE INTERNET
                                                                NETWORK,
                                                                SUBSCRIBER'S USE
                                                                OF OUTDATED OR
                                                                INCOMPATIBLE WEB
                                                                BROWSERS, AND
                                                                FOR ANY OTHER
                                                                REASON.
                                                            </p>
                                                            <p>
                                                                <strong>
                                                                    LIMITATION
                                                                    OF LIABILITY
                                                                </strong>
                                                            </p>
                                                            <p>
                                                                Neither Health
                                                                Cost Labs nor
                                                                any of our
                                                                subsidiaries,
                                                                affiliated
                                                                companies,
                                                                employees,
                                                                shareholders,
                                                                members, or
                                                                directors
                                                                (hereinafter
                                                                "Health Cost
                                                                Labs
                                                                Affiliates")
                                                                shall be liable
                                                                for (a) any
                                                                damages in
                                                                excess of three
                                                                (3) times the
                                                                annual
                                                                subscription fee
                                                                you paid or (b)
                                                                any special,
                                                                incidental,
                                                                indirect,
                                                                punitive or
                                                                consequential
                                                                damages or loss
                                                                of use, profit,
                                                                revenue or data
                                                                to you or any
                                                                third person
                                                                arising from
                                                                your use of the
                                                                Service, any
                                                                platform
                                                                applications or
                                                                any of the
                                                                content or other
                                                                materials on,
                                                                accessed through
                                                                or downloaded
                                                                from the Health
                                                                Cost Labs Site.
                                                                This limitation
                                                                of liability
                                                                shall:
                                                            </p>
                                                            <p>
                                                                1. Apply
                                                                regardless of
                                                                whether (1) you
                                                                base your claim
                                                                on contract,
                                                                tort, statute or
                                                                any other legal
                                                                theory, (2) we
                                                                knew or should
                                                                have known about
                                                                the possibility
                                                                of such damages,
                                                                or (3) the
                                                                limited remedies
                                                                provided in this
                                                                section fail of
                                                                their essential
                                                                purpose; and
                                                            </p>
                                                            <p>
                                                                2. Not apply to
                                                                any damage that
                                                                Health Cost Labs
                                                                may cause you
                                                                intentionally or
                                                                knowingly in
                                                                violation of
                                                                this Agreement
                                                                or applicable
                                                                law, or as
                                                                otherwise
                                                                mandated by
                                                                applicable law
                                                                that cannot be
                                                                disclaimed from
                                                                in this
                                                                Agreement.
                                                            </p>
                                                            <p>
                                                                <strong>
                                                                    TERMINATION
                                                                </strong>
                                                            </p>
                                                            <p>
                                                                1. Mutual rights
                                                                of termination.
                                                                You may
                                                                terminate this
                                                                Agreement, for
                                                                any or no
                                                                reason, at any
                                                                time, with
                                                                notice to Health
                                                                Cost Labs. This
                                                                notice will be
                                                                effective upon
                                                                Health Cost Labs
                                                                processing your
                                                                notice, said
                                                                processing shall
                                                                not take longer
                                                                than five (5)
                                                                business days.
                                                                If you terminate
                                                                this Agreement,
                                                                you remain
                                                                responsible for
                                                                payment for the
                                                                remainder of
                                                                your aggregated
                                                                Annual
                                                                Subscription Fee
                                                                as set forth in
                                                                Section 2.E.
                                                            </p>
                                                            <p>
                                                                Health Cost Labs
                                                                may terminate
                                                                this Agreement
                                                                for any reason
                                                                or no reason, at
                                                                any time, with
                                                                or without
                                                                notice. This
                                                                cancellation
                                                                shall be
                                                                effective
                                                                immediately or
                                                                as may be
                                                                specified in the
                                                                notice.
                                                                Termination of
                                                                your Health Cost
                                                                Labs account
                                                                includes
                                                                disabling your
                                                                access to Health
                                                                Cost Labs and
                                                                may also bar you
                                                                from any future
                                                                use of Health
                                                                Cost Labs. If
                                                                Health Cost Labs
                                                                terminates this
                                                                Agreement for
                                                                reasons other
                                                                than those set
                                                                forth in Section
                                                                7.B., Health
                                                                Cost Labs will
                                                                refund a
                                                                prorated amount
                                                                of the
                                                                aggregated
                                                                Annual
                                                                Subscription Fee
                                                                paid by
                                                                SUBSCRIBER.
                                                            </p>
                                                            <p>
                                                                2. Misuse of the
                                                                Services. Health
                                                                Cost Labs may
                                                                restrict,
                                                                suspend or
                                                                terminate the
                                                                account of any
                                                                SUBSCRIBER who
                                                                abuses or
                                                                misuses the
                                                                Services. Misuse
                                                                of the Services
                                                                includes, but is
                                                                not limited to,
                                                                the following:
                                                                creating
                                                                multiple or
                                                                false profiles;
                                                                sharing the
                                                                username and
                                                                password with
                                                                others;
                                                                infringing any
                                                                intellectual
                                                                property rights
                                                                of Health Cost
                                                                Labs or any
                                                                other person or
                                                                entity, or any
                                                                other behavior
                                                                that Health Cost
                                                                Labs, in its
                                                                sole discretion,
                                                                deems contrary
                                                                to its purpose.
                                                                In the event
                                                                Health Cost Labs
                                                                decides to
                                                                terminate
                                                                SUBSCRIBER's
                                                                account, Health
                                                                Cost Labs shall
                                                                notify
                                                                SUBSCRIBER of
                                                                the misuse of
                                                                the Services and
                                                                allow SUBSCRIBER
                                                                ninety (90) days
                                                                to rectify the
                                                                situation. Upon
                                                                termination of
                                                                the SUBSCRIBER
                                                                account for
                                                                misuse of Health
                                                                Cost Labs’
                                                                Services, you
                                                                remain
                                                                responsible for
                                                                payment for the
                                                                remainder of
                                                                your aggregated
                                                                Annual
                                                                Subscription Fee
                                                                as set forth in
                                                                Section 2.E.
                                                            </p>
                                                            <p>
                                                                3. Effect of
                                                                Termination.
                                                                Upon the
                                                                termination of
                                                                your Health Cost
                                                                Labs account,
                                                                you lose access
                                                                to the Services.
                                                                In addition,
                                                                Health Cost Labs
                                                                may block access
                                                                to the Services
                                                                from an IP
                                                                address or range
                                                                of IP addresses
                                                                associated with
                                                                those of
                                                                terminated
                                                                SUBSCRIBERS. The
                                                                terms of this
                                                                Agreement shall
                                                                survive any
                                                                termination,
                                                                except Sections
                                                                3 ("Your
                                                                Rights") and 4
                                                                ("Our Rights and
                                                                Obligations")
                                                                hereof.
                                                            </p>
                                                            <p>
                                                                <strong>
                                                                    GENERAL
                                                                    TERMS
                                                                </strong>
                                                            </p>
                                                            <p>
                                                                1. Severability.
                                                                If any provision
                                                                of this
                                                                Agreement is
                                                                found by a court
                                                                of competent
                                                                jurisdiction or
                                                                arbitrator to be
                                                                illegal, void,
                                                                or
                                                                unenforceable,
                                                                the
                                                                unenforceable
                                                                provision will
                                                                be modified so
                                                                as to render it
                                                                enforceable and
                                                                effective to the
                                                                maximum extent
                                                                possible in
                                                                order to effect
                                                                the intention of
                                                                the provision;
                                                                and if a court
                                                                or arbitrator
                                                                finds the
                                                                modified
                                                                provision
                                                                invalid,
                                                                illegal, void or
                                                                unenforceable,
                                                                the validity,
                                                                legality and
                                                                enforceability
                                                                of the remaining
                                                                provisions of
                                                                this Agreement
                                                                will not be
                                                                affected in any
                                                                way.
                                                            </p>
                                                            <p>
                                                                2. Language.
                                                                Where Health
                                                                Cost Labs has
                                                                provided you
                                                                with a
                                                                translation of
                                                                the English
                                                                language version
                                                                of this
                                                                Agreement, the
                                                                Privacy Policy,
                                                                and/or any other
                                                                documentation,
                                                                you agree that
                                                                the translation
                                                                is provided for
                                                                your convenience
                                                                only and that
                                                                the English
                                                                language
                                                                versions of this
                                                                Agreement, the
                                                                Privacy Policy,
                                                                and any other
                                                                documentation,
                                                                will govern your
                                                                relationship
                                                                with Health Cost
                                                                Labs.
                                                            </p>
                                                            <p>
                                                                3. Notices and
                                                                Service of
                                                                Process. In
                                                                addition to
                                                                Section 2.G.
                                                                ("Notifications
                                                                and Service
                                                                Messages"), we
                                                                may notify you
                                                                via postings on
                                                                www.Health Cost
                                                                Labs.biz. You
                                                                may contact us
                                                                via email at:
                                                                customerservice@healthcostlabs.com
                                                            </p>
                                                            <p>
                                                                or via mail or
                                                                courier at:
                                                            </p>
                                                            <p>
                                                                Health Cost
                                                                Labs, LLC.
                                                            </p>
                                                            <p>
                                                                ATTN: Legal
                                                                Department
                                                            </p>
                                                            <p>
                                                                260 Schuylkill
                                                                Road #1005
                                                            </p>
                                                            <p>
                                                                Phoenixville, PA
                                                                19460
                                                            </p>
                                                            <p>267-480-9267</p>
                                                            <p>
                                                                Additionally,
                                                                Health Cost Labs
                                                                accepts service
                                                                of process at
                                                                this address.
                                                                Any notices that
                                                                you provide
                                                                without
                                                                compliance with
                                                                this section
                                                                shall have no
                                                                legal effect.
                                                            </p>
                                                            <p>
                                                                Notices related
                                                                to or required
                                                                to be provided
                                                                to SUBSCRIBER
                                                                under this
                                                                Agreement shall
                                                                be sent to
                                                                address provided
                                                                by SUBSCRIBER on
                                                                website.
                                                            </p>
                                                            <p></p>
                                                            <p>
                                                                {" "}
                                                                1. Amendments to
                                                                this Agreement.
                                                                We reserve the
                                                                right to modify,
                                                                supplement or
                                                                replace the
                                                                terms of the
                                                                Agreement at any
                                                                time.
                                                            </p>
                                                            <p>
                                                                2. No informal
                                                                waivers,
                                                                agreements or
                                                                representations.
                                                                Our failure to
                                                                act with respect
                                                                to a breach of
                                                                this Agreement
                                                                by you or others
                                                                does not waive
                                                                our right to act
                                                                with respect to
                                                                that breach or
                                                                subsequent
                                                                similar or other
                                                                breaches, except
                                                                as expressly and
                                                                specifically
                                                                contemplated by
                                                                this Agreement.
                                                                No
                                                                representations,
                                                                statements,
                                                                consents,
                                                                waivers or other
                                                                acts or
                                                                omissions by any
                                                                Health Cost Labs
                                                                Affiliate shall
                                                                be deemed
                                                                legally binding
                                                                on any Health
                                                                Cost Labs
                                                                Affiliate,
                                                                unless
                                                                documented in a
                                                                physical writing
                                                                hand signed by a
                                                                duly appointed
                                                                officer of
                                                                Health Cost
                                                                Labs.
                                                            </p>
                                                            <p>
                                                                3. Governing Law
                                                                and
                                                                Jurisdiction.
                                                                This Agreement
                                                                and performance
                                                                hereunder shall
                                                                be governed by
                                                                the laws of the
                                                                State of
                                                                Pennsylvania
                                                                without regard
                                                                to conflicts of
                                                                laws principles
                                                                of such
                                                                jurisdiction.
                                                                SUBSCRIBER and
                                                                Health Cost Labs
                                                                hereby agree
                                                                that the sole
                                                                and exclusive
                                                                jurisdiction and
                                                                venue for any
                                                                litigation
                                                                arising from or
                                                                relating to this
                                                                Agreement shall
                                                                be an
                                                                appropriate
                                                                federal or state
                                                                court located in
                                                                the State of
                                                                Pennsylvania.
                                                            </p>
                                                            <p>
                                                                4. No Injunctive
                                                                Relief. In no
                                                                event shall you
                                                                seek or be
                                                                entitled to
                                                                rescission,
                                                                injunctive or
                                                                other equitable
                                                                relief, or to
                                                                enjoin or
                                                                restrain the
                                                                operation of the
                                                                Service,
                                                                exploitation of
                                                                any advertising
                                                                or other
                                                                materials issued
                                                                in connection
                                                                therewith, or
                                                                exploitation of
                                                                the Services or
                                                                any content or
                                                                other material
                                                                used or
                                                                displayed
                                                                through the
                                                                Services, and
                                                                you expressly
                                                                waive your
                                                                rights related
                                                                to same.
                                                            </p>
                                                            <p>
                                                                5. Assignment
                                                                and Delegation.
                                                                You may not
                                                                assign or
                                                                delegate any
                                                                rights or
                                                                obligations
                                                                under this
                                                                Agreement. Any
                                                                purported
                                                                assignment and
                                                                delegation shall
                                                                be ineffective,
                                                                invalid, and
                                                                void. We may
                                                                freely assign or
                                                                delegate all
                                                                rights and
                                                                obligations
                                                                under this
                                                                Agreement, fully
                                                                or partially,
                                                                with ninety (90)
                                                                days prior
                                                                written notice
                                                                to you. We may
                                                                also substitute
                                                                Network
                                                                Optimization,
                                                                LLC, d/b/a
                                                                Health Cost Labs
                                                                for any third
                                                                party that
                                                                assumes our
                                                                rights and
                                                                obligations
                                                                under this
                                                                Agreement by way
                                                                of unilateral
                                                                novation,
                                                                effective upon
                                                                notice to you.
                                                            </p>
                                                            <p>
                                                                6. Entire
                                                                Agreement. You
                                                                agree that this
                                                                Agreement
                                                                constitutes the
                                                                entire, complete
                                                                and exclusive
                                                                agreement
                                                                between you and
                                                                us regarding the
                                                                Services and
                                                                supersedes all
                                                                prior agreements
                                                                and
                                                                understandings,
                                                                whether written
                                                                or oral, or
                                                                whether
                                                                established by
                                                                custom,
                                                                practice, policy
                                                                or precedent,
                                                                with respect to
                                                                the subject
                                                                matter of this
                                                                Agreement. You
                                                                also may be
                                                                subject to
                                                                additional terms
                                                                and conditions
                                                                that may apply
                                                                when you use or
                                                                purchase certain
                                                                other Health
                                                                Cost Labs
                                                                services,
                                                                third-party
                                                                content or
                                                                third-party
                                                                software.
                                                            </p>
                                                        </div>

                                                        <div className="modal-footer">
                                                            <button
                                                                type="button"
                                                                className="btn btn-orange"
                                                                data-dismiss="modal"
                                                            >
                                                                Close
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default withRouter(Registration);
