

import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import BeforeLoginMenu from "../../routes/BeforeLoginMenu";
import AfterLoginMenu from "../../routes/AfterLoginMenu";
import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from 'powerbi-client';
import axios from "axios";

class Dashboard_New extends Component {
    constructor(props) {
        super(props);
        this.iFream = React.createRef();
        this.state = {
            token: localStorage.getItem("token"),
            isLoad: false,
            activeTab: 0,
            items: [],
            StateCode:[],
            NewStateCode:[],
            responseConfig: {}
        };
        if (process.env.NODE_ENV !== 'development') {
            document.addEventListener('contextmenu', (e) => {
                e.preventDefault();
            }
            );
            document.onkeydown = function (e) {
                if (e.keyCode == 123) {
                    return false;
                }
                if (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
                    return false;
                }
                if (e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
                    return false;
                }
                if (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
                    return false;
                }
                if (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
                    return false;
                }
            }
        }
        this.GetUrls = this.GetUrls.bind(this);
    }

    componentDidMount() {
        this.GetUrls()
    }


    GetUrls() {
        axios
            .get(`${process.env.REACT_APP_API_URL}api/Subscription/GetPowerBiToken?userId=${localStorage.getItem('userID')}`, {
                headers: {
                    Authorization: `Bearer ${this.state.token}`,
                    secret_key: localStorage.getItem("secret_key"),
                },
            })
            .then((response) => {
                let sdata = []
                if(response.data.State !== "ALL")
                {
                    sdata = response.data.State?.split(",")
                    sdata.push("AK")
                }
                let newsdata = []
                if(response.data.StateNew !== "ALL")
                {
                    newsdata = response.data.StateNew?.split(",")
                    newsdata.push("AK")
                }
                this.setState({
                    responseConfig: response.data,
                    StateCode:sdata,
                    NewStateCode: newsdata,
                    isLoad: true
                });
            })
            .catch((error) => {
                if (
                    error.response.status === 400 ||
                    error.response.status === 401 ||
                    error.response.status === 403
                ) {
                    this.props.history.push("/logout");
                }
                this.setState({
                    isLoad: true
                })
            });
    }


    render() {
        return (
            <>
                {this.state.token !== null ? <AfterLoginMenu /> : <BeforeLoginMenu />}
                {
                    !this.state.isLoad && <div className="loader-bg" id="preloader">
                        <div className="loader"></div>
                    </div>
                }
                <main role="main">
                    <div className="pt-5 mob-pg-pt our-mission">
                        <div className="container">

                            <div className="row">
                                <div className="col-12">
                                    <div className="dashboard_box">

                                        <PowerBIEmbed
                                            embedConfig={{
                                                type: "report", 
                                                id: this.state.responseConfig.ReportId,
                                                embedUrl: this.state.responseConfig.EmbedUrl,
                                                accessToken: this.state.responseConfig.EmbedToken,
                                                tokenType: models.TokenType.Embed,
                                                settings: {
                                                    panes: {
                                                        filters: {
                                                            expanded: false,
                                                            visible: false,
                                                        },
                                                        pageNavigation: {
                                                            visible: true,
                                                        },
                                                    },
                                                    background: models.BackgroundType.Transparent,
                                                },
                                                filters: [{
                                                    $schema: "http://powerbi.com/product/schema#basic",
                                                    target: {
                                                        table: "StateName_old",
                                                        column: "statecode"
                                                    },
                                                    operator: "eq",
                                                    values:  this.state.responseConfig.State !== "ALL"  ? this.state.StateCode : null
                                                },
                                                {
                                                    $schema: "http://powerbi.com/product/schema#basic",
                                                    target: {
                                                        table: "StateName_new",
                                                        column: "StateCode"
                                                    },
                                                    operator: "eq",
                                                    values:  this.state.responseConfig.StateNew !== "ALL"  ? this.state.NewStateCode : null
                                                }],
                                            }}
                                            cssClassName={"report-style-class"}
                                        />

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </main>

            </>
        );
    }
}
export default withRouter(Dashboard_New);
