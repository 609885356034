import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import BeforeLoginMenu from "../../routes/BeforeLoginMenu";
import AfterLoginMenu from "../../routes/AfterLoginMenu";

class TermsandCondition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: localStorage.getItem("token"),
   }

  }

  render() {
    return (
      <>
        {this.state.token !== null ? <AfterLoginMenu /> : <BeforeLoginMenu />}
        <main role="main">
          <div className="pt-5 pb-5">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h3 className="text-center mb-5 text-dark text-bold boldFont">
                  Terms & Conditions
                  </h3>
                </div>
                <div className="col-md-12">
                  <p className="text-justify">
                    {" "}
                   This Health Cost Labs Online Subscription Agreement ("Agreement") is by and between Health Cost Labs, LLC (hereinafter referred to as "Health Cost Labs", "us", "our" or "we") and you (hereinafter referred to as "SUBSCRIBER", “you” and “your”). This Agreement shall be effective as of the date of electronic execution ("Effective Date").  Electronic execution occurs when SUBSCRIBER indicates agreement to these Terms and Conditions by checking the box on the Signup Page.
                  </p>
                  <h5>INTRODUCTION</h5>
                  
                   <ul>
                      <li><i>Purpose.</i> The mission of Health Cost Labs is to provide insurance and financial professionals with the tools to enable them to be more productive and successful. To achieve our mission, we make services available through our website ("Health Cost Labs Site") and mobile applications to help you learn, work, find opportunities and make decisions.</li>
                      <li><i>Use.</i>SUBSCRIBER shall be provided access to the Health Cost Labs Site and/or Services for only the information they have subscribed to.</li>
                   </ul>
                   
                  <h5>YOUR OBLIGATIONS</h5>
                  <ul>
                    <li><i>Applicable</i> laws and this Agreement. You must comply with all applicable laws and this Agreement, as may be amended from time to time. As a condition to access the Health Cost Labs Site, you agree to this Agreement and to strictly observe the following DOs and DON'Ts
                        <ol type="a">
                        <li><b>DO undertake the following:</b>
                          <ul>
                              <li>Comply with all applicable laws, including, without limitation, privacy laws, intellectual property laws, and regulatory requirements;</li>
                              <li>Provide accurate information to us and update it as necessary;</li>
                              <li>Review and comply with our Privacy Policy;</li>
                              <li>Review and comply with notices sent by Health Cost Labs concerning the Services; and</li>
                              <li>Use the Services in a professional manner.</li>
                          </ul>
                        
                        </li>
                        <li><b>b)	DON'T undertake the following:</b>
                            <ul>
                                <li>Duplicate, license, sublicense, publish, broadcast, transmit, distribute, perform, display, sell, rebrand, or otherwise transfer information found on the Health Cost Labs Site, except as permitted in this Agreement or as expressly authorized by Health Cost Labs;</li>
                                <li>Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for any underlying intellectual property used to provide the Services, or any part thereof;</li>
                                <li>Create a user profile for anyone other than a natural person;</li>
                                <li>Utilize information, content or any data you view on and/or obtain from the Health Cost Labs Site to provide any service that is competitive, in Health Cost Labs's sole discretion, with the Health Cost Labs Site;</li>
                                <li>Imply or state, directly or indirectly, that you are affiliated with or endorsed by Health Cost Labs unless you have entered into a written agreement with Health Cost Labs (this includes, but is not limited to representing yourself as an accredited Health Cost Labs trainer if you have not been certified by Health Cost Labs as such);</li>
                                <li>Adapt, modify or create derivative works based on the Health Cost Labs Site or the technology underlying the Services;</li>
                                <li>Rent, lease, loan, trade, sell/re-sell access to the Health Cost Labs Site or any information therein, or the equivalent, in whole or part;</li>
                                <li>Use manual or automated software, devices, script robots, or other means or processes to access, "scrape," "crawl" or "spider" any data, web pages, or other services contained in the site;</li>
                                <li>Engage in "framing," "mirroring," or otherwise simulating the appearance or function of the Health Cost Labs Site;</li>
                                <li>Attempt to or actually access the Health Cost Labs Site by any means other than through the interfaces provided by Health Cost Labs;</li>
                                <li>Attempt to or actually override any security component included in or underlying the Health Cost Labs Site;</li>
                                <li>Engage in any action that directly or indirectly interferes with the proper working of or places an unreasonable load on our infrastructure, including but not limited to unsolicited communications to other users or Health Cost Labs personnel, attempts to gain unauthorized access, or transmission or activation of computer viruses;</li>
                                <li>Remove any copyright, trademark or other proprietary rights notices contained in or on the Health Cost Labs Site or any exports therefrom, including those of Health Cost Labs and any of its licensors;</li>
                                <li>Remove, cover or otherwise obscure any form of advertisement included on the Health Cost Labs Site;</li>
                                <li>Interfere with or disrupt the Health Cost Labs Site, including but not limited to any servers or networks connected to the Health Cost Labs Site;</li>
                                <li>Use or attempt to use another's account without authorization from Health Cost Labs; and</li>
                                <li>Infringe or use Health Cost Labs's brand, logos and/or trademarks, including, without limitation, using the word "Health Cost Labs" in any business name, email, or URL or including Health Cost Labs's trademarks and logos except as expressly permitted by Health Cost Labs.</li>
                            </ul>
                        </li>
                        </ol>
                           <li><i>License and warranty for your submissions to Health Cost Labs.</i> Any information you <i>submit</i> to us is at your own risk of loss. By providing information to us, you represent and warrant that you are entitled to submit the information and that the information is accurate, not confidential, and not in violation of any contractual restrictions or other third party rights. It is your responsibility to keep your Health Cost Labs profile information accurate and updated.</li>
                           <li><i>Service Eligibility.</i> To be eligible to use the Service, SUBSCRIBER must meet the following criteria and represent and warrant that they: (1) are 18 years of age or older; (2) are not currently restricted from the Services, or not otherwise prohibited from having a Health Cost Labs account, (3) are not a competitor of Health Cost Labs or are not using the Services for reasons that are in competition with Health Cost Labs; and (4) will only maintain one Health Cost Labs account at any given time.</li>

                    </li>
                  </ul>
                  <p>Each party hereby represents and warrants to the other that: (1) entering into this Agreement will not violate any other agreement to which it is a party; (2) will not violate any rights of the other party, including intellectual property rights such as patent, copyright or trademark rights; and (3) agrees to provide at its cost all equipment, software, and internet access necessary with respect to such party's use of the Services.</p>
                  <ul>
                      <li><i>Sign-In Credentials.</i> SUBSCRIBER agrees that they shall: (1) keep their passwords secure and confidential; (2) not permit others to use their account; (3) refrain from using other Users' accounts; and (4) refrain from selling, trading, or otherwise transferring their Health Cost Labs account to another party. SUBSCRIBER shall refrain from charging anyone for access to any portion of the Health Cost Labs Site, or any information therein. Further, SUBSCRIBER is responsible for anything that happens through its account until it closes down the account or proves that Health Cost Labs's account security was compromised due to no fault of SUBSCRIBER. To close your account, please email Customer Service at <a href="mailto:customerservice@healthcostlabs.com">customerservice@healthcostlabs.com</a>.</li>
                      <li><i>Payment.</i> SUBSCRIBER agrees to pay Health Cost Labs's monthly Subscription Fee for an annual term as agreed to when SUBSCRIBER completed the registration process and accepted these terms and conditions by providing their Credit Card information. Monthly receipts are provided in the Billing Portal section or can be requested at <a href="mailto:customerservice@healthcostlabs.com">customerservice@healthcostlabs.com</a>.</li>
                  </ul>
                  <p>Your annual subscription will be renewed automatically each year unless you terminate your Health Cost Labs subscription with written notice to Health Cost Labs sixty (60) or more days prior to the renewal date. All fees and charges are nonrefundable and there are no full, partial, or prorated refunds or credits for partially used periods unless Health Cost Labs terminates your subscription as set forth in Section 7.A. If you choose to purchase additional products or services from Health Cost Labs as part of your subscription, those additional products or services will become part of your annual term and shall be renewed automatically with your subscription unless terminated as above.</p>
                  <ul>
                      <li><i>Notify us of acts contrary to the Agreement.</i> If you believe that you are entitled or obligated to act contrary to this Agreement under any mandatory law, you agree to provide us with detailed and substantiated explanation of your reasons in writing at least thirty (30) days before you act contrary to this Agreement, to allow us to assess whether we may, at our sole discretion, provide an alternative remedy for the situation, though we are under no obligation to do so.</li>
                      <li><i>Notifications and Service Messages.</i> For purposes of service messages and notices about the Services to you, notice shall consist of an email from Health Cost Labs to the email address associated with your account, even if we have other contact information. You also agree that Health Cost Labs may communicate with you through your Health Cost Labs account or through other means including email, mobile number, telephone, or delivery services including the US Postal Service about your Health Cost Labs account or services associated with Health Cost Labs. You acknowledge and agree that we shall have no liability associated with or arising from your failure to maintain accurate contact or other information, including, but not limited to, your failure to receive critical information about the Service.</li>
                      <li><i>Mobile Services.</i> Health Cost Labs may offer the Services through mobile applications. If SUBSCRIBER uses the Services through a mobile device, they agree that information about the use of the Services through the mobile device may be communicated to us, including but not limited to the SUBSCRIBER's: mobile Partner, mobile device, or physical location. In addition, use of the Services through a mobile device may cause data to be displayed on and through your mobile device. In the event SUBSCRIBER changes or deactivates their mobile account, they must promptly update their Health Cost Labs account information to ensure that messages are not sent to the person that acquires the old number and failure to do so is SUBSCRIBER's responsibility. SUBSCRIBER is responsible for all charges and necessary permissions related to accessing the Health Cost Labs Site through a mobile access provider.</li>
                      <li><i>Third Party Pass-Through Terms of Service.</i> Health Cost Labs may offer services from third party companies. In some cases, those third parties require Health Cost Labs SUBSCRIBERS to comply with their terms and conditions. Health Cost Labs offers services from Google Maps/Google Earth. Health Cost Labs SUBSCRIBERS are bound by Google Maps/Google Earth Additional Terms of Service (including the Google Privacy Policy).</li>
                      <li><i>Privacy.</i> SUBSCRIBERS should carefully read our full Privacy Policy before deciding to become a SUBSCRIBER as it governs our treatment of any information, including personally identifiable information you submit to us. Please note that certain information, statements, data and content which SUBSCRIBERS may submit to Health Cost Labs may reveal gender, ethnic origin, nationality, age, religion and/or sexual orientation, and/or other personal information about the SUBSCRIBER. You acknowledge that submission of any information, statements, data, and content to us is voluntary.</li>
                      <li><i>Indemnification.</i> You expressly agree to indemnify us and hold us harmless for all damages, losses and costs (including, but not limited to, reasonable attorneys' fees and costs) related to all third party claims, charges, and investigations, caused by your acts including, without limitation, (1) your failure to comply with this Agreement, including, without limitation, your submission of content that violates third party rights or applicable laws, (2) any content you submit to the Services, (3) any activity in which you engage on, by or through the Health Cost Labs Site, (4) your use of any data provided by Health Cost Labs, and (5) any claim by any governmental entity for unpaid taxes or fees in any way related to your subscription with Health Cost Labs.</li>
                  </ul>
                  <h5>YOUR RIGHTS</h5>
                  <p>On the condition that you comply with all your obligations under this Agreement, we grant you a limited, revocable, nonexclusive, nonassignable, nonsublicenseable right to access information, through a generally available web browser or mobile device or application, view information, and use the Services that we provide on the Health Cost Labs Site and in accordance with this Agreement. Any other use of the Health Cost Labs Site contrary to our mission and purpose is strictly prohibited and a violation of this Agreement. We reserve all rights not expressly granted in this Agreement, including, without limitation, title, ownership, intellectual property rights, and all other rights and interest in the Health Cost Labs Site and all related items.</p>
                  <h5>OUR RIGHTS AND OBLIGATIONS</h5>
                  <ul>
                    <li><i>Services Availability.</i> For as long as Health Cost Labs continues to offer the Services, Health Cost Labs shall provide and seek to update, improve and expand the Services. As a result, we allow you to access the Health Cost Labs Site as it may exist and be available on any given day and have no other obligations, except as expressly stated in this Agreement. We may modify, replace, refuse access to, suspend or discontinue the Health Cost Labs Site, partially or entirely. In the event access is refused or the Health Cost Labs Site is suspended or discontinued, Health Cost Labs shall provide SUBSCRIBER with a prorated refund of its aggregated Annual Subscription Fee.</li>
                  </ul>
                  <p>Health Cost Labs may change and modify prices for all or part of the Services in our sole discretion once per year and shall notify SUBSCRIBER at least sixty (60) days prior to the end of the then current subscription term to give SUBSCRIBER time to evaluate the price change and decide if a renewal is warranted. Health Cost Labs further reserves the right to withhold, remove and or discard any content available as part of your account, with or without notice if deemed by Health Cost Labs to be contrary to this Agreement. For avoidance of doubt, Health Cost Labs has no obligation to store, maintain or provide you a copy of any content that you or other SUBSCRIBERS provide when using the Services. Any questions or issues surrounding the functionality of the Health Cost Labs Site or application should be directed to <a href="mailto:customerservice@healthcostlabs.com">customerservice@healthcostlabs.com</a>.</p>
                  <ol>
                      <li><i>Third Parties.</i> Health Cost Labs may include links to third party web sites ("Third Party Sites") on www.Health Cost Labs.biz and elsewhere. You are responsible for evaluating whether you want to access or use a Third Party Site. You should review any applicable terms and/or privacy policy of a Third Party Site before using it or sharing any information with it, because you may give the operator permission to use your information in ways we would not.</li>
                  </ol>
                    <p>Health Cost Labs is not responsible for and does not endorse any features, content, advertising, products or other materials on or available from Third Party Sites. Accordingly, if you decide to access Third Party Sites, you do so at your own risk and agree that that this Agreement does not apply to your use of any Third Party Site.</p>
                    <p>Please note: If you allow a Third Party Site to authenticate to or connect with your Health Cost Labs account, that application or website can access information on Health Cost Labs related to you and your connections.</p>
                    <ul>
                      <li><i>Disclosure of SUBSCRIBER Information.</i> You acknowledge, consent and agree that we may access, preserve, and disclose your registration and any other information you provide if required to do so by law or in a good faith belief that such access preservation or disclosure is reasonably necessary in our opinion to: (1) comply with legal process, including but not limited to civil and criminal subpoenas, court orders or other compulsory disclosures; (2) enforce this Agreement; (3) respond to claims of a violation of the rights of third parties, whether or not the third party is a user, individual, or government Partner; (4) respond to customer service inquiries; or (5) protect the rights, property, or personal safety of Health Cost Labs, our users or the public.</li>
                      <li><i>Equal Opportunity.</i> The parties hereto and their respective subcontractors shall abide by the requirements of 41 CFR 60-300.5(a) and 60-741.5(a). These regulations prohibit discrimination against qualified individuals on the basis of protected veteran status or disability, and require affirmative action by covered prime contractors and subcontractors to employ and advance in employment qualified protected veterans and individuals with disabilities.</li>
                    </ul>
                    <h5>DISCLAIMER</h5>
                    <p>SUBSCRIBER ACKNOWLEDGES THAT THE DATA CONTAINED ON THE HEALTH COST LABS SITE IS DATA OBTAINED FROM THIRD PARTIES AND WILL CONTAIN A DEGREE OF ERROR AND THAT SUBSCRIBER IS RESPONSIBLE FOR DETERMINING THAT THE DATA CONTAINED ON THE HEALTH COST LABS SITE IS SUFFICIENTLY ACCURATE FOR SUBSCRIBER'S PURPOSES. THE DATA CONTAINED ON THE HEALTH COST LABS SITE AND PROVIDED HEREUNDER IS PROVIDED ON AN AS-IS, AS-AVAILABLE BASIS AND HEALTH COST LABS DOES NOT MAKE AND HEREBY DISCLAIMS ANY WARRANTY, EXPRESS OR IMPLIED, WITH RESPECT TO THE LICENSED DATA, INCLUDING, BUT NOT LIMITED TO, THE ACCURACY, COMPLETENESS, TIMELINESS, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OF THE DATA.</p>
                    <p>DO NOT RELY ON THE HEALTH COST LABS SITE, ANY INFORMATION THEREIN, OR ITS CONTINUATION. WE PROVIDE THE PLATFORM FOR HEALTH COST LABS AND ALL INFORMATION AND SERVICES ON AN "AS IS" AND "AS AVAILABLE" BASIS. WE DO NOT PROVIDE ANY EXPRESS WARRANTIES OR REPRESENTATIONS.</p>
                    <p>TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW, WE DISCLAIM ANY AND ALL IMPLIED WARRANTIES AND REPRESENTATIONS, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NONINFRINGEMENT.</p>
                    <p>HEALTH COST LABS NEITHER WARRANTS NOR REPRESENTS THAT YOUR USE OF THE SERVICE WILL NOT INFRINGE THE RIGHTS OF THIRD PARTIES. ANY MATERIAL, SERVICE, OR TECHNOLOGY DESCRIBED OR USED ON THE HEALTH COST LABS SITE MAY BE SUBJECT TO INTELLECTUAL PROPERTY RIGHTS OWNED BY THIRD PARTIES WHO HAVE LICENSED SUCH MATERIAL, SERVICE OR TECHNOLOGY TO US.</p>
                    <p>HEALTH COST LABS DOES NOT GUARANTEE THAT THE SERVICES IT PROVIDES WILL FUNCTION WITHOUT INTERRUPTION OR ERRORS IN FUNCTIONING. IN PARTICULAR, THE OPERATION OF THE SERVICES MAY BE INTERRUPTED DUE TO MAINTENANCE, UPDATES, OR SYSTEM OR NETWORK FAILURES. HEALTH COST LABS DISCLAIMS ALL LIABILITY FOR DAMAGES CAUSED BY ANY SUCH INTERRUPTION OR ERRORS IN FUNCTIONING. FURTHERMORE, HEALTH COST LABS DISCLAIMS ALL LIABILITY FOR ANY MALFUNCTIONING, IMPOSSIBILITY OF ACCESS, OR POOR USE CONDITIONS OF THE HEALTH COST LABS SITE DUE TO DISTURBANCES RELATED TO INTERNET SERVICE PROVIDERS, TO THE SATURATION OF THE INTERNET NETWORK, SUBSCRIBER'S USE OF OUTDATED OR INCOMPATIBLE WEB BROWSERS, AND FOR ANY OTHER REASON.</p>
                    <h5>LIMITATION OF LIABILITY</h5>
                    <p>Neither Health Cost Labs nor any of our subsidiaries, affiliated companies, employees, shareholders, members, or directors (hereinafter "Health Cost Labs Affiliates") shall be liable for (a) any damages in excess of three (3) times the annual subscription fee you paid or (b) any special, incidental, indirect, punitive or consequential damages or loss of use, profit, revenue or data to you or any third person arising from your use of the Service, any platform applications or any of the content or other materials on, accessed through or downloaded from the Health Cost Labs Site. This limitation of liability shall:</p>
                        <ol>
                            <li>Apply regardless of whether (1) you base your claim on contract, tort, statute or any other legal theory, (2) we knew or should have known about the possibility of such damages, or (3) the limited remedies provided in this section fail of their essential purpose; and</li>
                            <li>Not apply to any damage that Health Cost Labs may cause you intentionally or knowingly in violation of this Agreement or applicable law, or as otherwise mandated by applicable law that cannot be disclaimed from in this Agreement.</li>
                        </ol>
                    <h5>TERMINATION</h5>
                    <ul>
                        <li>Mutual rights of termination. You may terminate this Agreement, for any or no reason, at any time, with notice to Health Cost Labs. This notice will be effective upon Health Cost Labs processing your notice, said processing shall not take longer than five (5) business days. If you terminate this Agreement, you remain responsible for payment for the remainder of your aggregated Annual Subscription Fee as set forth in Section 2.E.</li>
                        <li>YOU MUST DESTROY/DELETE ANY DATA PROCURED FROM THE HEALTH COST LABS WEBSITE UPON TERMINATION OF SUBSCRIPTION/TRIAL PERIOD.</li>
                    </ul>
                    <p>Health Cost Labs may terminate this Agreement for any reason or no reason, at any time, with or without notice. This cancellation shall be effective immediately or as may be specified in the notice. Termination of your Health Cost Labs account includes disabling your access to Health Cost Labs and may also bar you from any future use of Health Cost Labs. If Health Cost Labs terminates this Agreement for reasons other than those set forth in Section 7.B., Health Cost Labs will refund a prorated amount of the aggregated Annual Subscription Fee paid by SUBSCRIBER.</p>
                    <ul>
                        <li><i>Misuse</i> of the Services. Health Cost Labs may restrict, suspend or terminate the account of any SUBSCRIBER who abuses or misuses the Services. Misuse of the Services includes, but is not limited to, the following: creating multiple or false profiles; sharing the username and password with others; infringing any intellectual property rights of Health Cost Labs or any other person or entity, or any other behavior that Health Cost Labs, in its sole discretion, deems contrary to its purpose. In the event Health Cost Labs decides to terminate SUBSCRIBER's account, Health Cost Labs shall notify SUBSCRIBER of the misuse of the Services and allow SUBSCRIBER ninety (90) days to rectify the situation. Upon termination of the SUBSCRIBER account for misuse of Health Cost Labs's Services, you remain responsible for payment for the remainder of your aggregated Annual Subscription Fee as set forth in Section 2.E.</li>
                        <li><i>Effect of Termination.</i> Upon the termination of your Health Cost Labs account, you lose access to the Services. In addition, Health Cost Labs may block access to the Services from an IP address or range of IP addresses associated with those of terminated SUBSCRIBERS. The terms of this Agreement shall survive any termination, except Sections 3 ("Your Rights") and 4 ("Our Rights and Obligations") hereof.</li>
                    </ul>
                    <h5>GENERAL TERMS</h5>
                    <ul>
                        <li><i>Severability. </i>If any provision of this Agreement is found by a court of competent jurisdiction or arbitrator to be illegal, void, or unenforceable, the unenforceable provision will be modified so as to render it enforceable and effective to the maximum extent possible in order to effect the intention of the provision; and if a court or arbitrator finds the modified provision invalid, illegal, void or unenforceable, the validity, legality and enforceability of the remaining provisions of this Agreement will not be affected in any way.</li>
                        <li><i>Language.</i> Where Health Cost Labs has provided you with a translation of the English language version of this Agreement, the Privacy Policy, and/or any other documentation, you agree that the translation is provided for your convenience only and that the English language versions of this Agreement, the Privacy Policy, and any other documentation, will govern your relationship with Health Cost Labs.</li>
                        <li><i>Notices and Service of Process.</i> In addition to Section 2.G. ("Notifications and Service Messages"), we may notify you via postings on www.Health Cost Labs.biz. You may contact us via email at: <a href="customerservice@healthcostlabs.com">customerservice@healthcostlabs.com</a></li>
                    </ul>
                    <p>or via mail or courier at:
                        <b>Health Cost Labs, LLC.</b>
                        ATTN: Legal Department
                        260 Schuylkill Road #1005
                        Phoenixville, PA 19460
                        267-480-9267
                    </p>
                    <p>Additionally, Health Cost Labs accepts service of process at this address. Any notices that you provide without compliance with this section shall have no legal effect.</p>
                    <p>Notices related to or required to be provided to SUBSCRIBER under this Agreement shall be sent to address provided by SUBSCRIBER on website.</p>
                    <ol>
                        <li><i>Amendments to this Agreement.</i> We reserve the right to modify, supplement or replace the terms of the Agreement at any time.</li>
                        <li><i>No informal waivers, agreements or representations.</i> Our failure to act with respect to a breach of this Agreement by you or others does not waive our right to act with respect to that breach or subsequent similar or other breaches, except as expressly and specifically contemplated by this Agreement. No representations, statements, consents, waivers or other acts or omissions by any Health Cost Labs Affiliate shall be deemed legally binding on any Health Cost Labs Affiliate, unless documented in a physical writing hand signed by a duly appointed officer of Health Cost Labs.</li>
                        <li><i>Governing Law and Jurisdiction.</i> This Agreement and performance hereunder shall be governed by the laws of the State of Pennsylvania without regard to conflicts of laws principles of such jurisdiction. SUBSCRIBER and Health Cost Labs hereby agree that the sole and exclusive jurisdiction and venue for any litigation arising from or relating to this Agreement shall be an appropriate federal or state court located in the State of Pennsylvania.</li>
                        <li><i>No Injunctive Relief.</i> In no event shall you seek or be entitled to rescission, injunctive or other equitable relief, or to enjoin or restrain the operation of the Service, exploitation of any advertising or other materials issued in connection therewith, or exploitation of the Services or any content or other material used or displayed through the Services, and you expressly waive your rights related to same.</li>
                        <li><i>Assignment and Delegation.</i> You may not assign or delegate any rights or obligations under this Agreement. Any purported assignment and delegation shall be ineffective, invalid, and void. We may freely assign or delegate all rights and obligations under this Agreement, fully or partially, with ninety (90) days prior written notice to you. We may also substitute Network Optimization, LLC, d/b/a Health Cost Labs for any third party that assumes our rights and obligations under this Agreement by way of unilateral novation, effective upon notice to you.</li>
                        <li><i>Entire Agreement. </i>You agree that this Agreement constitutes the entire, complete and exclusive agreement between you and us regarding the Services and supersedes all prior agreements and understandings, whether written or oral, or whether established by custom, practice, policy or precedent, with respect to the subject matter of this Agreement. You also may be subject to additional terms and conditions that may apply when you use or purchase certain other Health Cost Labs services, third-party content or third party software.</li>
                    </ol>
                </div>
              </div>
            </div>
     
  
          
              </div>
          
        </main>
      </>
    );
  }
}
export default withRouter(TermsandCondition);
