import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import BeforeLoginMenu from "../../routes/BeforeLoginMenu";
import AfterLoginMenu from "../../routes/AfterLoginMenu";
import { Browser, setStyleAttribute } from "@syncfusion/ej2-base";
import { DataManager, WebApiAdaptor } from "@syncfusion/ej2-data";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";

import {
  CalculatedField,
  PivotFieldListComponent,
  Inject,
  PivotViewComponent,
} from "@syncfusion/ej2-react-pivotview";

import "./pivottable.css";

const CUSTOM_CSS = `
.e-pivotview {
width: 65%;
height: 100%;
float: left;
}
.e-pivotfieldlist {
width: 35%;
height: 100%;
float: right;
}
.e-pivotfieldlist .e-static {
width: 100% !important;
}
.e-frozenheader .e-table
{
    width:500px !important;
}
.e-pivotview .e-headercell{
    background-color: #666d77 !important;
    padding-top:15px
    padding-bottom:15px;
  }
  .e-pivotview .e-headertext{
      color:#fff;
      font-size:15px;
  }
  .e-grid.e-default tr:last-child td:first-child{
    background:#9eb0b9 !important;
    color:#fff !important;
  }
  .e-grid.e-default tr:last-child td:first-child .e-cellvalue{
      color:#fff !important;
  }
  .e-pivotview .e-grid .e-rowcell{
      background:#9eb0b9;
  }

.e-frozencontent #PivotViewFieldList_grid_content_table
{
    width:500px!important;
}
`;

class PivotTable extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem("token");
    if (!token) {
      this.props.history.push("/login");
    }

    this.state = {
      token: localStorage.getItem("token"),
      pivotData: [],
      states: [],
      selectState: "",
      isLoaded: false,
      dataSourceSettings: "",
      showLoader: false,
      cities: [],
      selectCity: "",
      filters: {
        msaName: [],
        location: [],
        category: [],
        maxDate: "",
        msatablename: ""
        //codeDescription: [],
      },
      msaName: "",
      location: "",
      category: ""

      //codeDescription: "",
    };

    let filterArr = JSON.stringify({
      state: "AL",
      MmInclude: "true",
    });

    this.remoteData = new DataManager({
      headers: {
        Authorization: `Bearer ${this.state.token}`,
        secret_key: localStorage.getItem("secret_key"),
      },
      url: `${process.env.REACT_APP_API_URL}api/Subscription/getrates?PiVotInputData=${filterArr}`,
      adaptor: new WebApiAdaptor(),
      crossDomain: true,
    });

    this.exportType = [
      { value: "pdf", text: "PDF" },
      { value: "excel", text: "Excel" },
      { value: "csv", text: "CSV" },
    ];

    this.dataSourceSettings = {
      dataSource: this.remoteData,
      type: "JSON",
      expandAll: false,
      allowLabelFilter: true,
      allowValueFilter: true,
      columns: [],
      rows: [
        { name: "FacilityName", caption: "Facility Name" },
        { name: "Category", caption: "Category" },
        {
          name: "codeDescription",
          caption: "Code Description",
        },
        { name: "procedureCode", caption: "Procedure Code" },
      ],
      values: [{ name: "rate", caption: "Negotiate Rate" }],
      formatSettings: [
        { name: "FacilityName", format: "C0" },
        { name: "rate", format: "C2" },
        {
          name: "cmsRelativity",
          format: "C2",
        },
      ],
      filters: [
        {
          name: "rate",
          caption: "Negotiate Rate",
        },
        { name: "healthsystemType", caption: "Healthsystem Type" },
        { name: "stateName", caption: "State" },
      ],
      enableSorting: true,
      valueSortSettings: {
        headerText: "Negotiate Rate($)",
        sortOrder: "Descending",
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleStatesChange = this.handleStatesChange.bind(this);
    this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
  }




  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
    // if(event.target.name === 'category')
    // {
    //   this.handleCategoryChange(event.target.value);
    // }
  }

  handleExportClick() {
    // this.pivotObj = document.getElementById("PivotViewFieldList").ej2_instances[0];
    // this.pivotObj.pdfExport();
    // if (this.mode.value === "excel") {
    //     this.pivotObj.excelExport();
    // } else if (this.mode.value === "csv") {
    //     this.pivotObj.csvExport();
    // } else {
    //     this.pivotObj.pdfExport();
    // }

    const selectedVal = this.state.selectState;

    const { msaName, location, category, codeDescription } = this.state;
    const selectedStateName = this.state.selectedStateName;
    const maxDate = null

    var fields = selectedVal.split("-");

    var stateCode = fields[1];
    var mmInclude = fields[0];
    //var stateName = fields[2];
    var stateName = selectedStateName;

    this.filterArr = JSON.stringify({
      state: stateCode,
      MmInclude: mmInclude,
      tableName: this.state.filters.msatablename,
      stateName: stateName,
      msaName: msaName,
      location: location,
      category: category,
      importtime: maxDate,
      userId:localStorage.getItem('userID')
      //codeDescription: codeDescription,
    });
    window.open(
      `${process.env.REACT_APP_API_URL}api/Subscription/export?pivotinput=${this.filterArr}`,
      "_blank"
    );


  }

  getAllState() {
    axios
      .get(
        `${process.env.REACT_APP_API_URL
        }api/Subscription/getstateforpivotfilter?userID=${localStorage.getItem(
          "userID"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
            secret_key: localStorage.getItem("secret_key"),
          },
        }
      )
      .then((response) => {
        this.setState({
          states: Object.values(response.data),
        });
      })
      .catch((error) => {
        if (
          error.response.status === 400 ||
          error.response.status === 401 ||
          error.response.status === 403
        ) {
          localStorage.removeItem("token");
          localStorage.removeItem("email");
          localStorage.removeItem("userID");
          localStorage.removeItem("userName");
          localStorage.removeItem("secret_key");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("expired");
          this.props.history.push("/logout");
        }
      });
  }

  refreshToken() {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    const expired = localStorage.getItem("expired");

    if (token !== null && refreshToken !== null && expired !== null) {
      axios
        .post(`${process.env.REACT_APP_API_URL}api/auth/refreshToken`, {
          token: token,
          refreshToken: refreshToken,
          expiration: expired,
        })
        .then((response) => {
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("expired");
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("refreshToken", response.data.refreshToken);
          localStorage.setItem("expired", response.data.expiration);
        })
        .catch((error) => {
          localStorage.removeItem("token");
          localStorage.removeItem("email");
          localStorage.removeItem("userID");
          localStorage.removeItem("userName");
          localStorage.removeItem("secret_key");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("expired");
          this.props.history.push("/logout");
        });
    }
  }

  //   handleStatesChange(event) {
  //     const selectedVal = event.target.value;
  // //const text = event.target.selectedIndex;
  // var index = event.nativeEvent.target.selectedIndex;
  // const selectedStateName = event.nativeEvent.target[index].text
  // console.log(selectedStateName);
  //   this.setState({
  //     selectedStateName: selectedStateName,
  //   });
  //     var fields = selectedVal.split("-");

  //     var stateCode = fields[1];
  //     //var stateName = fields[2];
  //     var stateName = selectedStateName;


  //     this.stateArr = JSON.stringify({
  //       stateCode: stateCode,
  //       stateName: stateName,
  //     });

  //     if (selectedVal !== "") {
  //       this.setState({
  //         selectState: selectedVal,
  //         showLoader: true,
  //         filters: {
  //           msaName: [],
  //           location: [],
  //           category: [],
  //         },
  //         msaName: "",
  //         location: "",
  //         category: "",
  //       });
  //       axios
  //         .get(
  //           `${process.env.REACT_APP_API_URL}api/Subscription/getcitybystateid?state=${this.stateArr}`,
  //           {
  //             headers: {
  //               Authorization: `Bearer ${this.state.token}`,
  //               secret_key: localStorage.getItem("secret_key"),
  //             },
  //           }
  //         )
  //         .then((response) => {
  //           this.setState({
  //             filters: {
  //               msaName: Object.values(response.data.msaName),
  //               location: Object.values(response.data.location),
  //               category: [],
  //               // codeDescription: Object.values(
  //               //     response.data.codeDescription
  //               // ),
  //             },
  //             showLoader: false,
  //             isLoaded: false,
  //           });
  //         })
  //         .catch((error) => {
  //           if (
  //             error.response.status === 400 ||
  //             error.response.status === 401 ||
  //             error.response.status === 403
  //           ) {
  //             this.setState({
  //               showLoader: false,
  //             });
  //             localStorage.removeItem("token");
  //             localStorage.removeItem("email");
  //             localStorage.removeItem("userID");
  //             localStorage.removeItem("userName");
  //             localStorage.removeItem("secret_key");
  //             localStorage.removeItem("refreshToken");
  //             localStorage.removeItem("expired");
  //             this.props.history.push("/logout");
  //           }
  //         });
  //     } else {
  //       this.setState({
  //         selectState: "",
  //         filters: {
  //           msaName: [],
  //           location: [],
  //           category: [],
  //         },
  //         showLoader: false,
  //         isLoaded: false,
  //         dataSourceSettings: "",
  //       });
  //     }
  //   }

  handleStatesChange(event) {
    const selectedVal = event.target.value;
    //const text = event.target.selectedIndex;
    var index = event.nativeEvent.target.selectedIndex;
    const selectedStateName = event.nativeEvent.target[index].text
    // console.log(selectedStateName);
    this.setState({
      selectedStateName: selectedStateName,
    });
    var fields = selectedVal.split("-");

    var stateCode = fields[1];

    if (selectedVal !== "") {
      this.setState({
        selectState: selectedVal,
        showLoader: true,
        filters: {
          msaName: [],
          location: [],
          category: [],
        },
        msaName: "",
        location: "",
        category: "",
      });
      axios
        .get(
          `${process.env.REACT_APP_API_URL}api/Subscription/getmsafilter?stateCode=${stateCode}`,
          {
            headers: {
              Authorization: `Bearer ${this.state.token}`,
              secret_key: localStorage.getItem("secret_key"),
            },
          }
        )
        .then((response) => {
          console.log(response.data)
          this.setState({
            filters: {
              msaName: response.data,
              location: [
                {
                  "location": "Inpatient",
                  "locationID": 1
                },
                {
                  "location": "Outpatient",
                  "locationID": 2
                }
              ],
              category: [],
              // codeDescription: Object.values(
              //     response.data.codeDescription
              // ),
            },
            showLoader: false,
            isLoaded: false,
          });
        })
        .catch((error) => {
          if (
            error.response.status === 400 ||
            error.response.status === 401 ||
            error.response.status === 403
          ) {
            this.setState({
              showLoader: false,
            });
            localStorage.removeItem("token");
            localStorage.removeItem("email");
            localStorage.removeItem("userID");
            localStorage.removeItem("userName");
            localStorage.removeItem("secret_key");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("expired");
            this.props.history.push("/logout");
          }
        });
    } else {
      this.setState({
        selectState: "",
        filters: {
          msaName: [],
          location: [],
          category: [],
        },
        showLoader: false,
        isLoaded: false,
        dataSourceSettings: "",
      });
    }
  }

  handleLocationChange(event) {
    const sellocation = event.target.value;
    const selstates = this.state.selectState;
    const selmsaName = this.state.msaName;
    const selmsaTable = this.state.filters.msatablename;
    const selectedStateName = this.state.selectedStateName;
    console.log(selectedStateName, selmsaName);
    var fields = selstates.split("-");
    //var stateName = fields[2];
    var stateName = selectedStateName;
    this.filterArr = JSON.stringify({
      msaName: selmsaName,
      location: sellocation,
      tableName: selmsaTable
    });

    if (sellocation !== "" && selmsaName !== "") {
      this.setState({
        location: sellocation,
        showLoader: true,
        filters: {
          msaName: this.state.filters.msaName,
          location: this.state.filters.location,
          category: [],
          msatablename: selmsaTable
        },
        category: "",
      });
      axios
        .get(
          `${process.env.REACT_APP_API_URL}api/Subscription/getcategoryfilter?filterInput=${this.filterArr}`,
          {
            headers: {
              Authorization: `Bearer ${this.state.token}`,
              secret_key: localStorage.getItem("secret_key"),
            },
          }
        )
        .then((response) => {
          this.setState({
            filters: {
              msaName: this.state.filters.msaName,
              location: this.state.filters.location,
              category: Object.values(response.data),
              msatablename: selmsaTable
            },
            showLoader: false,
            isLoaded: false,
          });
        })
        .catch((error) => {
          this.setState({
            showLoader: false,
          });
          if (
            error.response.status === 400 ||
            error.response.status === 401 ||
            error.response.status === 403
          ) {
            this.setState({
              showLoader: false,
            });
            localStorage.removeItem("token");
            localStorage.removeItem("email");
            localStorage.removeItem("userID");
            localStorage.removeItem("userName");
            localStorage.removeItem("secret_key");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("expired");
            this.props.history.push("/logout");
          }
        });
    } else {
      this.setState({
        location: "",
        filters: {
          msaName: this.state.filters.msaName,
          location: this.state.filters.location,
          category: [],
        },
        showLoader: false,
        dataSourceSettings: "",
      });
    }
  }


  handleMsaChange(event) {
    console.log(event.target.selectedOptions[0].getAttribute('tablename'))
    this.setState({
      filters: {
        msaName: this.state.filters.msaName,
        location: this.state.filters.location,
        msatablename: event.target.selectedOptions[0].getAttribute('tablename'),
        category: [],
      },
      location: "",
      category: "",
    });
  }

  handleCategoryChange(e) {
    this.setState({
      showLoader: true,
    });
    const selectedVal = this.state.selectState;
    const { msaName, location } = this.state;
    const selectedStateName = this.state.selectedStateName;
    const category = e;

    if (
      selectedVal !== "" &&
      msaName !== "" &&
      location !== "" &&
      category !== ""
    ) {
      var fields = selectedVal.split("-");

      var stateCode = fields[1];
      var mmInclude = fields[0];
      // var stateName = fields[2];
      var stateName = selectedStateName;



      const filters = JSON.stringify({
        state: stateCode,
        MmInclude: mmInclude,
        stateName: stateName,
        msaName: msaName,
        location: location,
        category: category,
        //codeDescription: codeDescription,
      });

      axios
        .get(
          `${process.env.REACT_APP_API_URL}api/Subscription/GetMaxdate?filterInput=${filters}`,
          {
            headers: {
              Authorization: `Bearer ${this.state.token}`,
              secret_key: localStorage.getItem("secret_key"),
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.setState({
            filters: {
              msaName: this.state.filters.msaName,
              location: this.state.filters.location,
              category: this.state.filters.category,
              maxDate: Object.values(response.data),
            },
            showLoader: false,
          });
        })
        .catch((error) => {
          this.setState({
            showLoader: false,
          });
        });
    }
  }

  handleFilterSubmit(event) {
    const selectedVal = this.state.selectState;
    const { msaName, location, category } = this.state;
    const selectedStateName = this.state.selectedStateName;
    const selmsaTable = this.state.filters.msatablename;
    // console.log(selmsaTable,this.state.filters)
    const maxDate = null
    if (
      selectedVal !== "" &&
      msaName !== "" &&
      location !== "" &&
      category !== "" &&
      maxDate !== ""
    ) {
      var fields = selectedVal.split("-");

      var stateCode = fields[1];
      var mmInclude = fields[0];
      // var stateName = fields[2];
      var stateName = selectedStateName;



      this.filterArr = JSON.stringify({
        state: stateCode,
        MmInclude: mmInclude,
        stateName: stateName,
        msaName: msaName,
        location: location,
        category: category,
        importtime: maxDate
        //codeDescription: codeDescription,
      });

      this.setState({
        showLoader: true,
      });

      axios
        .post(
          // `${process.env.REACT_APP_API_URL}api/Subscription/getrates?PiVotInputData=${this.filterArr}`,
          `${process.env.REACT_APP_API_URL}api/Subscription/getrates`,
          {
            state: stateCode,
            MmInclude: mmInclude,
            tableName: selmsaTable,
            msaName: msaName,
            location: location,
            category: category,
            importtime: maxDate
            // codeDescription: codeDescription,
          },
          {
            headers: {
              Authorization: `Bearer ${this.state.token}`,
              secret_key: localStorage.getItem("secret_key"),
            },
          }
        )
        .then((response) => {
          this.setState({
            dataSourceSettings: {
              dataSource: response.data,
              type: "JSON",
              expandAll: false,
              allowLabelFilter: true,
              allowValueFilter: true,
              columns: [],
              rows: [
                {
                  name: "FacilityName",
                  caption: "Facility Name",
                },
                {
                  name: "Category",
                  caption: "Category",
                },
                {
                  name: "codeDescription",
                  caption: "Code Description",
                },
                {
                  name: "procedureCode",
                  caption: "Procedure Code",
                },
                {
                  name: "PlanName",
                  caption: "Plan Name",
                },
              ],
              values: [
                {
                  name: "rate",
                  caption: "Negotiate Rate ($)",
                  type: "Avg",
                },
              ],
              formatSettings: [
                { name: "FacilityName", format: "C0" },
                { name: "rate", format: "C2" },
                {
                  name: "cmsRelativity",
                  format: "C2",
                }
              ],
              filters: [
                {
                  name: "Category",
                  caption: "Category",
                },
                {
                  name: "healthsystemType",
                  caption: "Health System",
                },
                { name: "LOB", caption: "LOB" },
                { name: "carrier", caption: "Carrier" },
                { name: "product", caption: "Product" },
                { name: "location", caption: "Location" },
                { name: "MSAName", caption: "MSA Name" },
                { name: "stateName", caption: "State" },
                {
                  name: "FacilityName",
                  caption: "Facility Name",
                },
                {
                  name: "cmsRelativity",
                  caption: "CMS Relativity",
                  format: "C2",
                },
                {
                  name: "hospitalType", caption: "Hospital Type"
                },
                {
                  name: "npi", caption: "NPI"
                },
                {
                  name: "CMSNormalized",
                  caption: "CMS Normalized",
                  format: "C2",
                },
                {
                  name: "CmsRate",
                  caption: "CMS Rate",
                  format: "C2",
                },
                {
                  name: "GrossCharges",
                  caption: "Gross Charges",
                },
              ],
              enableSorting: true,
              valueSortSettings: {
                headerText: "Negotiate Rate($)",
                sortOrder: "Descending",
              },
            },
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            showLoader: false,
          });
          if (
            error.response.status === 400 ||
            error.response.status === 401 ||
            error.response.status === 403
          ) {
            localStorage.removeItem("token");
            localStorage.removeItem("email");
            localStorage.removeItem("userID");
            localStorage.removeItem("userName");
            localStorage.removeItem("secret_key");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("expired");
            this.props.history.push("/logout");
          }
        });

      if (this.pivotObj) {
        this.remoteData = "";
        this.pivotObj.refresh();
      }
      event.preventDefault();
    }
  }

  componentDidMount() {
    this.refreshToken();
    this.getAllState();
  }

  render() {
    return (
      <>
        {this.state.token !== null ? <AfterLoginMenu /> : <BeforeLoginMenu />}
        <style>{CUSTOM_CSS}</style>
        {this.state.showLoader && (
          <div className="loader-bg" id="preloader">
            <div className="loader"></div>
          </div>
        )}
        <main role="main">
          <div className="pt-5 pb-5 mob-pg-pt">
            <div className="container">
              <div className="form">
                <div className=" text-center font-weight-bold pb-3">
                  <a
                    className="pivotVideoLink"
                    href="https://youtu.be/iD5no5l8tT8"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    To Learn More -&gt; Watch Pivot Explainer Video
                  </a>
                  <br /> <a className="pivotVideoLink" target="_blank" href="https://datacatalog.urban.org/dataset/betos-20-classification-code-assignments-2019/resource/76dee1e5-5c70-492f-83ac-36ffd691e93c">Betos Categories</a>
                </div>
                <div className="row pt-4 gray-bg">
                  <form>
                    <div className="col-md-12">
                      <div className="row pivotFilter-box">
                        {this.state.states.length > 0 && (
                          <div className="col-md-4 col-sm-6">
                            <div className="form-group">
                              <label className="d-inline-block bold_text pr-3 pb-3 white-text col-md-4">
                                State:
                              </label>
                              <select
                                className="md-form drpdownList mdb-select white-bg col-md-8 custom_select"
                                name="selectState"
                                onChange={(e) => {
                                  this.handleChange(e);
                                  this.handleStatesChange(e);
                                }}
                                required
                              >
                                <option value="">Select</option>
                                {this.state.states.map((stateArr, index) => (
                                  <option
                                    value={
                                      stateArr.mrmIncluded +
                                      "-" +
                                      stateArr.stateCode +
                                      "-" +
                                      stateArr.stateName +
                                      "-" +
                                      stateArr.stateID
                                    }
                                  >
                                    {stateArr.stateName}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        )}
                        {this.state.states.length === 0 && (
                          <div className="col-md-4 col-sm-6">
                            <div className="form-group">
                              <label className="d-inline-block bold_text pr-3 pb-3 white-text col-md-4">
                                State:
                              </label>
                              <select
                                className="md-form drpdownList mdb-select white-bg col-md-8 custom_select"
                                name="selectState"
                                // onChange={this.handleStatesChange}
                                onChange={(e) => {
                                  this.handleChange(e);
                                  this.handleStatesChange(e);
                                }}
                                required
                              >
                                <option value="">Select</option>
                                <option value="false-AK-Alaska-2">
                                  Alaska
                                </option>
                              </select>
                            </div>
                          </div>
                        )}
                        <div className="col-md-4 col-sm-6">
                          <div className="form-group">
                            <label className="d-inline-block bold_text pl-0 pl-lg-2 pr-3 pb-3 white-text col-md-5">
                              MSA Name:
                            </label>
                            <select
                              className="md-form drpdownList mdb-select white-bg col-md-7 custom_select"
                              name="msaName"
                              onChange={(e) => {
                                this.handleChange(e);
                                this.handleMsaChange(e);
                              }}
                              required
                            >
                              <option value="">Select</option>
                              {this.state.filters.msaName.map(
                                (msaArr, index) => (
                                  <option value={msaArr.msaID || 0} tablename={msaArr.TableName}>
                                    {msaArr.MSA}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>

                        <div className="col-md-4 col-sm-6">
                          <div className="form-group">
                            <label className="d-inline-block bold_text pl-0 pl-lg-2 pr-3 pb-3 white-text col-md-5">
                              Location:
                            </label>
                            <select
                              className="md-form drpdownList mdb-select white-bg col-md-7 custom_select"
                              name="location" value={this.state.location}
                              onChange={(e) => {
                                this.handleChange(e);
                                this.handleLocationChange(e);
                              }}
                              required
                            >
                              <option value="">Select</option>
                              {this.state.filters.location.map(
                                (locationArr, index) => (
                                  <option value={locationArr.locationID}>
                                    {locationArr.location}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-9  col-sm-6">
                          <div className="form-group">
                            <label className="d-inline-block bold_text mr-5 pb-3 white-text col-md-1">
                              Category:
                            </label>
                            <select
                              className="md-form drpdownList mdb-select white-bg col-md-11 custom_select cat-dropdown"
                              name="category"
                              onChange={this.handleChange}
                              required
                            >
                              <option value="">Select</option>
                              {this.state.filters.category.map(
                                (categoryArr, index) => (
                                  <option value={categoryArr.categoryID}>
                                    {categoryArr.category}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                        </div>
                        {/* <div className="col-md-8">
                                                    <div className="form-group">
                                                        <label className="d-inline-block bold_text pr-3 pb-3 white-text">
                                                            Code Description:
                                                        </label>
                                                        <select
                                                            className="md-form drpdownList mdb-select white-bg select-70-width"
                                                            name="codeDescription"
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                            required
                                                        >
                                                            <option value="">
                                                                Select
                                                            </option>
                                                            {this.state.filters.codeDescription.map(
                                                                (
                                                                    codeArr,
                                                                    index
                                                                ) => (
                                                                    <option
                                                                        value={
                                                                            codeArr
                                                                        }
                                                                    >
                                                                        {
                                                                            codeArr
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>*/}
                        <div className="col-md-3">
                          <div className="form-group pull-right">
                            <button
                              className="exportBTN btn-orange e-control text-rightmt-0 e-btn e-lib e-flat e-primary"
                              onClick={this.handleFilterSubmit}
                            >
                              Apply Filters
                            </button>
                          </div>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="container ratesPivotTable">
              {this.state.isLoaded && (
                <>
                  <div className="row">
                    <div
                      className="col-lg-12 property-section"
                      style={{ paddingRight: 0 }}
                    >
                      <ButtonComponent
                        onClick={this.handleExportClick.bind(this)}
                        cssclassName="e-flat"
                        isPrimary={true}
                        className="exportBTN btn-orange"
                      >
                        Download <i className="fa fa-download ml-2"></i>
                      </ButtonComponent>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-12">
                      <PivotViewComponent
                        id="PivotViewFieldList"
                        ref={(d) => (this.pivotObj = d)}
                        enginePopulated={this.afterPivotPopulate.bind(this)}
                        allowExcelExport={true}
                        allowPdfExport={true}
                        enableValueSorting={true}
                        width={"100%"}
                        height={"730"}
                        gridSettings={{
                          columnWidth: 140,
                        }}
                      ></PivotViewComponent>
                      <PivotFieldListComponent
                        id="PivotFieldList"
                        ref={(d) => (this.fieldlistObj = d)}
                        enginePopulated={this.afterPopulate.bind(this)}
                        dataSourceSettings={this.state.dataSourceSettings}
                        renderMode={"Fixed"}
                        allowCalculatedField={false}
                      >
                        <Inject services={[CalculatedField]} />
                      </PivotFieldListComponent>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </main>
      </>
    );
  }

  afterPopulate() {
    this.pivotObj =
      document.getElementById("PivotViewFieldList").ej2_instances[0];
    this.fieldlistObj =
      document.getElementById("PivotFieldList").ej2_instances[0];
    this.fieldlistObj.updateView(this.pivotObj);

    this.setState({
      showLoader: false,
      dataSourceSettings: "",
    });
  }
  afterPivotPopulate(args) {
    this.pivotObj =
      document.getElementById("PivotViewFieldList").ej2_instances[0];
    this.fieldlistObj =
      document.getElementById("PivotFieldList").ej2_instances[0];
    this.fieldlistObj.update(this.pivotObj);
  }
  rendereComplete() {
    this.fieldlistObj.updateView(this.pivotObj);
  }
  ondataBound() {
    this.pivotObj.tooltip.destroy();
    if (Browser.isDevice) {
      this.pivotObj.element.style.width = "100%";
      this.pivotObj.allowCalculatedField = true;
      this.pivotObj.showFieldList = true;
    }
    this.pivotObj.refresh();
  }
  onLoad() {
    if (Browser.isDevice) {
      this.renderMode = "Popup";
      this.target = ".control-section";
      setStyleAttribute(document.getElementById("PivotFieldList"), {
        width: 0,
        height: 0,
        float: "left",
        display: "none",
      });
    }
  }
}
export default withRouter(PivotTable);
