import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import queryString from "query-string";
import BeforeLoginMenu from "../../routes/BeforeLoginMenu";
import AfterLoginMenu from "../../routes/AfterLoginMenu";

class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            token: localStorage.getItem("token"),
            newPassword: "",
            confirmPassword: "",
            email: "",
            errorMessage: "",
            successMessage: "",
            errors: {
                password: "This field is required",
                confirmPassword: "This field is required",
            },
            isValid: false,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    validate(event) {
        const { name, value } = event.target;
        const errors = this.state.errors;

        switch (name) {
            case "newPassword":
                var passErrors = [];

                if (value.search(/[A-Z]/) < 0) {
                    passErrors.push(
                        "Password must have at least one uppercase ('A'-'Z')."
                    );
                }
                if (value.search(/[a-z]/) < 0) {
                    passErrors.push(
                        "Password must have at least one lowercase ('a'-'z')."
                    );
                }
                if (value.search(/[0-9]/) < 0) {
                    passErrors.push(
                        "Password must have at least one digit ('0'-'9')."
                    );
                }
                if (value.search(/[!@#$%^&*]/) < 0) {
                    passErrors.push(
                        "Password must have at least one special character."
                    );
                }

                if (passErrors.length > 0) {
                    errors.password = passErrors.join("\n\t");
                } else {
                    errors.password = "";
                }
                break;
            case "confirmPassword":
                var password = this.state.newPassword;
                if (password !== value) {
                    errors.confirmPassword =
                        "Password and Confirm Password must be same.";
                } else {
                    errors.confirmPassword = "";
                }
                break;
            default:
                break;
        }
        this.setState({
            errors,
            [name]: value,
        });
        if (
            errors.password.length === 0 &&
            errors.confirmPassword.length === 0
        ) {
            this.setState({
                isValid: true,
            });
        } else {
            this.setState({
                isValid: false,
            });
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        const { newPassword, confirmPassword } = this.state;
        const { email, token } = queryString.parse(this.props.location.search);

        if (newPassword !== confirmPassword) {
            this.setState({
                errorMessage: "New Password and Confirm Password are not same.",
            });
        } else {
            this.setState({
                isValid: false,
            });
            axios
                .post(
                    `${process.env.REACT_APP_API_URL}api/auth/ResetPassword`,
                    {
                        NewPassword: newPassword,
                        ConfirmPassword: confirmPassword,
                        Email: email,
                        Token: token,
                    }
                )
                .then((response) => {
                    if (response.data) {
                        this.setState({
                            successMessage: response.data.message,
                            errorMessage: "",
                            newPassword: "",
                            confirmPassword: "",
                        });
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        this.setState({
                            errorMessage: error.response.data.message,
                            successMessage: "",
                            newPassword: "",
                            confirmPassword: "",
                        });
                    }
                });
        }
    }

    render() {
        return (
            <>
                {this.state.token !== null ? (
                    <AfterLoginMenu />
                ) : (
                    <BeforeLoginMenu />
                )}
                <main role="main">
                    <div className="login_bg">
                        <div className="pt-5 pb-5">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-md-6 mx-auto text-center bg-orange">
                                        <div className="login-wrap p-4 p-md-5">
                                            <h3 className="page-title text-white mb-5">
                                                Reset Password
                                            </h3>
                                            {this.state.errorMessage !== "" && (
                                                <div
                                                    className="alert alert-danger alert-dismissible fade show"
                                                    role="alert"
                                                >
                                                    {this.state.errorMessage}
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="alert"
                                                        aria-label="Close"
                                                    ></button>
                                                </div>
                                            )}
                                            {this.state.successMessage !==
                                                "" && (
                                                <div
                                                    className="alert alert-success alert-dismissible fade show"
                                                    role="alert"
                                                >
                                                    {this.state.successMessage}
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="alert"
                                                        aria-label="Close"
                                                    >
                                                        <span
                                                            aria-hidden="true"
                                                            onClick={
                                                                this.handleClose
                                                            }
                                                        >
                                                            &times;
                                                        </span>
                                                    </button>
                                                </div>
                                            )}
                                            <form
                                                className="login-form"
                                                onSubmit={this.handleSubmit}
                                            >
                                                <div className="form-group">
                                                    <input
                                                        type="password"
                                                        name="newPassword"
                                                        value={
                                                            this.state
                                                                .newPassword
                                                        }
                                                        onChange={(e) => {
                                                            this.handleChange(
                                                                e
                                                            );
                                                            this.validate(e);
                                                        }}
                                                        className={
                                                            this.state.errors
                                                                .password &&
                                                            this.state.errors
                                                                .password !==
                                                                "This field is required"
                                                                ? "form-control text-center mb-3 is-invalid"
                                                                : "form-control text-center mb-3"
                                                        }
                                                        placeholder="Enter New Password"
                                                        required
                                                    />
                                                    {this.state.errors
                                                        .password !== "" &&
                                                        this.state.errors
                                                            .password !==
                                                            "This field is required" && (
                                                            <div className="invalid-feedback">
                                                                {
                                                                    this.state
                                                                        .errors
                                                                        .password
                                                                }
                                                            </div>
                                                        )}
                                                </div>
                                                <div className="form-group">
                                                    <input
                                                        type="password"
                                                        name="confirmPassword"
                                                        value={
                                                            this.state
                                                                .confirmPassword
                                                        }
                                                        onChange={(e) => {
                                                            this.handleChange(
                                                                e
                                                            );
                                                            this.validate(e);
                                                        }}
                                                        className={
                                                            this.state.errors
                                                                .confirmPassword &&
                                                            this.state.errors
                                                                .confirmPassword !==
                                                                "This field is required"
                                                                ? "form-control text-center mb-3 is-invalid"
                                                                : "form-control text-center mb-3"
                                                        }
                                                        placeholder="Re-enter Password"
                                                        required
                                                    />
                                                    {this.state.errors
                                                        .confirmPassword !==
                                                        "" &&
                                                        this.state.errors
                                                            .confirmPassword !==
                                                            "This field is required" && (
                                                            <div className="invalid-feedback">
                                                                {
                                                                    this.state
                                                                        .errors
                                                                        .confirmPassword
                                                                }
                                                            </div>
                                                        )}
                                                </div>
                                                <div className="form-group">
                                                    <button
                                                        type="submit"
                                                        disabled={
                                                            this.state
                                                                .isValid ===
                                                            false
                                                        }
                                                        className="btn btn-orange mb-2"
                                                    >
                                                        SUBMIT
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default withRouter(ResetPassword);
