import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import BeforeLoginMenu from "../../routes/BeforeLoginMenu";
import AfterLoginMenu from "../../routes/AfterLoginMenu";

class VerifyEmail extends Component {
    constructor(props) {
        super(props);

        const token = localStorage.getItem("token");
        if (!token) {
            this.props.history.push("/login");
        }

        this.state = {
            token: localStorage.getItem("token"),
            isVerify: false,
        };
    }

    refreshToken() {
        const token = localStorage.getItem("token");
        const refreshToken = localStorage.getItem("refreshToken");
        const expired = localStorage.getItem("expired");

        if (token !== null && refreshToken !== null && expired !== null) {
            axios
                .post(
                    `${process.env.REACT_APP_API_URL}api/auth/refreshToken`,
                    {
                        token: token,
                        refreshToken: refreshToken,
                        expiration: expired,
                    }
                )
                .then((response) => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("refreshToken");
                    localStorage.removeItem("expired");
                    localStorage.setItem("token", response.data.token);
                    localStorage.setItem(
                        "refreshToken",
                        response.data.refreshToken
                    );
                    localStorage.setItem("expired", response.data.expiration);
                })
                .catch((error) => {
                    if (
                        error.response.status === 400 ||
                        error.response.status === 401 ||
                        error.response.status === 403
                    ) {
                        // localStorage.removeItem("token");
                        // localStorage.removeItem("email");
                        // localStorage.removeItem("userID");
                        // localStorage.removeItem("userName");
                        // localStorage.removeItem("secret_key");
                        // localStorage.removeItem("refreshToken");
                        // localStorage.removeItem("expired");
                        this.props.history.push("/logout");
                    }
                });
        }
    }

    verifyEmail() {
        var queryString = JSON.stringify({
            Email: localStorage.getItem("email"),
        });
        axios
            .get(
                `${process.env.REACT_APP_API_URL}api/auth/EmailVerifyUserAsync?UserEmail=${queryString}`
                // {
                //     headers: {
                //         Authorization: `Bearer ${this.state.token}`,
                //         secret_key: localStorage.getItem("secret_key"),
                //     },
                // }
            )
            .then((response) => {
                if (response.data.isSuccess === true) {
                    this.setState({
                        isVerify: true,
                    });
                }
            })
            .catch((error) => {
                if (
                    error.response.status === 400 ||
                    error.response.status === 401 ||
                    error.response.status === 403
                ) {
                    // localStorage.removeItem("token");
                    // localStorage.removeItem("email");
                    // localStorage.removeItem("userID");
                    // localStorage.removeItem("userName");
                    // localStorage.removeItem("secret_key");
                    // localStorage.removeItem("refreshToken");
                    // localStorage.removeItem("expired");
                    this.props.history.push("/logout");
                }
            });
    }

    componentDidMount() {
        this.refreshToken();
        this.verifyEmail();
    }

    render() {
        if (this.state.isVerify) {
            this.message = (
                <div
                    className="alert alert-success alert-dismissible fade show"
                    role="alert"
                >
                    An email has been send to your registered email address to
                    verify.
                </div>
            );
        } else {
            this.message = (
                <div
                    className="alert alert-info alert-dismissible fade show"
                    role="alert"
                >
                    Loading...
                </div>
            );
        }
        return (
            <>
                {this.state.token !== null ? (
                    <AfterLoginMenu />
                ) : (
                    <BeforeLoginMenu />
                )}
                <main role="main">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12 col-lg-12">
                                <h1>Verify Email</h1>
                                <div className="card mb-4">
                                    <div className="card-body">
                                        {this.message}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default withRouter(VerifyEmail);
