import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from "../components/pages/Home";
import OurMission from "../components/pages/OurMission";
import Login from "../components/auth/Login";
import InventoryList from "../components/auth/InventoryList";
// import Search from "../components/auth/Search";
import ForgotPassword from "../components/auth/ForgotPassword";
import ResetPassword from "../components/auth/ResetPassword";
import Profile from "../components/account/Profile";
import Registration from "../components/auth/Registration";
import UserList from "../components/account/UserList";
import VerifyEmail from "../components/account/VerifyEmail";
import ConfirmEmail from "../components/account/ConfirmEmail";
import Subscription from "../components/account/Subscription";
import Checkout from "../components/account/Checkout";
import Thankyou from "../components/account/Thankyou";
import PivotTable from "../components/pages/PivotTable";
import OopRates from "../components/pages/OopRates";
import ProductAndServices from "../components/pages/ProductAndServices";
import BeforeLoginMenu from "./BeforeLoginMenu";
import Logout from "../components/auth/Logout";
import TermsandCondition from "../components/pages/TermsandCondition";
import PrivacyPolicy from "../components/pages/PrivacyPolicy";
// import EOBAccuracySurvey from "../components/pages/EOBAccuracySurvey";
import RxWholesaler from "../components/auth/RxWholesaler";
import Donation from "../components/pages/Donation";
import SubscriptionView from "../components/pages/SubscriptionView";
import Partners from "../components/pages/Partners";
import Dashboard from "../components/pages/Dashboard";
import Dashboard_New from "../components/pages/Dashboard";

class AppRoute extends Component {
    constructor(props) {
        super(props);

        this.state = {
            token: localStorage.getItem("token"),
        };
    }
    render() {
        return (
            <Router>
                {/* <div className="loader-bg">
                    <div className="loader"></div>
                </div> */}
                {this.state.token === "" && <BeforeLoginMenu />}

                <Switch>
                    {/* <Route path="/EOBAccuracySurvey" component={EOBAccuracySurvey} /> */}
                    <Route path="/partner" component={Partners} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/terms-condition" component={TermsandCondition} />
                    <Route path="/pivotTable" component={PivotTable} />
                    <Route path="/ooprates" component={OopRates} />
                    <Route path="/confirmEmail" component={ConfirmEmail} />
                    <Route path="/verifyEmail" component={VerifyEmail} />
                    <Route path="/userList" component={UserList} />
                    <Route path="/profile" component={Profile} />
                    <Route path="/resetPassword" component={ResetPassword} />
                    <Route path="/forgotPassword" component={ForgotPassword} />
                    <Route path="/logout" component={Logout} />
                    <Route path="/login" component={Login} />
                    <Route path="/inventoryList" component={InventoryList} />
                    {/* <Route path="/search" component={Search} /> */}
                    <Route path="/registration" component={Registration} />
                    <Route path="/ourMission" component={OurMission} />
                    <Route path="/subscription" component={Subscription} />
                    <Route path="/Checkout" component={Checkout} />
                    <Route path="/thankyou" component={Thankyou} />
                    <Route path="/productandservices" component={ProductAndServices} />
                    <Route path="/rxwholesaler" component={RxWholesaler} />
                    {/* <Route path="/donation" component={Donation} /> */}
                    <Route path="/subscriptionview" component={SubscriptionView} />
                    <Route path="/vindex" component={Dashboard} />
                    {/* <Route path="/metabase" component={Metabase} /> */}
                    <Route path="/" component={Home} />
                    
                </Switch>
            </Router>
        );
    }
}

export default AppRoute;
