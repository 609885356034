import React, { Component } from "react";
import Captcha from 'captcha-image';
import axios from "axios";

class HospitalSurvey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            states: [],
            msa: [],
            hospitals: [],
            successMessage: "",
            errorMessage: "",
            submitInProgress: false,
            User_Email: localStorage.getItem("email") || sessionStorage.getItem("SurveyUserMail"),
            isLogged: localStorage.getItem("token"),
            stateName: "",
            msaID: undefined,
            hospitalId: undefined,
            selectedQus: 1,
            quistions: [
                {
                    qs: "If you are an clinical employee of this hospital would you go here for your care?",
                    name: "Quality_Status",
                    type: "Yes,No"
                },
                {
                    qs: "Did your upfront price match your final price?",
                    name: "Price_Accuracy_Status",
                    type: "Yes,No"
                },
                {
                    qs: "If using a major carrier to pay for your care did you get an out of network bill by any doctor?",
                    name: "OON_Bill_Status",
                    type: "Yes,No"
                },
                {
                    qs: "If paying with cash did hospital give you a good faith estimate?",
                    name: "GFE_Status",
                    type: "Yes,No"
                },
                {
                    qs: "If requested an itemized list of CPT, DRG, Diagnosis codes did they give it to you?",
                    name: "Medical_Codes_Status",
                    type: "Yes,No"
                },
                {
                    qs: "Was your baby delivery Vaginal or C-Section?",
                    name: "Baby_delivery_Status",
                    type: "Vaginal,C-Section"
                },
                {
                    qs: "Did your referring physician provide options outside of hospital or health system?",
                    name: "Referrals_Status",
                    type: "Yes,No"
                },
                {
                    qs: "Did you seek a 2nd opinion on your cancer diagnosis?",
                    name: "Cancer_Status",
                    type: "Yes,No"
                }
            ],
            answers: {
                Quality_Status: undefined,
                Price_Accuracy_Status: undefined,
                OON_Bill_Status: undefined,
                GFE_Status: undefined,
                Medical_Codes_Status: undefined,
                Baby_delivery_Status: undefined,
                Referrals_Status: undefined,
                Cancer_Status: undefined,
                Heart_attack_Status: undefined
            },
            CaptchaImage: undefined,
            Captchavalue: '',
            InvalidCaptcha: false,
        };


        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    createMarkup() {
        const captchaImage = new Captcha(
            '20px Arial',
            'center',
            'middle',
            105,
            45,
            '#f8f8f8',
            '#000',
            6
        ).createImage();
        this.setState({
            CaptchaImage: captchaImage
        })
        return { __html: captchaImage };
    }

    getAllState() {
        axios
            .get(`${process.env.REACT_APP_API_URL}api/master/getallstates`)
            .then((response) => {
                this.setState({
                    states: Object.values(response.data),
                });
            })
            .catch((error) => {
                if (
                    error.response.status === 400 ||
                    error.response.status === 401 ||
                    error.response.status === 403
                ) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("email");
                    localStorage.removeItem("userID");
                    localStorage.removeItem("userName");
                    localStorage.removeItem("secret_key");
                    localStorage.removeItem("refreshToken");
                    localStorage.removeItem("expired");
                    this.props.history.push("/login");
                }
            });
    }
    getAllMsa() {
        axios
            .get(
                `${process.env.REACT_APP_API_URL}api/inventory/getmsabystatename?state={stateName:''}`,
                {
                    headers: {
                        Authorization: `Bearer ${this.state.token}`,
                        secret_key: localStorage.getItem("secret_key")
                    },
                }
            )
            .then((response) => {
                this.setState({
                    msa: Object.values(response.data),
                });
            })
            .catch((error) => {
                if (
                    error.response.status === 400 ||
                    error.response.status === 401 ||
                    error.response.status === 403
                ) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("email");
                    localStorage.removeItem("userID");
                    localStorage.removeItem("userName");
                    localStorage.removeItem("secret_key");
                    localStorage.removeItem("refreshToken");
                    localStorage.removeItem("expired");
                    this.props.history.push("/login");
                }
            });
    }

    handleChange(event) {
        // console.log(event.target.name,event.target.value)
        const name = event.target.name;
        let answers = this.state.answers;

        answers[name] = event.target.value
        this.setState({
            answers: answers
        })
    }

    handleStateChange(event) {
        this.setState({
            stateName: event.target.value,
            msa: [],
            msaID: undefined
        });
        axios
            .get(
                `${process.env.REACT_APP_API_URL}api/inventory/getmsabystatename?state={stateName:'${event.target.value}'}`
            )
            .then((response) => {
                this.setState({
                    msa: Object.values(response.data),
                });
            })
            .catch((error) => {
                if (
                    error.response.status === 400 ||
                    error.response.status === 401 ||
                    error.response.status === 403
                ) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("email");
                    localStorage.removeItem("userID");
                    localStorage.removeItem("userName");
                    localStorage.removeItem("secret_key");
                    localStorage.removeItem("refreshToken");
                    localStorage.removeItem("expired");
                    this.props.history.push("/login");
                }
            });

    }

    handleMsaChange(event) {
        this.setState({
            msaID: event.target.value,
            hospitals: [],
            hospitalId: undefined,
        });
        axios
            .get(
                `${process.env.REACT_APP_API_URL}api/inventory/getHospitalbyFilter?FilterJson={stateName:"${this.state.stateName}",msaId:"${+event.target.value}"}`
            )
            .then((response) => {
                this.setState({
                    hospitals: Object.values(response.data),
                });
            })
            .catch((error) => {
                if (
                    error.response.status === 400 ||
                    error.response.status === 401 ||
                    error.response.status === 403
                ) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("email");
                    localStorage.removeItem("userID");
                    localStorage.removeItem("userName");
                    localStorage.removeItem("secret_key");
                    localStorage.removeItem("refreshToken");
                    localStorage.removeItem("expired");
                    this.props.history.push("/login");
                }
            });

    }

    convertYesNotoBool(val) {
        switch (val) {
            case 'Yes':
                return true;
            case 'No':
                return false;
            default:
                return val;
        }
    }

    getHospitalName(id) {
        const fdata = this.state.hospitals.filter((i) => i.hospitalID === +id);
        return fdata[0].facilityName;
    }

    handleSubmit(event) {
        event.preventDefault();
        let hospId = +this.state.hospitalId;
        const element = document.querySelector('.captcha img').getAttribute("data-key");
        if (element !== this.state.Captchavalue) {
            this.setState({
                InvalidCaptcha: true
            })
        } else {
            this.setState({
                submitInProgress: true
            })
            const data = {
                HospitalID: +hospId,
                Hospital_Name: this.getHospitalName(hospId),
                User_Email: this.state.User_Email,
                Quality_Status: this.state.answers.Quality_Status ? this.convertYesNotoBool(this.state.answers.Quality_Status) : null,
                Price_Accuracy_Status:this.state.answers.Price_Accuracy_Status ? this.convertYesNotoBool(this.state.answers.Price_Accuracy_Status) : null,
                OON_Bill_Status:this.state.answers.OON_Bill_Status? this.convertYesNotoBool(this.state.answers.OON_Bill_Status) : null,
                GFE_Status:this.state.answers.GFE_Status? this.convertYesNotoBool(this.state.answers.GFE_Status) : null,
                Medical_Codes_Status:this.state.answers.Medical_Codes_Status? this.convertYesNotoBool(this.state.answers.Medical_Codes_Status) : null,
                Baby_delivery_Status:this.state.answers.Baby_delivery_Status? this.convertYesNotoBool(this.state.answers.Baby_delivery_Status) : null,
                Referrals_Status:this.state.answers.Referrals_Status? this.convertYesNotoBool(this.state.answers.Referrals_Status) : null,
                Cancer_Status:this.state.answers.Cancer_Status? this.convertYesNotoBool(this.state.answers.Cancer_Status) : null
                //Heart_attack_Status: this.convertYesNotoBool(this.state.answers.Heart_attack_Status)
            }
            axios
                .post(
                    `${process.env.REACT_APP_API_URL}api/Inventory/hospitalSurveyAddData`, data
                )
                .then((response) => {
                    let ans = {
                        Quality_Status: undefined,
                        Price_Accuracy_Status: undefined,
                        OON_Bill_Status: undefined,
                        GFE_Status: undefined,
                        Medical_Codes_Status: undefined,
                        Baby_delivery_Status: undefined,
                        Referrals_Status: undefined,
                        Cancer_Status: undefined
                    };
                    this.setState({
                        submitInProgress: false,
                        Captchavalue: '',
                        successMessage: '',
                        errorMessage: '',
                        answers: ans,
                        hospitalId:"",
                        stateName:"",
                        msaID:"",
                        InvalidCaptcha: false
                    });
                    this.createMarkup();
                    this.props.parentCallback(response.data);

                })
                .catch((error) => {
                    
                    this.setState({
                        submitInProgress: false,
                        errorMessage: error?.message,
                    });
                    this.createMarkup();
                });
        }

    }

    componentDidMount() {
        this.getAllState();
        this.getAllMsa();
    }


    render() {
        return (
            <>
                <form onSubmit={(e) => this.handleSubmit(e)}>
                    <div className="row">
                        <div className="col-md-6 col-12">
                            <label className="bold_text">
                                State Name:
                            </label>
                            <select
                                className="md-form mdb-select white-bg form-control w-100"
                                name="stateName"
                                onChange={(e) => this.handleStateChange(e)}
                                required
                                value={this.state.stateName}
                            >
                                <option value="">Select State</option>
                                {
                                    this.state.states[0]?.stateName !== undefined ?
                                        this.state.states
                                            .sort((a, b) => a.stateName.localeCompare(b.stateName))
                                            .map((stateArr, index) => (
                                                <option
                                                    value={
                                                        stateArr.stateName
                                                    }
                                                    key={index}
                                                >
                                                    {`${stateArr.stateName} (${stateArr.stateCode})`}
                                                </option>
                                            ))
                                        : ''
                                }
                            </select>
                        </div>

                        <div className="col-md-6 col-12">
                            <label className="bold_text">
                                Metro Area:
                            </label>
                            <select
                                className="md-form mdb-select white-bg form-control w-100"
                                name="selectMsa"
                                onChange={(e) => this.handleMsaChange(e)}
                                required
                                value={this.state.msaID}
                                disabled={this.state.stateName === ''}
                            >
                                <option value="">Select Metro Area</option>
                                {
                                    this.state.msa !== undefined ?
                                        this.state.msa.map((msaArr, index) => (
                                            <option value={msaArr.msaID} key={index}>{msaArr.msaName}</option>
                                        ))
                                        : ''
                                }
                            </select>
                        </div>

                        <div className="col-12 my-3">
                            <label className="bold_text">
                                Hospital Name:
                            </label>
                            <select
                                className="md-form mdb-select white-bg form-control w-100"
                                name="selectMsa"
                                onChange={(e) => this.setState({ hospitalId: e.target.value })}
                                required
                                value={this.state.hospitalId}
                                disabled={this.state.stateName === '' || this.state.msaID === undefined}
                            >
                                <option value="">Select Hospital Name</option>
                                {
                                    this.state.hospitals !== undefined ?
                                        this.state.hospitals.map((hospitalArr, index) => (
                                            <option value={hospitalArr.hospitalID} key={index}>{hospitalArr.facilityName}</option>
                                        ))
                                        : ''
                                }
                            </select>
                        </div>

                        {/* <div className="col-md-6 col-12 my-3">
                            <label className="bold_text">
                                Email:
                            </label>
                            <input type="text" className="form-control" value={this.state.User_Email} onChange={(e) => this.setState({
                                User_Email: e.target.value
                            })}
                                disabled
                                placeholder="Enter Your Email"></input>
                        </div> */}
                        <div className="col-12">
                            <ul className="hospital_survey_question_wrap">
                                {
                                    this.state.quistions.map((msaArr, index) => (
                                        <li key={index}>
                                            <h3>{msaArr.qs}</h3>

                                            <div className="custom-control custom-radio custom-control-inline">

                                                <input type="radio" id={`radioOne${index}`}
                                                    name={msaArr.name}
                                                    value={msaArr.type.split(',')[0]}
                                                    className="custom-control-input"
                                                    checked={this.state.answers[msaArr.name] === msaArr.type.split(',')[0]}
                                                    onChange={(e) => this.handleChange(e)} />

                                                <label className="custom-control-label" htmlFor={`radioOne${index}`}>{msaArr.type.split(',')[0]}</label>

                                            </div>

                                            <div className="custom-control custom-radio custom-control-inline">

                                                <input type="radio" id={`radioTwo${index}`}
                                                    name={msaArr.name}
                                                    className="custom-control-input"
                                                    value={msaArr.type.split(',')[1]}
                                                    checked={this.state.answers[msaArr.name] === msaArr.type.split(',')[1]}
                                                    onChange={(e) => this.handleChange(e)}  />

                                                <label className="custom-control-label" htmlFor={`radioTwo${index}`}>{msaArr.type.split(',')[1]}</label>

                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>

                        <div className="col-md-6 col-12">
                            <div className="input-group mt-3">
                                <div className="input-group-prepend p-0">
                                    <span className="input-group-text  p-0">{
                                        !this.state.CaptchaImage ?
                                            <div dangerouslySetInnerHTML={this.createMarkup()} />
                                            : <><div className="captcha" dangerouslySetInnerHTML={{ __html: this.state.CaptchaImage }} /></>
                                    }</span>
                                    <button className="btn" type="button" onClick={() => this.setState({
                                        CaptchaImage: undefined
                                    })}><i className="fa fa-refresh" aria-hidden="true"></i></button>
                                </div>
                                <input type="text" name="captcha" required className="form-control " aria-label="Enter Captcha" placeholder="Enter Captcha" value={this.state.Captchavalue} onChange={(e) => this.setState({
                                    Captchavalue: e.target.value,
                                    InvalidCaptcha: false
                                })}></input>
                            </div>
                            {
                                this.state.InvalidCaptcha ?
                                    <><p className="text-red">Please enter valid Captcha</p></>
                                    : ''
                            }

                        </div>

                        <div className="col-md-6 col-12">
                            <button className="btn btn-orange bannerseachPlaceHoldercolor mr-auto ml-auto d-block mt-3">Submit</button>
                        </div>
                    </div>
                </form>

                {this.state.errorMessage !== "" && (
                    <div
                        className="alert alert-danger alert-dismissible fade show mt-3"
                        role="alert"
                    >
                        {this.state.errorMessage}
                        <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close"
                        ></button>
                    </div>
                )}
                {this.state.successMessage !== "" && (
                    <div
                        className="alert alert-success alert-dismissible fade show mt-3"
                        role="alert"
                    >
                        {this.state.successMessage}
                        <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close"
                        ></button>
                    </div>
                )}


            </>
        );
    }
}
export default HospitalSurvey;
