import React, { Component } from "react";
import Captcha from 'captcha-image';
import axios from "axios";

class UserMailOtpVerification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            UserMailNeed: true,
            OtpNeed: false,
            usermail: '',
            userOtp: '',
            isSuccess: false,
            message: "",
            inProgress: false
        };


        this.sendOTP = this.sendOTP.bind(this);
        this.openotpform = this.openotpform.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }


    sendOTP(e) {
        e.preventDefault()
        if (this.state.usermail != null && this.state.userOtp != null) {
            const val1 = document.querySelector(
                `input[name=otp-1]`
            );
            const val2 = document.querySelector(
                `input[name=otp-2]`
            );
            const val3 = document.querySelector(
                `input[name=otp-3]`
            );
            const val4 = document.querySelector(
                `input[name=otp-4]`
            );
            const data = {
                email: this.state.usermail,
                Otp: val1.value.toString() + val2.value.toString() + val3.value.toString() + val4.value.toString()
            }
            this.setState({
                inProgress: true
            });
            axios
                .post(
                    `${process.env.REACT_APP_API_URL}api/Donation/donationUserOtpVerify`, data
                )
                .then((response) => {
                    if (response.data.isSuccess) {
                        sessionStorage.setItem("SurveyUserMail", this.state.usermail)
                        this.props.parentCallback(response.data.isSuccess);
                    }
                    else {
                        this.setState({
                            isSuccess: false,
                            message: response.data.message,
                            inProgress: false
                        });
                    }

                })
                .catch((error) => {
                    this.setState({
                        isSuccess: false,
                        inProgress: false,
                        message: "Mail not Sent!!"
                    });
                });
        }
    }

    openotpform(e) {
        e.preventDefault();
        const data = {
            email: this.state.usermail,
            Otp: ""
        }
        this.setState({
            inProgress: true
        });
        axios
            .post(
                `${process.env.REACT_APP_API_URL}api/Donation/surveyUserSendOtp`, data
            )
            .then((response) => {
                if (response.data.isSuccess) {
                    this.setState(prevState => ({
                        UserMailNeed: !prevState.UserMailNeed,
                        OtpNeed: !prevState.OtpNeed,
                        inProgress: false
                    }));
                }
                else {
                    this.setState({
                        isSuccess: false,
                        message: response.data.message,
                        inProgress: false
                    });
                }

            })
            .catch((error) => {
                this.setState({
                    isSuccess: false,
                    inProgress: false,
                    message: "Mail not Sent!!"
                });
            });
    }

    handleChange(e) {
        const { maxLength, value, name } = e.target;
        const [fieldName, fieldIndex] = name.split("-");
        let fieldIntIndex = parseInt(fieldIndex, 10);
        // Check if no of char in field == maxlength
        if (value.length >= maxLength && !isNaN(+value)) {

            // It should not be last input field

            if (fieldIntIndex < 4) {

                // Get the next input field using it's name
                const nextfield = document.querySelector(
                    `input[name=otp-${fieldIntIndex + 1}]`
                );


                // If found, focus the next field
                if (nextfield !== null) {
                    nextfield.removeAttribute("disabled")
                    nextfield.focus();
                }
            }
        }

    };


    componentDidMount() {
    }


    render() {
        return (
            <>
                {
                    this.state.UserMailNeed && !this.state.OtpNeed ?
                        <>
                            <div className="row align-items-center justify-content-center">
                                <div className="text-center col-md-7 col-12">
                                    <form onSubmit={this.openotpform}>
                                        <input type="email" required placeholder="Enter Your Email" className="form-control text-center mb-3" onChange={(e) => this.setState({ usermail: e.target.value })}></input>
                                        <button type="submit" disabled={this.state.inProgress} className="btn btn-orange mb-2">Send OTP</button>
                                        {
                                            !this.state.isSuccess && this.state.message != "" ?
                                                <div className="row justify-content-center"><div className="col-md-3"></div><div className="col-md-6"><div className="alert alert-danger alert-dismissible fade show" role="alert">
                                                    {this.state.message}
                                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.alertChange}>
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div></div><div className="col-md-3"></div></div> : ''
                                        }
                                    </form>
                                </div>
                            </div>

                        </>
                        :
                        <>
                            <div className="row align-items-center justify-content-center">
                                <div className="text-center col-md-7 col-12">
                                    <p>&nbsp;&nbsp;&nbsp;Enter Your OTP</p>
                                    <form onSubmit={this.sendOTP}>
                                        <div className="d-flex mt-2">
                                            <div className="col-sm-3">
                                                <input type="tel" required maxLength="1" minLength="1" pattern="[0-9]" onChange={(e) => this.handleChange(e)} name="otp-1" placeholder="0" className="form-control text-center"></input>
                                            </div>
                                            <div className="col-sm-3">
                                                <input type="tel" required maxLength="1" minLength="1" pattern="[0-9]" onChange={(e) => this.handleChange(e)} name="otp-2" disabled placeholder="0" className="form-control text-center"></input>
                                            </div>
                                            <div className="col-sm-3">
                                                <input type="tel" required maxLength="1" minLength="1" pattern="[0-9]" onChange={(e) => this.handleChange(e)} name="otp-3" disabled placeholder="0" className="form-control text-center"></input>
                                            </div>
                                            <div className="col-sm-3">
                                                <input type="tel" required maxLength="1" minLength="1" pattern="[0-9]" onChange={(e) => this.handleChange(e)} name="otp-4" disabled placeholder="0" className="form-control text-center"></input>
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-orange mt-3">Verify OTP</button>
                                        {
                                            !this.state.isSuccess && this.state.message != "" ?
                                                <div className="row justify-content-center mt-2"><div className="col-12"><div className="alert alert-danger alert-dismissible fade show" role="alert">
                                                    Please enter valid OTP
                                                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.alertChange}>
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div></div></div> : ''
                                        }
                                    </form>
                                </div>
                            </div>
                        </>
                }


                {/* {this.state.errorMessage !== "" && (
                    <div
                        className="alert alert-danger alert-dismissible fade show mt-3"
                        role="alert"
                    >
                        {this.state.errorMessage}
                        <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close"
                        ></button>
                    </div>
                )}
                {this.state.successMessage !== "" && (
                    <div
                        className="alert alert-success alert-dismissible fade show mt-3"
                        role="alert"
                    >
                        {this.state.successMessage}
                        <button
                            type="button"
                            className="close"
                            data-dismiss="alert"
                            aria-label="Close"
                        ></button>
                    </div>
                )} */}


            </>
        );
    }
}
export default UserMailOtpVerification;
