import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import BeforeLoginMenu from "../../routes/BeforeLoginMenu";
import AfterLoginMenu from "../../routes/AfterLoginMenu";
import axios from "axios";

class Checkout extends Component {
    constructor(props) {
        super(props);
        if (process.env.NODE_ENV !== 'development') {
            document.addEventListener('contextmenu', (e) => {
                e.preventDefault();
            }
            );
            document.onkeydown = function (e) {
                if (e.keyCode == 123) {
                    return false;
                }
                if (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
                    return false;
                }
                if (e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
                    return false;
                }
                if (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
                    return false;
                }
                if (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
                    return false;
                }
            }
        }
        const token = localStorage.getItem("token");
        this.ref = React.createRef()
        this.state = {
            token: token,
            items: [],
            paymentType: "card",
            cardType: "",
            cardName: "",
            cardNumber: "",
            expiryDate: "",
            cvv: "",
            routingNumber: "",
            accountNumber: "",
            accountName: "",
            bankName: "",
            accountType: "",
            firstName: "",
            lastName: "",
            address: "",
            city: "",
            state: "",
            zipcode: "",
            successMessage: "",
            errorMessage: "",
            coupons: [],
            paymentInProgress: false,
            DicountedAMount: 0,
            instalTotalPrice: null,
            isCouponApplyed:false
        };

        if (!token) {
            this.props.history.push("/login");
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleAddMore = this.handleAddMore.bind(this);
        this.handleCouponChange = this.handleCouponChange.bind(this);
        this.DeleteCart = this.DeleteCart.bind(this);



    }

    handleCouponChange(event) {
        this.setState({
            errorMessage: '',

        });
        //var couponName = event.target.value;
        var couponName = this.state.couponname;
        console.log('name' + couponName);
        //if(couponName!=undefined)
        //{
        var percentage = 0;
        let hasAnnual = this.checkHasAnnualSubsc()
        this.setState({
            isCouponApplyed: hasAnnual
        });
        if (!hasAnnual) {this.setState({
            errorMessage: 'Coupon code only applicable for annual subscriptions',
        });
        return;}
        axios
            .get(
                `${process.env.REACT_APP_API_URL}api/Master/getallcoupons?cupon=${couponName}`,
                {}
            )
            .then((response) => {
                console.log(response.data.length)
                if (response.data.length > 0) {
                    percentage = response.data[0].couponDiscountPerc;
                    localStorage.setItem("CouponPer", percentage);
                    var finaldata = JSON.parse(localStorage.getItem("SubscriptionData"));
                    let i = 0;
                    let TotalAMount = 0;
                    var dicountedAmount = 0;
                    for (i = 0; i < finaldata.length; i++) {
                        if (finaldata[i].subscriptionType == 'Annual') {
                            let ids = JSON.parse(localStorage.getItem("subsId"));
                            finaldata[i].actualPrice = finaldata[i].totalPrice - (finaldata[i].totalPrice * percentage / 100);
                            if (ids !== null && finaldata[i].subscriptionID === ids[i]?.subid) {
                                dicountedAmount = dicountedAmount + (finaldata[i].totalPrice * percentage / 100) / 12;
                            } else {
                                dicountedAmount = dicountedAmount + (finaldata[i].totalPrice * percentage / 100);
                            }
                        }
                        else {
                            finaldata[i].actualPrice = finaldata[i].totalPrice;
                        }
                        TotalAMount += finaldata[i].actualPrice;
                        finaldata[i].userID = localStorage.getItem("userID");
                    }
                    this.setState({
                        items: finaldata,
                        totalAMount: TotalAMount,
                        // selectedCouponValue : couponID,
                        CouponName: couponName,
                        DicountedAMount: dicountedAmount,
                    });
                    localStorage.setItem("SubscriptionData", JSON.stringify(finaldata));
                    this.calculateTotal();
                }
                else {
                    localStorage.setItem("CouponPer", percentage);
                    var finaldata = JSON.parse(localStorage.getItem("SubscriptionData"));
                    let i = 0;
                    let TotalAMount = 0;
                    var dicountedAmount = 0;
                    for (i = 0; i < finaldata.length; i++) {
                        if (finaldata[i].subscriptionType == 'Annual') {
                            let ids = JSON.parse(localStorage.getItem("subsId"));
                            finaldata[i].actualPrice = finaldata[i].totalPrice - (finaldata[i].totalPrice * percentage / 100);
                            // console.log(ids)
                            if (ids !== null && finaldata[i]?.subscriptionID === ids[i]?.subid) {
                                dicountedAmount = dicountedAmount + (finaldata[i].totalPrice * percentage / 100) / 12;
                            }
                            else {
                                dicountedAmount = dicountedAmount + (finaldata[i].totalPrice * percentage / 100);
                            }


                        }
                        else {
                            finaldata[i].actualPrice = finaldata[i].totalPrice;
                        }
                        TotalAMount += finaldata[i].actualPrice;
                        finaldata[i].userID = localStorage.getItem("userID");
                    }
                    this.setState({
                        items: finaldata,
                        totalAMount: TotalAMount,
                        // selectedCouponValue : couponID,
                        CouponName: null,
                        DicountedAMount: dicountedAmount,
                    });
                    localStorage.setItem("SubscriptionData", JSON.stringify(finaldata));
                    this.setState({

                        errorMessage: 'Invalid coupon code or coupon is expired',

                    });

                    this.calculateTotal();

                }

            })
            .catch((error) => {
                console.log(error)
                if (
                    error.response.status === 400 ||
                    error.response.status === 401 ||
                    error.response.status === 403
                ) {

                }
            });

        //}


    }


    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    handleAddMore(event) {
        this.props.history.push("/subscription");
    }

    refreshToken() {
        const token = localStorage.getItem("token");
        const refreshToken = localStorage.getItem("refreshToken");
        const expired = localStorage.getItem("expired");

        if (token !== null && refreshToken !== null && expired !== null) {
            axios
                .post(
                    `${process.env.REACT_APP_API_URL}api/auth/refreshToken`,
                    {
                        token: token,
                        refreshToken: refreshToken,
                        expiration: expired,
                    }
                )
                .then((response) => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("refreshToken");
                    localStorage.removeItem("expired");
                    localStorage.setItem("token", response.data.token);
                    localStorage.setItem(
                        "refreshToken",
                        response.data.refreshToken
                    );
                    localStorage.setItem("expired", response.data.expiration);
                })
                .catch((error) => {
                    if (
                        error.response.status === 400 ||
                        error.response.status === 401 ||
                        error.response.status === 403
                    ) {
                        localStorage.removeItem("token");
                        localStorage.removeItem("email");
                        localStorage.removeItem("userID");
                        localStorage.removeItem("userName");
                        localStorage.removeItem("secret_key");
                        localStorage.removeItem("refreshToken");
                        localStorage.removeItem("expired");
                        localStorage.removeItem("rxWholesaler");
                        this.props.history.push("/login");
                    }
                });
        }
    }

    getSubscriptionDetails() {
        const subscriptionId = sessionStorage.getItem("subscriptionId");
        const stateId = sessionStorage.getItem("selectedState");
        console.log(subscriptionId)
        console.log(stateId)
        localStorage.removeItem("CouponPer");

        if (subscriptionId != null) {
            axios
                .get(
                    `${process.env.REACT_APP_API_URL}api/Master/getsubscriptionbyid?stateId=${stateId}&subscriptionid=${subscriptionId}`,
                    {}
                )
                .then((response) => {

                    var data = localStorage.getItem("SubscriptionData");
                    let ExistingsubscriptionData;
                    let Extended;
                    let TotalAMount = 0;
                    let flag = true;
                    //console.log(response.data)
                    if (data != null) {
                        ExistingsubscriptionData = JSON.parse(localStorage.getItem("SubscriptionData"));
                        let m = 0;
                        for (m = 0; m < response.data.length; m++) {
                            let n = 0;
                            for (n = 0; n < ExistingsubscriptionData.length; n++) {
                                if (response.data[m].subscriptionID == ExistingsubscriptionData[n].subscriptionID && response.data[m].subscriptionType == ExistingsubscriptionData[n].subscriptionType && response.data[m].filterStates == ExistingsubscriptionData[n].filterStates) {
                                    flag = false;
                                    this.setState({
                                        errorMessage: 'Subscription is already added to the list',

                                    });
                                    break;
                                }

                            }
                        }
                        if (flag == true) {
                            // console.log(flag)
                            Extended = ExistingsubscriptionData.concat(response.data);
                            localStorage.setItem("SubscriptionData", JSON.stringify(Extended));
                            this.calculateTotal()
                        }
                    }
                    else {

                        localStorage.setItem("SubscriptionData", JSON.stringify(response.data));
                        this.calculateTotal()

                    }
                    var finaldata = JSON.parse(localStorage.getItem("SubscriptionData"));
                    console.log(finaldata)
                    let CouponPer = 0;
                    let couponID = 0;
                    //CouponPer = JSON.parse(localStorage.getItem("CouponPer"));
                    couponID = JSON.parse(localStorage.getItem("couponID", couponID))

                    let i = 0;
                    for (i = 0; i < finaldata.length; i++) {
                        if (finaldata[i].subscriptionType == 'Annual') {
                            finaldata[i].actualPrice = finaldata[i].totalPrice - (finaldata[i].totalPrice * CouponPer / 100);
                            finaldata[i].couponCode = couponID;
                        }
                        else {
                            finaldata[i].actualPrice = finaldata[i].totalPrice;
                        }
                        TotalAMount += finaldata[i].actualPrice;
                        finaldata[i].userID = localStorage.getItem("userID");
                        finaldata[i].row = i;
                    }
                    localStorage.setItem("SubscriptionData", JSON.stringify(finaldata));
                    console.log(finaldata)
                    this.setState({
                        items: finaldata,
                        totalAMount: TotalAMount,
                        selectedCouponValue: couponID,
                    });
                    sessionStorage.removeItem("subscriptionId");
                    sessionStorage.removeItem("selectedState");
                    this.calculateTotal()
                })
                .catch((error) => {
                    console.log(error)
                    if (
                        error.status === 400 ||
                        error.status === 401 ||
                        error.status === 403
                    ) {
                        localStorage.clear()
                        // localStorage.removeItem("token");
                        // localStorage.removeItem("email");
                        // localStorage.removeItem("userID");
                        // localStorage.removeItem("userName");
                        // localStorage.removeItem("secret_key");
                        // localStorage.removeItem("refreshToken");
                        // localStorage.removeItem("expired");
                        this.props.history.push("/login");
                    }
                });
        }
        else {
            var finaldata = JSON.parse(localStorage.getItem("SubscriptionData"));
            console.log(finaldata)
            let CouponPer = 0;
            let couponID = 0;
            let TotalAMount = 0;
            CouponPer = JSON.parse(localStorage.getItem("CouponPer"));
            couponID = JSON.parse(localStorage.getItem("couponID", couponID))

            let i = 0;
            for (i = 0; i < finaldata.length; i++) {
                if (finaldata[i].subscriptionType == 'Annual') {
                    finaldata[i].actualPrice = finaldata[i].totalPrice - (finaldata[i].totalPrice * CouponPer / 100);
                    finaldata[i].couponCode = couponID;
                }
                else {
                    finaldata[i].actualPrice = finaldata[i].totalPrice;
                }
                TotalAMount += finaldata[i].actualPrice;
                finaldata[i].row = i;
                finaldata[i].userID = localStorage.getItem("userID");
            }
            localStorage.setItem("SubscriptionData", JSON.stringify(finaldata));
            this.setState({
                items: finaldata,
                totalAMount: TotalAMount,
                selectedCouponValue: couponID,
            });
            this.calculateTotal()
        }
    }


    DeleteCart(id, type, state, row) {
        this.setState({
            errorMessage: '',

        });
        //console.log(row)


        var data = JSON.parse(localStorage.getItem("SubscriptionData"));
        // console.log(data)
        //var filteredArray = data.filter(item => item.subscriptionID != id && item.subscriptionType!=type && item.filterStates!=state)
        // var filteredArray = data.filter((item => item.subscriptionID != id) && (item => item.subscriptionType!=type) && (item => item.filterStates!=state));
        var filteredArray = data.filter(item => item.row != row);
        console.log(filteredArray)
        let TotalAMount = 0;
        var dicountedAmount = 0;
        var percentage = localStorage.getItem("CouponPer");
        let i = 0;
        for (i = 0; i < filteredArray.length; i++) {
            if (filteredArray[i].subscriptionType == 'Annual') {
                filteredArray[i].actualPrice = filteredArray[i].totalPrice - (filteredArray[i].totalPrice * percentage / 100);
                dicountedAmount = dicountedAmount + (filteredArray[i].totalPrice * percentage / 100);
            }
            else {
                filteredArray[i].actualPrice = filteredArray[i].totalPrice;
            }
            TotalAMount += filteredArray[i].actualPrice;
            filteredArray[i].userID = localStorage.getItem("userID");
        }
        console.log(dicountedAmount)
        this.setState({
            items: filteredArray,
            totalAMount: TotalAMount,
            DicountedAMount: dicountedAmount,

        });
        localStorage.setItem("SubscriptionData", JSON.stringify(filteredArray));
        // this.setState({
        //     paymentType: type,
        // });
        let ids = JSON.parse(localStorage.getItem("subsId"));
        const index = ids?.findIndex(element => element.subid === id && element.state === state || element.subid === id && state === 'All States');
        let allState = JSON.parse(localStorage.getItem("subsIdAllState"));
        let aindex;
        if (allState !== undefined && allState !== null) {
            aindex = Object.keys(allState).some(function (k) {
                return allState.subid === id;
            });
        }


        if (index !== -1 && index !== undefined) {
            ids.splice(index, 1);
            localStorage.setItem('subsId', JSON.stringify(ids));
        }
        if (aindex != false && aindex !== undefined) {
            localStorage.removeItem('subsIdAllState');
        }
        this.calculateTotal()
    }

    handlePaymentChange(type) {
        this.setState({
            paymentType: type,
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ paymentInProgress: true })
        const {
            items,
            paymentType,
            cardType,
            cardName,
            cardNumber,
            expiryDate,
            cvv,
            routingNumber,
            accountNumber,
            accountName,
            bankName,
            accountType,
            firstName,
            lastName,
            address,
            city,
            state,
            zipcode,
            email,
        } = this.state;
        var data = JSON.parse(localStorage.getItem("SubscriptionData"));
        let inst = localStorage.getItem("IsInstalmentPayment");
        let id = JSON.parse(localStorage.getItem("subsId"));
        // var result = data.filter(function (item) {
        //     return !id.some(function (el) {
        //         return el.subid === item.subscriptionID && el.state === item.filterStates
        //     })
        // });
        var result = data.filter(el => {
            return !id?.find(element => {
                return element.subid === el.subscriptionID && element.state === el.filterStates;
            });
        });
        console.log(result)
        for (let i = 0; i < result.length; i++) {
            let dIndx = data.findIndex(x => x.subscriptionID == result[i].subscriptionID && x.filterStates == result[i].filterStates);
            console.log(data[dIndx])
            data[dIndx].IsInstalmentPayment = false;
            data[dIndx].MaintenanceCharges = 0;
            data[dIndx].InstallmentAmount = 0;

        }

        if (inst) {
            let id = JSON.parse(localStorage.getItem("subsId"));
            let allState = JSON.parse(localStorage.getItem("subsIdAllState"));

            for (let i = 0; i < id.length; i++) {
                let dIndx = data.findIndex(x => x.subscriptionID == id[i].subid && x.filterStates == id[i].state);
                console.log(dIndx)
                if (dIndx !== -1) {
                    const IsInstalmentPayment = JSON.parse(localStorage.getItem("IsInstalmentPayment"));
                    const MaintenanceCharges = JSON.parse(localStorage.getItem("MaintenanceCharges"));
                    data[dIndx].IsInstalmentPayment = IsInstalmentPayment;
                    data[dIndx].MaintenanceCharges = MaintenanceCharges;
                    data[dIndx].InstallmentAmount = +parseFloat(data[dIndx].actualPrice / 12 + MaintenanceCharges).toFixed(2);
                }

            }

            if (allState !== undefined || allState !== null) {
                for (let i = 0; i < id.length; i++) {
                    let dIndx = data.findIndex(x => x.subscriptionID == allState?.subid && x.filterStates == 'All States');
                    console.log(dIndx)
                    if (dIndx !== -1) {
                        const IsInstalmentPayment = JSON.parse(localStorage.getItem("IsInstalmentPayment"));
                        const MaintenanceCharges = JSON.parse(localStorage.getItem("MaintenanceCharges"));
                        data[dIndx].IsInstalmentPayment = IsInstalmentPayment;
                        data[dIndx].MaintenanceCharges = MaintenanceCharges;
                        data[dIndx].InstallmentAmount = +parseFloat(data[dIndx].actualPrice / 12 + MaintenanceCharges).toFixed(2);
                    }

                }
            }
        }

        if (paymentType === "card") {
            const cardArr = {
                // itemscredit: {
                //     userId: localStorage.getItem("userID"),
                //     totalFilesCountAllStates:
                //         this.state.items[0].totalFilesCountAllStates,
                //     filterStates: this.state.items[0].filterStates,
                //     totalFilesCountSpecificState:
                //         this.state.items[0].totalFilesCountSpecificState,
                //     subscriptionID: this.state.items[0].subscriptionID,
                //     subscriptionTitle: this.state.items[0].subscriptionTitle,
                //     subscriptionEndDate:
                //         this.state.items[0].subscriptionEndDate,
                //     subscriptionType: this.state.items[0].subscriptionType,
                //     accessType: this.state.items[0].accessType,
                //     mrmIncluded: this.state.items[0].mrmIncluded,
                //     discountPercentage: this.state.items[0].discountPercentage,
                //     priceDescription: this.state.items[0].priceDescription,
                //     price: this.state.items[0].price,
                //     totalPrice: this.state.items[0].totalPrice,
                //     isActive: this.state.items[0].isActive,
                // },
                itemscredit: { data },
                paymentDetailscredit: {
                    cardType: cardType,
                    cardName: cardName,
                    cardNumber: cardNumber,
                    expiryDate: expiryDate,
                    cvv: cvv
                },
                billingAddresscredit: {
                    firstName: firstName,
                    lastName: lastName,
                    address: address,
                    city: city,
                    state: state,
                    zipcode: zipcode,
                    email: localStorage.getItem("email"),
                },
            };
            console.log(cardArr["itemscredit"]);
            axios
                .post(
                    `${process.env.REACT_APP_API_URL}api/Payment/CardPayment`,
                    {
                        items: cardArr["itemscredit"],
                        paymentDetails: cardArr["paymentDetailscredit"],
                        billingAddress: cardArr["billingAddresscredit"],
                    }
                )
                .then((response) => {
                    // console.log(response.data.messages.message[0].text);
                    // console.log(response.data.responseCode);
                    // if (response.data.messages) {
                    // if (response.data.messages.message[0].text == "Successful.") {
                    if (response.data.transactionResponse.responseCode == 1) {
                        this.setState({
                            successMessage:
                                response.data.messages.message[0].text,
                            paymentInProgress: false
                        });
                        sessionStorage.removeItem("selectedState");
                        sessionStorage.removeItem("subscriptionId");
                        localStorage.removeItem("CouponPer");
                        localStorage.removeItem("SubscriptionData");
                        localStorage.removeItem("couponID");
                        localStorage.removeItem("CouponPer");
                        localStorage.removeItem("subsIdAllState");
                        localStorage.removeItem("IsInstalmentPayment");
                        localStorage.removeItem("MaintenanceCharges");
                        localStorage.removeItem("InstallmenetAmount");
                        localStorage.removeItem("subsId");
                        this.ref.current.click();
                        this.props.history.push("/thankYou");
                    } else {
                        this.ref.current.click()
                        setTimeout(() => {
                            window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                            });
                        }, 800);
                        this.setState({
                            errorMessage:
                                response.data.transactionResponse.errors[0]
                                    .errorText,
                            successMessage: "",
                            paymentInProgress: false
                        });

                    }
                })
                .catch((error) => {
                    // console.log(error)
                    this.ref.current.click()
                    setTimeout(() => {
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                        });
                    }, 800);
                    this.setState({
                        errorMessage: 'Payment request failed. Please provide valid data.',
                        successMessage: "",
                        paymentInProgress: false
                    });

                });

            // console.log(JSON.stringify(cardArr));
            // console.log(JSON.stringify(cardArr["billingAddresscredit"]));
        } else {
            // var data = JSON.parse(localStorage.getItem("SubscriptionData"));
            const echeckArr = {
                items: { data },
                paymentDetails: {
                    routingNumber: routingNumber,
                    accountNumber: accountNumber,
                    accountName: accountName,
                    bankName: bankName,
                    accountType: "",
                },
                billingAddress: {
                    firstName: firstName,
                    lastName: lastName,
                    address: address,
                    city: city,
                    state: state,
                    zipcode: zipcode,
                    email: localStorage.getItem("email")
                },
            };
            console.log(echeckArr["items"],)
            axios
                .post(
                    `${process.env.REACT_APP_API_URL}api/Payment/CeckPayment`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.state.token}`,
                            secret_key: localStorage.getItem("secret_key"),
                        },
                        items: echeckArr["items"],
                        paymentDetails: echeckArr["paymentDetails"],
                        billingAddress: echeckArr["billingAddress"],
                    }
                )
                .then((response) => {
                    //console.log(response.data.transactionResponse.responseCode);
                    // if (response.data.messages.message[0].text == "Successful.") {
                    if (response.data.transactionResponse.responseCode == 1) {

                        this.setState({
                            successMessage:
                                response.data.messages.message[0].text,
                            paymentInProgress: false
                        });
                        this.ref.current.click()
                        sessionStorage.removeItem("selectedState");
                        sessionStorage.removeItem("subscriptionId");
                        localStorage.removeItem("CouponPer");
                        localStorage.removeItem("SubscriptionData");
                        localStorage.removeItem("couponID");
                        localStorage.removeItem("CouponPer");
                        localStorage.removeItem("subsIdAllState");
                        localStorage.removeItem("IsInstalmentPayment");
                        localStorage.removeItem("MaintenanceCharges");
                        localStorage.removeItem("InstallmenetAmount");
                        localStorage.removeItem("subsId");
                        this.props.history.push("/thankYou");
                    } else {
                        this.ref.current.click()
                        setTimeout(() => {
                            window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                            });
                        }, 800);
                        this.setState({
                            errorMessage:
                                response.data.transactionResponse.errors[0]
                                    .errorText,
                            successMessage: "",
                            paymentInProgress: false
                        });

                    }
                })
                .catch((error) => {
                    // console.log(error);
                    this.ref.current.click()
                    setTimeout(() => {
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                        });
                    }, 800);

                    this.setState({
                        errorMessage: 'Payment request failed. Please provide valid data.',
                        successMessage: "",
                        paymentInProgress: false
                    });

                });

            // console.log(JSON.stringify(echeckArr));
        }
    }

    checkHasAnnualSubsc =  () => {
        var data = JSON.parse(localStorage.getItem("SubscriptionData"));

        const found = data?.some(el => el.subscriptionType === "Annual");
        return found
    }

    getCouponCode() {
       
        axios
            .get(`${process.env.REACT_APP_API_URL}api/master/getallcoupons`, {})
            .then((response) => {
                this.setState({
                    coupons: Object.values(response.data),
                });
            })
            .catch((error) => {
                if (
                    error.response.status === 400 ||
                    error.response.status === 401 ||
                    error.response.status === 403
                ) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("email");
                    localStorage.removeItem("userID");
                    localStorage.removeItem("userName");
                    localStorage.removeItem("secret_key");
                    localStorage.removeItem("refreshToken");
                    localStorage.removeItem("expired");
                    localStorage.removeItem("CouponPer");
                    localStorage.removeItem("SubscriptionData");
                    localStorage.removeItem("couponID");
                    localStorage.removeItem("CouponPer");
                    this.props.history.push("/login");
                }
            });
    }

    componentDidMount() {
        this.getCouponCode();
        this.refreshToken();
        this.getSubscriptionDetails();
        // this.checkHasAnnualSubsc()
    }


    checkHasInstallMent(id, state) {
        let ids = JSON.parse(localStorage.getItem("subsId"));
        let allState = JSON.parse(localStorage.getItem("subsIdAllState"));
        let index;
        console.log(ids, allState)

        if (allState === null && ids !== null || allState === undefined && ids !== null) {
            index = ids?.findIndex(object => {
                return object?.subid === id && object?.state === state;
            });
        } else if (allState !== null) {
            console.log(allState)
            let hasVal = Object.keys(allState).some(function (k) {
                return allState[k] === id;
            });
            if (hasVal) {
                index = ids.findIndex(object => {
                    return object?.subid === id && object?.state === allState.state;
                });
            }
            else {
                index = ids.findIndex(object => {
                    return object?.subid === id && object?.state === state;
                });
            }
        } else {
            return false
        }

        if (ids !== null && index !== -1) {
            return true
        }
        return false

    }

    calculateTotal() {
        let ids = JSON.parse(localStorage.getItem("subsId"));
        let SubscriptionData = JSON.parse(localStorage.getItem("SubscriptionData"));
        let allState = JSON.parse(localStorage.getItem("subsIdAllState"));
        let instAmount = [];
        let totalAmounts = 0;
        if (ids?.length && allState === null || ids?.length && allState === undefined) {
            let loop = undefined;
            for (let i = 0; i < SubscriptionData.length; i++) {
                let dIndx = SubscriptionData.findIndex(x => x.subscriptionID == ids[i]?.subid && x.filterStates == ids[i]?.state);
                console.log(dIndx)
                if (dIndx !== -1) {
                    const MaintenanceCharges = JSON.parse(localStorage.getItem("MaintenanceCharges"));
                    instAmount.push(+parseFloat(SubscriptionData[dIndx].actualPrice / 12 + MaintenanceCharges).toFixed(2));

                } else {
                    // var result = SubscriptionData.filter(function (item) {
                    //     return !ids.some(function (el) {
                    //         console.log(el)
                    //         return el.subid === item.subscriptionID && el.state === item.filterStates
                    //     })
                    // });

                    var result = SubscriptionData.filter(el => {
                        return !ids.find(element => {
                            return element.subid === el.subscriptionID && element.state === el.filterStates;
                        });
                    });

                    for (let i = 0; i < result.length; i++) {
                        if (loop !== result.length) {
                            loop = i + 1
                            instAmount.push(+parseFloat(result[i].actualPrice).toFixed(2));
                        }
                    }
                }
            }

            for (let index = 0; index < instAmount.length; index++) {
                totalAmounts += instAmount[index];
            }
            this.setState({
                instalTotalPrice: totalAmounts
            })
        } else if (ids?.length && allState !== null || ids?.length && allState !== undefined) {
            let loop = undefined;
            for (let i = 0; i < SubscriptionData.length; i++) {
                let dIndx = SubscriptionData.findIndex(x => x.subscriptionID == ids[i]?.subid && allState.state == ids[i]?.state || x.subscriptionID == ids[i]?.subid && x.filterStates == ids[i]?.state);
                console.log(dIndx)
                if (dIndx !== -1) {
                    const MaintenanceCharges = JSON.parse(localStorage.getItem("MaintenanceCharges"));
                    instAmount.push(+parseFloat(SubscriptionData[dIndx].actualPrice / 12 + MaintenanceCharges).toFixed(2));

                } else {

                    var result = SubscriptionData.filter(el => {
                        return !ids.find(element => {
                            return element.subid === el.subscriptionID && element.state === el.filterStates || element.subid === el.subscriptionID && element.state === allState.state;
                        });
                    });

                    for (let i = 0; i < result.length; i++) {
                        if (loop !== result.length) {
                            loop = i + 1
                            instAmount.push(+parseFloat(result[i].actualPrice).toFixed(2));
                        }
                    }

                }
            }

            for (let index = 0; index < instAmount.length; index++) {
                totalAmounts += instAmount[index];
            }
            this.setState({
                instalTotalPrice: totalAmounts
            })
        }
        else {
            this.setState({
                instalTotalPrice: null
            })
        }
    }



    render() {
        return (
            <>
                {this.state.token !== null ? (
                    <AfterLoginMenu />
                ) : (
                    <BeforeLoginMenu />
                )}
                <main role="main" className="">
                    <div className="pt-3 pb-5">
                        <div className="container">
                            {this.state.errorMessage !== "" && (
                                <div
                                    className="alert alert-danger alert-dismissible fade show"
                                    role="alert"
                                >
                                    {this.state.errorMessage}
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="alert"
                                        aria-label="Close"
                                    ></button>
                                </div>
                            )}
                            {this.state.successMessage !== "" && (
                                <div
                                    className="alert alert-success alert-dismissible fade show"
                                    role="alert"
                                >
                                    {this.state.successMessage}
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="alert"
                                        aria-label="Close"
                                    ></button>
                                </div>
                            )}

                            <div className="col-12 check-mobile-pad">
                                <div className="form-group">
                                    <button
                                        type="submit"
                                        onClick={this.handleAddMore}
                                        className="btn btn-orange mb-2 float-right"
                                    >
                                        Add More..
                                    </button>
                                </div>
                                <h3 className="bold_text_no_text_transform font20">
                                    Order information
                                </h3>
                                <div className="table-responsive checkout-table">
                                    <table className="table table-bordered border-warning">
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    Subscription type
                                                </th>
                                                <th scope="col">Description</th>
                                                <th scope="col">No. of Files</th>
                                                <th scope="col">Unit Price(USD)</th>
                                                <th scope="col">
                                                    Total Price(USD)
                                                </th>
                                                <th scope="col">
                                                    Final Price(USD)
                                                </th>
                                                <th scope="col">Installment Cost</th>
                                                <th scope="col">Maintenance Fee</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        {this.state.items.map((item, index) => (
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        {item.subscriptionTitle}
                                                        <br />
                                                        {item.filterStates}
                                                    </td>
                                                    <td>{item.subscriptionType}</td>
                                                    {item.accessType ==
                                                        "National" ? (
                                                        <td>
                                                            {
                                                                item.totalFilesCountAllStates
                                                            }{" "}
                                                            Files
                                                        </td>
                                                    ) : (
                                                        <td>
                                                            {
                                                                item.totalFilesCountSpecificState
                                                            }{" "}
                                                            Files
                                                        </td>
                                                    )}
                                                    <td align="right">
                                                        $
                                                        {parseFloat(
                                                            item.price
                                                        ).toLocaleString(
                                                            undefined,
                                                            {
                                                                minimumFractionDigits: 2,
                                                            }
                                                        )}
                                                    </td>
                                                    <td align="right">
                                                        {/* {item.totalPrice} */}$
                                                        {parseFloat(
                                                            item.totalPrice
                                                        ).toLocaleString(
                                                            undefined,
                                                            {
                                                                minimumFractionDigits: 2,
                                                            }
                                                        )}
                                                    </td>
                                                    <td align="right">
                                                        {/* {item.totalPrice} */}$
                                                        {parseFloat(
                                                            item.actualPrice
                                                        ).toLocaleString(
                                                            undefined,
                                                            {
                                                                minimumFractionDigits: 2,
                                                            }
                                                        )}
                                                    </td>
                                                    <td align="right">
                                                        {
                                                            this.checkHasInstallMent(item.subscriptionID, item.filterStates) ?
                                                                `$${parseFloat(item.actualPrice / 12).toFixed(2)}` : '$0'
                                                        }

                                                    </td>
                                                    <td align="right">
                                                        {
                                                            this.checkHasInstallMent(item.subscriptionID, item.filterStates) ?
                                                                `$${localStorage.getItem('MaintenanceCharges')}` : '$0'
                                                        }
                                                    </td>
                                                    <td>
                                                        <button type="button" onClick={() => {
                                                            this.DeleteCart(
                                                                item.subscriptionID, item.subscriptionType, item.filterStates, item.row
                                                            );
                                                        }} className="close redclose" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        ))}
                                        <tbody>
                                            <td colSpan="3">
                                                {/* <label className="pr-3 pb-3 col-md-5 custom-apply-dropdown">Apply Coupon</label> */}
                                                <input

                                                    type="text"
                                                    name="couponname"

                                                    value={
                                                        this.state.password
                                                    }
                                                    onChange={
                                                        this.handleChange
                                                    }
                                                    className="form-control text-center mb-3 coupontext blockletter"
                                                    placeholder="Enter Coupon code"

                                                />

                                                <button

                                                    className="btn btn-orange mr-2 applybutton "
                                                    onClick={this.handleCouponChange}>Apply</button></td>

                                            <td
                                                colSpan="4"
                                                className="text-right font-weight-bold custom-apply-dropdown blockletter"
                                            >
                                                {
                                                    !this.state.isCouponApplyed ? '' :
                                                    this.state.CouponName ? 
                                                    ` PROMO APPLIED ${
                                                        this.state.CouponName
                                                    }`
                                                    : ''
                                                }
                                                {/* PROMO APPLIED {
                                                    this.state.CouponName
                                                } */}

                                            </td>
                                            <td align="right" colSpan="1" className="font-weight-bold redclose"  >
                                                {" "}
                                                - $
                                                {parseFloat(
                                                    this.state.DicountedAMount.toFixed(2)
                                                ).toLocaleString(
                                                    undefined,
                                                    {
                                                        minimumFractionDigits: 2,
                                                    }
                                                )}
                                                {" "}
                                            </td>
                                            <td></td>
                                        </tbody>
                                        <tbody>
                                            <tr>

                                                <td
                                                    colSpan="7"
                                                    className="text-right font-weight-bold"
                                                >
                                                    TOTAL
                                                </td>
                                                <td align="right" colSpan="1" className="font-weight-bold">
                                                    {this.state.instalTotalPrice !== null ?
                                                        `$${this.state.instalTotalPrice.toFixed(2)}` :
                                                        `$${parseFloat(
                                                            this.state.totalAMount
                                                        ).toLocaleString(
                                                            undefined,
                                                            {
                                                                minimumFractionDigits: 2,
                                                            }
                                                        )}`
                                                    }

                                                </td>
                                                <td></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="row">
                                    <aside className="col-lg-6">
                                        <h3 className="bold_text_no_text_transform font20 pl-3">
                                            Payment information
                                        </h3>
                                        <hr className="border border-warning" />
                                        <div className="card-body check-mobile-pad">
                                            <ul
                                                className="nav bg-light nav-pills rounded nav-fill mb-3"
                                                role="tablist"
                                            >
                                                <li
                                                    className="nav-item"
                                                    onClick={() => {
                                                        this.handlePaymentChange(
                                                            "card"
                                                        );
                                                    }}
                                                >
                                                    <a
                                                        className="nav-link active"
                                                        data-toggle="pill"
                                                        href="#nav-tab-card"
                                                    >
                                                        <i className="fa fa-credit-card"></i>{" "}
                                                        Credit / Debit Card
                                                    </a>
                                                </li>
                                                <li
                                                    className="nav-item"
                                                    onClick={() => {
                                                        this.handlePaymentChange(
                                                            "echeck"
                                                        );
                                                    }}
                                                >
                                                    <a
                                                        className="nav-link"
                                                        data-toggle="pill"
                                                        href="#nav-tab-bank"
                                                    >
                                                        <i className="fa fa-university"></i>{" "}
                                                        Echeck Payment
                                                    </a>
                                                </li>
                                            </ul>

                                            <div className="tab-content">
                                                <div
                                                    className="tab-pane fade show active"
                                                    id="nav-tab-card"
                                                >
                                                    <div className="form-group">
                                                        <label className="font-weight-bold">
                                                            Card Type
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <div className="input-group">
                                                            <select
                                                                className="form-control border border-warning"
                                                                name="cardType"
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                            >
                                                                <option value="">
                                                                    Select
                                                                </option>
                                                                <option value="Visa">
                                                                    Visa
                                                                </option>
                                                                <option value="Master Card">
                                                                    Master Card
                                                                </option>
                                                                <option value="Discover">
                                                                    Discover
                                                                </option>
                                                                <option value="American Express">
                                                                    American
                                                                    Express
                                                                </option>
                                                            </select>

                                                            <div className="input-group-append">
                                                                <span className="input-group-text text-muted">
                                                                    <i className="fa fa-cc-visa"></i>{" "}
                                                                    <i className="fa fa-cc-amex ml-1"></i>{" "}

                                                                    <i className="fa fa-cc-mastercard"></i>
                                                                    &nbsp;
                                                                    <i className="fa fa-cc-discover"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="font-weight-bold">
                                                            Name on the Card
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control border border-warning"
                                                            name="cardName"
                                                            placeholder=""
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="font-weight-bold">
                                                            Card Number
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <div className="input-group">
                                                            <input
                                                                type="text"
                                                                className="form-control border border-warning"
                                                                name="cardNumber"
                                                                placeholder=""
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-5">
                                                            <div className="form-group">
                                                                <label>
                                                                    <span className="hidden-xs font-weight-bold">
                                                                        Expiry
                                                                        Date
                                                                        <span className="text-danger">
                                                                            *
                                                                        </span>
                                                                    </span>{" "}
                                                                </label>
                                                                <div className="input-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border border-warning "
                                                                        placeholder="MM/YYYY"
                                                                        name="expiryDate"
                                                                        onChange={
                                                                            this
                                                                                .handleChange
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-group">
                                                                <label
                                                                    className="font-weight-bold"
                                                                    data-toggle="tooltip"
                                                                    title=""
                                                                    data-original-title="3 digits code on back side of the card"
                                                                >
                                                                    CVV
                                                                    <span className="text-danger">
                                                                        *
                                                                    </span>
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control border border-warning"
                                                                    onChange={
                                                                        this
                                                                            .handleChange
                                                                    }
                                                                    name="cvv"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="tab-pane fade"
                                                    id="nav-tab-bank"
                                                >
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label font-weight-bold">
                                                            Routing Number
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="routingNumber"
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label font-weight-bold">
                                                            Account Number
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="accountNumber"
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label font-weight-bold">
                                                            Name on Bank Account
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="accountName"
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label font-weight-bold">
                                                            Bank Name
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="bankName"
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label font-weight-bold">
                                                            Account type
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <select
                                                                name="accountType"
                                                                className="form-control"
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                            >
                                                                <option value="Savings">
                                                                    Savings
                                                                </option>
                                                                <option value="Checking">
                                                                    Checking
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </aside>
                                    <aside className="col-lg-6">
                                        <h3 className="bold_text_no_text_transform font20 pl-3">
                                            Billing information
                                        </h3>
                                        <hr className="border border-warning" />

                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label font-weight-bold">
                                                First Name
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <div className="col-sm-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="firstName"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label font-weight-bold">
                                                Last Name
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <div className="col-sm-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="lastName"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label font-weight-bold">
                                                Address
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <div className="col-sm-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="address"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label font-weight-bold">
                                                City
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <div className="col-sm-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="city"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label font-weight-bold">
                                                State
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <div className="col-sm-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="state"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label font-weight-bold">
                                                Zip Code
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <div className="col-sm-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="zipcode"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            {
                                                this.state.paymentType === 'card' ?
                                                    <button type="submit" className="btn btn-orange mb-2 float-right"
                                                        disabled={this.state.cardType === '' || this.state.cardName === '' || this.state.cardNumber === '' || this.state.expiryDate === '' || this.state.cvv === '' || this.state.firstName === '' || this.state.lastName === '' || this.state.address === '' || this.state.city === '' || this.state.state === '' || this.state.zipcode === '' || this.state.paymentInProgress} data-toggle="modal" data-target="#payment_process_Modal">
                                                        Make Payment
                                                    </button>
                                                    :
                                                    <button type="submit" className="btn btn-orange mb-2 float-right"
                                                        disabled={this.state.routingNumber === '' || this.state.accountNumber === '' || this.state.accountName === '' || this.state.bankName === '' || this.state.firstName === '' || this.state.lastName === '' || this.state.address === '' || this.state.city === '' || this.state.state === '' || this.state.zipcode === '' || this.state.paymentInProgress} data-toggle="modal" data-target="#payment_process_Modal">
                                                        Make Payment
                                                    </button>
                                            }

                                        </div>
                                    </aside>

                                </div>
                            </form>


                            {/* payment Under process modal  */}
                            <div className="modal fade" id="payment_process_Modal" data-backdrop="static">
                                <div className="modal-dialog modal-dialog-scrollable payment_process_modal">
                                    <div className="modal-content ">

                                        <div className="modal-body ">
                                            <span data-dismiss="modal" ref={this.ref} ></span>
                                            <div className="loader"></div>
                                            <p>Your payment is under process. Please do not refresh the page or hit back button now.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}
export default withRouter(Checkout);
