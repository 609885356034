import React, { Component } from "react";
import reactDom from "react-dom";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import BeforeLoginMenu from "../../routes/BeforeLoginMenu";
import AfterLoginMenu from "../../routes/AfterLoginMenu";
import { Browser, setStyleAttribute } from "@syncfusion/ej2-base";
import { DataManager, WebApiAdaptor } from "@syncfusion/ej2-data";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import lowCostPopupContext from '../data/popup-text.json';
import AsyncSelect from 'react-select/async';
import _ from 'lodash';

import {
  CalculatedField,
  PivotFieldListComponent,
  Inject,
  PivotViewComponent,
} from "@syncfusion/ej2-react-pivotview";

import "./pivottable.css";


const CUSTOM_CSS = `
.e-pivotview {
width: 100%;
height: 100%;
float: left;
}
.e-pivotfieldlist {
width: 0;
height: 100%;
float: right;
display:none;
}
.e-pivotfieldlist .e-static {
width: 100% !important;
}
.e-frozenheader .e-table
{
    width:400px !important;
}
.e-pivotview .e-headercell{
    background-color: #666d77 !important;
    padding-top:15px
    padding-bottom:15px;
  }
  .e-pivotview .e-headertext{
      color:#fff;
      font-size:15px;
  }
  .e-grid.e-default tr:last-child td:first-child{
    background:#9eb0b9 !important;
    color:#fff !important;
  }
  .e-grid.e-default tr:last-child td .e-cellvalue{
      color:#fff !important;
  }
  .e-pivotview .e-grid .e-rowcell{
      background:#9eb0b9;
  }

.e-frozencontent #PivotViewFieldList_grid_content_table
{
    width:400px!important;
}
.e-table colgroup col {
  width:inherit !important;
}

.e-frozenheader.e-frozen-left-header .e-table .e-headertext:before {
  content: "Code Description/Hospital";
}
.exportBTN
{
  margin-right:24%;
}
`;

class OopRates extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem("token");
    this.phonePopup = React.createRef();
    this.selectRef = React.createRef();
    this.codeDescriptionRef = React.createRef();
    if (!token) {
      this.props.history.push("/login");
    }
    const sdata = JSON.parse(sessionStorage.getItem('oopfl'));
    const oopflcus = JSON.parse(sessionStorage.getItem('oopflcus'));
    this.state = {
      token: localStorage.getItem("token"),
      pivotData: [],
      hasData: false,
      isLoaded: false,
      dataSourceSettings: "",
      showLoader: false,
      msaID: sdata?.msaID || oopflcus?.msaID || '',
      msaName: sdata?.msaName || oopflcus?.msaName || '',
      medicalCode: sdata?.code || oopflcus?.code || '',
      stateName: sdata?.stateName || oopflcus?.stateName || '',
      stateCodeloc: sdata?.stateCode || oopflcus?.stateCode || '',
      lives: oopflcus?.lives || '',
      accumulator: oopflcus?.accumulator || '',
      paymentMethod: sdata?.paymentMethod || oopflcus?.paymentMethod || '',
      Setting: [],
      states: [],
      needModal: sessionStorage.getItem("oopfl"),
      mobileNumber: '',
      countryNumber: '+1',
      oopJson: [],
      lowCostPopupContext: lowCostPopupContext,
      hasOpenPhone: false,
      summeryCashrate: [],
      userReportType: '',
      cancleToken: axios.CancelToken.source(),
      codeDiscription: [],
      codeDiscriptionFilterd: [],
      codeDiscriptionPopup: false,
      searchText: '',
      isCodeDescriptionLoade: false,
      msaTableName: sdata?.tableName || oopflcus?.tableName || '',
    };

    let filterArr = JSON.stringify({
      state: "AL",
      MmInclude: "true",
    });

    this.remoteData = new DataManager({
      headers: {
        Authorization: `Bearer ${this.state.token}`,
        secret_key: localStorage.getItem("secret_key"),
      },
      url: `${process.env.REACT_APP_API_URL}api/OobRates/getoobrates`,
      adaptor: new WebApiAdaptor(),
      crossDomain: true,
    });

    this.exportType = [
      { value: "pdf", text: "PDF" },
      { value: "excel", text: "Excel" },
      { value: "csv", text: "CSV" },
    ];

    this.dataSourceSettings = {
      dataSource: this.remoteData,
      type: "JSON",
      expandAll: false,
      allowLabelFilter: true,
      allowValueFilter: true,
      columns: [],
      rows: [
        { name: "FacilityName", caption: "Facility Name" },
        { name: "Category", caption: "Category" },
        {
          name: "codeDescription",
          caption: "Code Description",
        },
        { name: "procedureCode", caption: "Procedure Code" },
      ],
      values: [{ name: "rate", caption: "Negotiate Rate" }],
      formatSettings: [
        { name: "FacilityName", format: "C0" },
        { name: "rate", format: "C2" },
        {
          name: "cmsRelativity",
          format: "C2",
        },
      ],
      filters: [
        {
          name: "rate",
          caption: "Negotiate Rate",
        },
        { name: "healthsystemType", caption: "Healthsystem Type" },
        { name: "stateName", caption: "State" },
      ],
      enableSorting: true,
      valueSortSettings: {
        headerText: "Negotiate Rate ($)",
        sortOrder: "Descending",
      },
    };

    this.handleFilterSubmit = this.handleFilterSubmit.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.oopCustomSubmit = this.oopCustomSubmit.bind(this);
    this.numberValid = this.numberValid.bind(this);
    this.openNumberPopup = this.openNumberPopup.bind(this);
    this.sendSms = this.sendSms.bind(this);
    this.getSummeryReport = this.getSummeryReport.bind(this);
    this.getReportType = this.getReportType.bind(this);
    this.handleMsaChange = this.handleMsaChange.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.loadOptions = _.debounce(this.loadOptions.bind(this), 100);
    this.handelCodeSelect = this.handelCodeSelect.bind(this);
    this.getAllSetting = this.getAllSetting.bind(this);
    this.handleSettingChange = this.handleSettingChange.bind(this);
  }

  handleClickOutside = event => {
    const domNode = reactDom.findDOMNode(this.codeDescriptionRef.current);

    if (!domNode || !domNode.contains(event.target)) {
      this.setState({
        codeDiscriptionPopup: false
      });
    }
  }

  getaccessAllState() {
    const reportStatus = JSON.parse(localStorage.getItem("reportType"));
    const umail = reportStatus.replace_Email !== null ?
      reportStatus.replace_Email : reportStatus.actual_Email;
    axios
      .get(
        `${process.env.REACT_APP_API_URL
        }api/OobRates/getoobratesStatewiseUserAccess?email=${umail}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
            secret_key: localStorage.getItem("secret_key"),
          },
          cancelToken: this.state.cancleToken.token
        }
      )
      .then((response) => {
        this.setState({
          states: Object.values(response.data),
        });
      })
      .catch((error) => {
        if (
          error?.response?.status === 400 ||
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          localStorage.clear()
          this.props.history.push("/logout");
        }
      });
  }

  handleStateChange(event) {
    let stateFilter = []
    if (event) {
      this.setState({
        stateName: event?.target.value,
        msaID: '',
        codeDiscription: [],
        codeDiscriptionFilterd: [],
        searchText: '',
        medicalCode: ''
      })
      stateFilter = this.state.states.filter(i => i.stateName === event?.target.value);
      this.selectRef.current.setValue('')
    }
    const state = stateFilter[0]?.stateCode || this.state.stateCodeloc;
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/Subscription/getmsafilter?stateCode=${state}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
            secret_key: localStorage.getItem("secret_key"),
          },
        }
      )
      .then((response) => {
        this.setState({
          msa: Object.values(response.data),
        });
      })
      .catch((error) => {
        if (
          error?.response?.status === 400 ||
          error?.response?.status === 401 ||
          error?.response?.status === 403
        ) {
          localStorage.removeItem("token");
          localStorage.removeItem("email");
          localStorage.removeItem("userID");
          localStorage.removeItem("userName");
          localStorage.removeItem("secret_key");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("expired");
          this.props.history.push("/login");
        }
      });
  }


  handleExportClick() {
    const reportStatus = JSON.parse(localStorage.getItem("reportType"));
    const umail = reportStatus.replace_Email !== null ?
      reportStatus.replace_Email : reportStatus.actual_Email;
    const filter = {
      'code': this.state.medicalCode.procedureCode,
      'accout': umail,
      "stateName": this.state.stateName,
      "msaID": this.state.msaID,
      'msaName': this.state.msaName,
      'paymentMethod':this.state.paymentMethod.toUpperCase(),
    }
    window.open(
      `${process.env.REACT_APP_API_URL}api/OobRates/exportOobData?oobinput=${JSON.stringify(filter)}`,
      "_blank"
    );


  }

  handleExportClickcustom() {
    const reportStatus = JSON.parse(localStorage.getItem("reportType"));
    const umail = reportStatus.replace_Email !== null ?
      reportStatus.replace_Email : reportStatus.actual_Email;
    const filter = {
      'stateName': this.state.stateName,
      'msaID': this.state.msaID,
      'code': this.state.medicalCode.procedureCode,
      'lives': this.state.lives,
      'accumulator': this.state.accumulator,
      'accout': umail,
      'msaName': this.state.msaName,
      'paymentMethod':this.state.paymentMethod.toUpperCase(),
    }
    window.open(
      `${process.env.REACT_APP_API_URL}api/OobRates/exportOobDatacustomvalue?oobinput=${JSON.stringify(filter)}`,
      "_blank"
    );


  }

  handleSettingChange(event) {
    this.setState({
      paymentMethod: event.target.value
    });
  }


  refreshToken() {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    const expired = localStorage.getItem("expired");

    if (token !== null && refreshToken !== null && expired !== null) {
      axios
        .post(`${process.env.REACT_APP_API_URL}api/auth/refreshToken`, {
          token: token,
          refreshToken: refreshToken,
          expiration: expired,
        })
        .then((response) => {
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("expired");
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("refreshToken", response.data.refreshToken);
          localStorage.setItem("expired", response.data.expiration);
        })
        .catch((error) => {
          localStorage.clear()
          this.props.history.push("/logout");
        });
    }
  }



  handleFilterSubmit(event) {
    event.preventDefault();
    const searchingStatus = JSON.parse(localStorage.getItem("reportType"))
    if (searchingStatus.searching_Status !== 1) {
      var d = new Date(searchingStatus?.next_Search_Date);
      const msg = `Your next eligible search begins on ${d.toLocaleDateString('en-US')}`;
      window.alert(msg)
      //window.alert("Your 2 free medical code searches for every 60 days is over. Please try again later.");
      this.props.history.push("/");
    }
    else {
      this.getOOPRates()
    }
  }

  handleMsaChange(event) {
    const TableNameFilter = this.state.msa.filter(i => i.msaID === +event.target.value);
    this.setState({
      msaID: event.target.value,
      msaName: event.target.selectedOptions[0].getAttribute('msaname'),
      codeDiscription: [],
      codeDiscriptionFilterd: [],
      searchText: '',
      isCodeDescriptionLoade: true,
      msaTableName: TableNameFilter[0]?.TableName,
      medicalCode: ''
    })
    this.selectRef.current.setValue('')
    // this.selectRef.current.clearValue();

  }

  handleSearchChange(value) {
    this.setState({
      searchText: value,
    });
    return value;
  }

  getOptionValue = (option) => option.procedureCode;

  getOptionLabel = (option) => `(${option.procedureCode}) ${option.codeDescription}`;

  // load options using API call
  loadOptions(value, callback) {
    const fdata = {
      stateCode: this.state.stateCodeloc,
      msaName: this.state.msaName,
      queryText: this.state.searchText,
    }
    if (!value) {
      return callback([]);
    }
    axios
      .get(
        `${process.env.REACT_APP_API_URL}api/OobRates/GetCodeDescriptionbyMSA?FilterJson=${JSON.stringify(fdata)}`
      )
      .then((response) => {

        if (response.data.length) {
          let dataModified;
          dataModified = response?.data?.map(
            obj => {
              return {
                "procedureCode": obj?.procedureCode.trim(),
                "codeDescription": obj?.codeDescription.trim()
              }
            }
          );
          this.setState({
            codeDiscription: dataModified
          });
          callback(this.mapOptionsToValues(dataModified))

          sessionStorage.setItem("codeDiscription", JSON.stringify(dataModified));
        } else {
          this.setState({
            codeDiscription: []
          });
          callback(this.mapOptionsToValues([]))
          sessionStorage.setItem("codeDiscription", JSON.stringify([]));
        }

      })
      .catch((error) => {
        console.log(error)
      });


  };

  handelCodeSelect(event) {
    console.log(event)
    this.setState({
      medicalCode: event
    })
  }

  mapOptionsToValues = options => {
    return options.map(option => ({
      procedureCode: option.procedureCode,
      codeDescription: option.codeDescription
    }));
  };

  // oop filter 
  oopCustomSubmit() {
    const searchingStatus = JSON.parse(localStorage.getItem("reportType"));
    if (this.state.token !== null && searchingStatus.searching_Status === 1) {
      this.setState({
        showLoader: true,
        errorMessage: ''
      });
      sessionStorage.removeItem("oopfl")
      const reportStatus = JSON.parse(localStorage.getItem("reportType"));
      const umail = reportStatus.replace_Email !== null ?
        reportStatus.replace_Email : reportStatus.actual_Email;

      const filter = {
        'stateName': this.state.stateName,
        'msaID': this.state.msaID,
        'msaName': this.state.msaName,
        'code': this.state.medicalCode.procedureCode,
        'lives': this.state.lives,
        'accumulator': this.state.accumulator,
        'accout': umail,
        "report_Type": reportStatus.report_Type,
        "logged_useremail": reportStatus.actual_Email,
        'stateCode': this.state.stateCodeloc,
        'paymentMethod':this.state.paymentMethod.toUpperCase(),
        "islogged": reportStatus.report_Type.toLowerCase() !== 'employer' ? true : false
      }

      const save = {
        'stateName': this.state.stateName,
        'msaID': this.state.msaID,
        'msaName': this.state.msaName,
        'code': this.state.medicalCode,
        'lives': this.state.lives,
        'accumulator': this.state.accumulator,
        'accout': umail,
        "report_Type": reportStatus.report_Type,
        "logged_useremail": reportStatus.actual_Email,
        'stateCode': this.state.stateCodeloc,
        'paymentMethod':this.state.paymentMethod,
        "islogged": reportStatus.report_Type.toLowerCase() !== 'employer' ? true : false
      }
      sessionStorage.setItem("oopflcus", JSON.stringify(save));
      // console.log(filter)
      this.setState({
        userReportType: reportStatus.report_Type
      });
      axios
        .post(
          `${process.env.REACT_APP_API_URL}api/OobRates/Getoobrateswithcustomvalue`, filter, {
          cancelToken: this.state.cancleToken.token
        }
        )
        .then((response) => {
          // console.log(response.data[0] == {})
          this.setState({
            hasData: response.data[0].Category === undefined ? true : false
          });
          this.setState({
            dataSourceSettings: {
              dataSource: response.data,
              type: "JSON",
              expandAll: true,
              allowLabelFilter: true,
              allowValueFilter: true,
              columns: [],
              rows: [
                {
                  name: "CodeDescription",
                  caption: "Code Description",
                },
                {
                  name: "FacilityName",
                  caption: "Facility Name",
                },
                {
                  name: "planname",
                  caption: "Plan Name",
                },
              ],
              values: [
                {
                  name: "rates",
                  caption: "Negotiate Rate ($)",
                  type: "Avg",
                },
                {
                  name: "MemberOOP",
                  caption: "Your Out Of Pocket Cost",
                  type: "Avg",
                },
              ],
              formatSettings: [
                { name: "FacilityName", format: "C0" },
                { name: "rates", format: "C2" },
                {
                  name: "MemberOOP", format: "C2",
                },
              ],
              filters: [
                {
                  name: "Category",
                  caption: "Category",
                },
                {
                  name: "healthsystemType",
                  caption: "Health System",
                },
                { name: "LOB", caption: "LOB" },
                { name: "codetype", caption: "Code Type" },
                { name: "location", caption: "Location" },
                { name: "msaname", caption: "MSA Name" },
                { name: "Statename", caption: "State" },
                {
                  name: "FacilityName",
                  caption: "Facility Name",
                },
                {
                  name: "HospitalType", caption: "Hospital Type"
                },
                {
                  name: "npi", caption: "NPI"
                },
                {
                  name: "notes", caption: "Notes"
                },
              ],
              enableSorting: false,
              valueSortSettings: {
                headerText: "Negotiate Rate ($)",
                sortOrder: "Descending",
              },
            },
            isLoaded: true,
            oopJson: response.data
          });
          setTimeout(() => {
            document.querySelector('.e-headercell.e-columnsheader.e-gtot.e-movablefirst')?.click();
            document.querySelector('.e-headercell.e-columnsheader.e-gtot.e-movablefirst')?.click();
          }, 1000);
          this.getSummeryReport();
          this.getReportType();
        })
        .catch((error) => {
          this.setState({
            dataSourceSettings: {
              dataSource: [],
              type: "JSON",
              expandAll: true,
              allowLabelFilter: true,
              allowValueFilter: true,
              columns: [],
              rows: [
                {
                  name: "CodeDescription",
                  caption: "Code Description",
                },
                {
                  name: "FacilityName",
                  caption: "Facility Name",
                },
                {
                  name: "planname",
                  caption: "Plan Name",
                },
              ],
              values: [
                {
                  name: "rates",
                  caption: "Negotiate Rate ($)",
                  type: "Avg",
                },
                {
                  name: "MemberOOP",
                  caption: "Your Out Of Pocket Cost",
                  type: "Avg",
                },
              ],
              formatSettings: [
                { name: "FacilityName", format: "C0" },
                { name: "rates", format: "C2" },
                {
                  name: "MemberOOP", format: "C2",
                },
              ],
              filters: [
                {
                  name: "Category",
                  caption: "Category",
                },
                {
                  name: "healthsystemType",
                  caption: "Health System",
                },
                { name: "LOB", caption: "LOB" },
                { name: "codetype", caption: "Code Type" },
                { name: "location", caption: "Location" },
                { name: "msaname", caption: "MSA Name" },
                { name: "Statename", caption: "State" },
                {
                  name: "FacilityName",
                  caption: "Facility Name",
                },
                {
                  name: "HospitalType", caption: "Hospital Type"
                },
                {
                  name: "npi", caption: "NPI"
                },
                {
                  name: "notes", caption: "Notes"
                },
              ],
              enableSorting: false,
              valueSortSettings: {
                headerText: "Negotiate Rate ($)",
                sortOrder: "Descending",
              },

            },
            isLoaded: true,
            showLoader: false
          });
          if (
            error?.response?.status === 400 ||
            error?.response?.status === 401 ||
            error?.response?.status === 403
          ) {
            localStorage.clear();
            this.props.history.push("/logout");
          }
        });
    } else if (this.state.token == null || this.state.token == undefined) {
      this.setState({
        errorMessage: "You need to login first"
      });
    } else {
      window.alert("Your 2 free medical code searches for every 60 days is over. Please try again later.");
      this.props.history.push("/");
    }
  }

  numberValid(num) {
    const re = /^[0-9\b]+$/;
    return re.test(num)
  }

  getOOPRates() {
    if (this.state.token !== null) {
      sessionStorage.removeItem("oopflcus")
      this.setState({
        showLoader: true,
        errorMessage: ''
      });

      const reportStatus = JSON.parse(localStorage.getItem("reportType"));
      const umail = reportStatus.replace_Email !== null ?
        reportStatus.replace_Email : reportStatus.actual_Email;
      const filter = {
        'code': this.state.medicalCode.procedureCode,
        'accout': umail,
        "stateName": this.state.stateName,
        "msaID": this.state.msaID,
        'msaName': this.state.msaName,
        "report_Type": reportStatus.report_Type,
        "logged_useremail": reportStatus.actual_Email,
        'stateCode': this.state.stateCodeloc,
        'paymentMethod':this.state.paymentMethod.toUpperCase(),
        "islogged": reportStatus.report_Type.toLowerCase() !== 'employer' ? true : false
      }

      const save = {
        'code': this.state.medicalCode,
        'accout': umail,
        "stateName": this.state.stateName,
        "msaID": this.state.msaID,
        'msaName': this.state.msaName,
        "report_Type": reportStatus.report_Type,
        "logged_useremail": reportStatus.actual_Email,
        'stateCode': this.state.stateCodeloc,
        'paymentMethod':this.state.paymentMethod,
        "islogged": reportStatus.report_Type.toLowerCase() !== 'employer' ? true : false
      }
      sessionStorage.setItem("oopfl", JSON.stringify(save));
      this.setState({
        userReportType: reportStatus.report_Type
      });
      axios
        .post(
          `${process.env.REACT_APP_API_URL}api/OobRates/getoobrates`, filter, {
          cancelToken: this.state.cancleToken.token
        }
        )
        .then((response) => {
          // console.log(response.data[0] == {})

          this.setState({
            hasData: response.data[0].Category === undefined ? true : false
          });
          this.setState({
            dataSourceSettings: {
              dataSource: response.data,
              type: "JSON",
              expandAll: true,
              allowLabelFilter: true,
              allowValueFilter: true,
              columns: [],
              rows: [
                {
                  name: "CodeDescription",
                  caption: "Code Description",
                },
                {
                  name: "FacilityName",
                  caption: "Facility Name",
                },
                {
                  name: "planname",
                  caption: "Plan Name",
                },
              ],
              values: [
                {
                  name: "rates",
                  caption: "Negotiate Rate ($)",
                  type: "Avg",
                },
                {
                  name: "MemberOOP",
                  caption: "Your Out Of Pocket Cost",
                  type: "Avg",
                },
              ],
              formatSettings: [
                { name: "FacilityName", format: "C0" },
                { name: "rates", format: "C2" },
                {
                  name: "MemberOOP", format: "C2",
                },
              ],
              filters: [
                {
                  name: "Category",
                  caption: "Category",
                },
                {
                  name: "healthsystemType",
                  caption: "Health System",
                },
                { name: "LOB", caption: "LOB" },
                { name: "codetype", caption: "Code Type" },
                { name: "location", caption: "Location" },
                { name: "msaname", caption: "MSA Name" },
                { name: "Statename", caption: "State" },
                {
                  name: "FacilityName",
                  caption: "Facility Name",
                },
                {
                  name: "HospitalType", caption: "Hospital Type"
                },
                {
                  name: "npi", caption: "NPI"
                },
                {
                  name: "notes", caption: "Notes"
                },
              ],
              enableSorting: false,
              valueSortSettings: {
                headerText: "Negotiate Rate ($)",
                sortOrder: "Descending",
              },

            },
            isLoaded: true,
            oopJson: response.data
          });
          setTimeout(() => {
            document.querySelector('.e-headercell.e-columnsheader.e-gtot.e-movablefirst')?.click();
            document.querySelector('.e-headercell.e-columnsheader.e-gtot.e-movablefirst')?.click();
          }, 1000);
          this.getSummeryReport();
          this.getReportType();
        })
        .catch((error) => {
          this.setState({
            dataSourceSettings: {
              dataSource: [],
              type: "JSON",
              expandAll: true,
              allowLabelFilter: true,
              allowValueFilter: true,
              columns: [],
              rows: [
                {
                  name: "CodeDescription",
                  caption: "Code Description",
                },
                {
                  name: "FacilityName",
                  caption: "Facility Name",
                },
                {
                  name: "planname",
                  caption: "Plan Name",
                },
              ],
              values: [
                {
                  name: "rates",
                  caption: "Negotiate Rate ($)",
                  type: "Avg",
                },
                {
                  name: "MemberOOP",
                  caption: "Your Out Of Pocket Cost",
                  type: "Avg",
                },
              ],
              formatSettings: [
                { name: "FacilityName", format: "C0" },
                { name: "rates", format: "C2" },
                {
                  name: "MemberOOP", format: "C2",
                },
              ],
              filters: [
                {
                  name: "Category",
                  caption: "Category",
                },
                {
                  name: "healthsystemType",
                  caption: "Health System",
                },
                { name: "LOB", caption: "LOB" },
                { name: "codetype", caption: "Code Type" },
                { name: "location", caption: "Location" },
                { name: "msaname", caption: "MSA Name" },
                { name: "Statename", caption: "State" },
                {
                  name: "FacilityName",
                  caption: "Facility Name",
                },
                {
                  name: "HospitalType", caption: "Hospital Type"
                },
                {
                  name: "npi", caption: "NPI"
                },
                {
                  name: "notes", caption: "Notes"
                },
              ],
              enableSorting: false,
              valueSortSettings: {
                headerText: "Negotiate Rate ($)",
                sortOrder: "Descending",
              },

            },
            isLoaded: true,
            showLoader: false
          });
          if (
            error?.response?.status === 400 ||
            error?.response?.status === 401 ||
            error?.response?.status === 403
          ) {
            localStorage.clear();
            this.props.history.push("/logout");
          }
        });

    }
  }

  openNumberPopup() {
    const list = this.phonePopup.current.classList;
    if (list.length !== 2) {
      this.phonePopup.current.classList = "activepopup getPhoneNumberBox"
      this.setState({ hasOpenPhone: true })
    } else {
      this.phonePopup.current.classList = "getPhoneNumberBox"
      this.setState({ hasOpenPhone: false })
    }
  }

  getSummeryReport() {
    const reportStatus = JSON.parse(localStorage.getItem("reportType"));
    const umail = reportStatus.replace_Email !== null ?
      reportStatus.replace_Email : reportStatus.actual_Email;
    const filter = {
      'code': this.state.medicalCode.procedureCode,
      'accout': umail,
      "stateName": this.state.stateName,
      "msaID": this.state.msaID,
      'msaName': this.state.msaName,
      'paymentMethod':this.state.paymentMethod.toUpperCase(),
    }

    axios
      .post(
        `${process.env.REACT_APP_API_URL}api/OobRates/getoobratesSummery`, filter, {
        cancelToken: this.state.cancleToken.token
      }
      )
      .then((response) => {
        //console.log(response)
        this.setState({
          summeryCashrate: response.data
        });

      })
      .catch((error) => {
        if (
          error.response?.status === 400 ||
          error.response?.status === 401 ||
          error.response?.status === 403
        ) {
          localStorage.clear();
          this.props.history.push("/logout");
        }
      });
  }

  sendSms() {
    const status = this.numberValid(this.state.mobileNumber)
    if (status) {
      this.setState({
        showLoader: true
      });
      const reportStatus = JSON.parse(localStorage.getItem("reportType"));
      const numfor = new Intl.NumberFormat("en-us",
        {
          currency: "USD",
          style: "currency"
        })
      let cashratedata = this.state.summeryCashrate;
      let oopjson = this.state.oopJson;
      // remove extra feild from json 
      for (var i = 0; i < oopjson.length; i++) {
        delete oopjson[i].LOB;
        delete oopjson[i].healthsystemType;
        delete oopjson[i].Statename;
        delete oopjson[i].location;
        delete oopjson[i].Category;
        delete oopjson[i].codetype;
        delete oopjson[i].npi;
      }
      const newArrayOfObj = oopjson.map(({
        medicalcode: CPTorDRGcode,
        CodeDescription: CodeDescription,
        msaname: MSAName,
        FacilityName: FacilityName,
        HospitalType: HospitalType,
        planname: PlanName,
        rates: Rates,
        MemberOOP: OOP,
        notes: Notes,
      }) => ({
        CPTorDRGcode,
        CodeDescription,
        MSAName,
        FacilityName,
        HospitalType,
        PlanName,
        Rates,
        OOP,
        Notes,
      }));
      var oopgroupdata = newArrayOfObj.reduce(function (r, a) {
        r[a.FacilityName] = r[a.FacilityName] || [];
        r[a.FacilityName].push(a);
        return r;
      }, Object.create(null));

      const oopgroupkeys = Object.keys(oopgroupdata);
      const summery = [];

      for (var j = 0; j < oopgroupkeys.length; j++) {
        let k = oopgroupkeys[j];
        let rates = oopgroupdata[k].map(item => item.Rates);
        let cashrate = cashratedata.filter(i => i.FacilityName === k);

        let json = {
          Hospital: k,
          LowAmt: numfor.format(Math.min(...rates)),
          AvgAmt: numfor.format((rates.reduce((a, b) => a + b, 0) / rates.length)),
          HighAmt: numfor.format(Math.max(...rates)),
          CashRate: numfor.format(cashrate[0]?.CASH_RATE || 0)
        }
        summery.push(json)
      }

      // removing OOP and Notes for Generic report 
      if (this.state.userReportType.toLowerCase() !== 'employer') {
        for (var i = 0; i < newArrayOfObj.length; i++) {
          delete newArrayOfObj[i].OOP;
          delete newArrayOfObj[i].Notes;
        }
      }

      // const newArr = newArrayOfObj.map(element => {
      //   if (element.Rates) element.Rates = element.Rates.toString()
      //   if (element.OOP) element.OOP = element.OOP.toString()
      //   return element
      // })
      const stateId = this.state.states.filter(i => i?.stateName.toLowerCase() === this.state?.stateName.toLowerCase());
      const userDomain = localStorage.getItem('email').split('@');
      const scondSplit = userDomain[1].split('.');
      let udomain = '';
      if (scondSplit.length == 2) {
        udomain = scondSplit[0];
      } else if (scondSplit.length > 2) {
        var str = userDomain[1].substring(userDomain[1].indexOf(".") + 1);
        udomain = str;
      }
      let smsVal = {
        number: this.state.countryNumber + this.state.mobileNumber,
        OOPData: JSON.stringify(newArrayOfObj.sort((a, b) => (a.Rates > b.Rates) - (a.Rates < b.Rates))),
        pdfHeader: this.state.lowCostPopupContext?.sendTo,
        userId: localStorage.getItem("userID"),
        OOPSummeryData: JSON.stringify(summery),
        pdfFooter: this.state.lowCostPopupContext?.pdfFooter,
        report_Type: reportStatus.report_Type,
        StateCode: stateId[0].stateCode,
        userDomain: udomain
      }

      axios
        .post(
          `${process.env.REACT_APP_API_URL}api/OobRates/sendPdftoUser`, smsVal, {
          cancelToken: this.state.cancleToken.token
        }
        )
        .then((response) => {
          this.setState({
            showLoader: false
          });
          this.phonePopup.current.classList = "getPhoneNumberBox"
          this.setState({ hasOpenPhone: false, mobileNumber: '' })
          alert(`${response.data}`);
        })
        .catch((error) => {
          this.setState({
            showLoader: false
          });
          this.phonePopup.current.classList = "getPhoneNumberBox"
          this.setState({ hasOpenPhone: false, mobileNumber: '' })
          alert("Some Error Occurred During Sending SMS!!")
          if (
            error?.response?.status === 400 ||
            error?.response?.status === 401 ||
            error?.response?.status === 403
          ) {
            localStorage.clear();
            this.props.history.push("/logout");
          }
        });
    }
    else {
      alert('Please Enter a Valid Number')
    }
  }

  getReportType() {
    const accout = localStorage.getItem('userName');

    if (this.state.token !== null && accout !== null) {

      axios
        .get(
          `${process.env.REACT_APP_API_URL
          }api/OobRates/getReportType?email=${accout}`, {
          cancelToken: this.state.cancleToken.token
        }
        )
        .then((response) => {
          localStorage.setItem("reportType", JSON.stringify(response.data[0]));
          // if (response.data[0].searching_Status !== 1) {
          //   this.props.history.push("/");
          // }
        })
        .catch((error) => {
          if (
            error?.response?.status === 400 ||
            error?.response?.status === 401 ||
            error?.response?.status === 403
          ) {
            localStorage.clear()
            this.props.history.push("/logout");
          }
        });

    } else {
      this.setState({
        errorMessage: "You need to login first"
      });
    }
  }

  getAllSetting() {
    axios
      .get(
        `${process.env.REACT_APP_API_URL
        }api/OobRates/getoobratesSetting`,
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
            secret_key: localStorage.getItem("secret_key"),
          },
        }
      )
      .then((response) => {
        // console.log(response.data)
        this.setState({
          Setting: response.data,
        });
      })
      .catch((error) => {
        if (
          error.response.status === 400 ||
          error.response.status === 401 ||
          error.response.status === 403
        ) {
          localStorage.clear()
          this.props.history.push("/logout");
        }
      });
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
    this.refreshToken();
    const oopfl = JSON.parse(sessionStorage.getItem("oopfl"))
    const oopflcus = JSON.parse(sessionStorage.getItem("oopflcus"))
    const searchingStatus = JSON.parse(localStorage.getItem("reportType"));
    let codeDescription = JSON.parse(sessionStorage.getItem("codeDiscription"));
    if (oopfl == null && oopflcus == null || oopfl == undefined && oopflcus == undefined || searchingStatus.searching_Status !== 1) {
      this.props.history.push("/");
    }
    else {

      let codeDiscriptionFilter = [];
      if (oopfl !== null) {
        this.getOOPRates();
        codeDiscriptionFilter = codeDescription.filter(i => i.value === oopfl.code)
      }
      else {
        this.oopCustomSubmit()
        codeDiscriptionFilter = codeDescription?.filter(i => i.value === oopflcus.code)
      }
      this.getaccessAllState();
      this.handleStateChange();
      this.getAllSetting();

      this.setState({
        codeDiscription: codeDescription,
        searchText: codeDiscriptionFilter[0]
      })

    }

  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
    this.state.cancleToken.cancel()
  }

  render() {
    return (
      <>
        {this.state.token !== null ? <AfterLoginMenu /> : <BeforeLoginMenu />}
        <style>{CUSTOM_CSS}</style>
        {this.state.showLoader && (
          <div className="loader-bg" id="preloader">
            <div className="loader"></div>
          </div>
        )}
        <main role="main">
          <div className="pt-4 pb-5">
            <div className="container">
              <div className="form OOPPg_filter">
                {/* <div className=" text-center font-weight-bold pb-3">
                  <a
                    className="pivotVideoLink"
                    href="https://youtu.be/iD5no5l8tT8"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    To Learn More -&gt; Watch Pivot Explainer Video
                  </a>
                  <br /> <a className="pivotVideoLink" target="_blank" href="https://datacatalog.urban.org/dataset/betos-20-classification-code-assignments-2019/resource/76dee1e5-5c70-492f-83ac-36ffd691e93c">Betos Categories</a>
                </div> */}

                {
                  this.state.errorMessage ? <><div className="row justify-content-center"><div className="col-md-3"></div><div className="col-md-6"><div className="alert alert-danger alert-dismissible fade show" role="alert">
                    {this.state.errorMessage}
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.alertChange}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div></div><div className="col-md-3"></div></div> </> :
                    ''
                }

                {/* main filter  */}
                <div className="row gray-bg">
                  <form className="w-100">
                    <div className="col-md-12">

                      <div className="row pivotFilter-box">
                        <div className="col-md-6 col-sm-6 ">
                          <div className="form-group d-flex align-items-center my-3 ">
                            <label className="d-inline-block bold_text white-text col-md-4 m-0">
                              State:
                            </label>

                            <select
                              className="md-form  mdb-select white-bg col-md-8 custom_select form-control"
                              name="selectState"
                              onChange={(e) => this.handleStateChange(e)}
                              required
                              value={this.state.stateName}
                            >
                              <option value="">Select State</option>
                              {this.state.states
                                .sort((a, b) => a.stateName.localeCompare(b.stateName))
                                .map((stateArr, index) => (
                                  <option
                                    key={index}
                                    value={
                                      stateArr.stateName
                                    }
                                  >
                                    {`${stateArr.stateName} (${stateArr.stateCode})`}
                                  </option>
                                ))}
                            </select>

                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <div className="form-group d-flex align-items-center my-3">
                            <label className="d-inline-block bold_text white-text col-md-3 m-0">
                              METRO AREA:
                            </label>
                            <select
                              className="md-form  mdb-select white-bg col-md-9 custom_select form-control pr-0"
                              name="selectMsa"
                              onChange={(e) => this.handleMsaChange(e)}
                              required
                              value={this.state.msaID}
                            >
                              <option value="">Select Metro Area</option>
                              {this.state.msa !== undefined ?
                                this.state.msa.map((msaArr, index) => (
                                  <option key={index} value={msaArr.msaID} msaname={msaArr.MSA}>{msaArr.MSA}</option>
                                ))
                                : ''}
                            </select>

                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <div className="form-group d-flex align-items-center my-3">
                            <label className="d-inline-block bold_text white-text col-md-4  m-0">
                              Medical Code:
                            </label>
                            <div style={{ width: '100%' }} className=" medicle_code_search_wrp">
                              <AsyncSelect
                                ref={this.selectRef}
                                cacheOptions
                                placeholder={'Medical Code / Code Description'}
                                noOptionsMessage={() => this.state.searchText === '' ? 'Please enter some value to search Medical Code / Code Description' : 'Nothing found'}
                                loadingMessage={() => 'Searching...'}
                                defaultOptions={this.state.codeDiscription}
                                defaultValue={this.state.medicalCode}
                                loadOptions={this.loadOptions}
                                getOptionValue={this.getOptionValue}
                                getOptionLabel={this.getOptionLabel}
                                onInputChange={(e) => this.handleSearchChange(e)}
                                onChange={this.handelCodeSelect}
                                isClearable
                                isDisabled={this.state.stateName == '' || this.state.msaName == '' || this.state.msaID == '' ? true : false}
                                className="color-text"
                              />
                            </div>
                          </div>

                        </div>

                        <div className="col-md-6 col-sm-6">
                          <div className="form-group d-flex align-items-center my-3">
                            <label className="d-inline-block bold_text white-text col-md-3 m-0">
                              Setting:
                            </label>

                            <select
                              className="md-form  mdb-select white-bg col-md-9 custom_select form-control pr-0"
                              name="paymentMethod"
                              onChange={(e) => this.handleSettingChange(e)}
                              required
                              value={this.state.paymentMethod}
                              disabled={this.state.token === null || !this.state.Setting.length}

                            >
                              <option value="">Select Setting</option>
                              {
                                this.state.Setting.length > 0 ?
                                  this.state.Setting
                                    // .sort((a, b) => a.localeCompare(b))
                                    .map((stateArr, index) => (
                                      <option
                                        value={
                                          stateArr
                                        }
                                        key={index}
                                      >
                                        {stateArr}
                                      </option>
                                    ))
                                  : ''
                              }
                            </select>

                          </div>
                        </div>


                        <div className="col-12">
                          {
                            this.state.needModal === null || this.state.needModal === undefined ?
                              <button
                                className="exportBTN btn-orange e-control text-right m-0 mb-3 e-btn e-lib e-flat e-primary "
                                disabled={this.state.stateName == '' || this.state.medicalCode == '' || this.state.msaID === '' || this.state.paymentMethod == ''}
                                data-toggle="modal" data-target="#OOP_Modal"
                                onClick={(e) => e.preventDefault()}
                              >
                                Get OOP Cost
                              </button>
                              :
                              <button
                                className="exportBTN btn-orange e-control text-right m-0 mb-3 e-btn e-lib e-flat e-primary "
                                disabled={this.state.stateName == '' || this.state.medicalCode == '' || this.state.msaID === '' || this.state.paymentMethod == ''}
                                onClick={this.handleFilterSubmit}
                              >
                                Get OOP Cost
                              </button>
                          }

                        </div>
                        <div className="clearfix"></div>
                      </div>

                    </div>
                  </form>

                  {/* oop modal  */}
                  <div className="modal fade" id="OOP_Modal" data-backdrop="static">
                    <div className="modal-dialog modal-dialog-scrollable oop_modal">
                      <div className="modal-content ">
                        <div className="modal-body ">
                          <button type="button" className="close" data-dismiss="modal">
                            &times;
                          </button>
                          <div className="form-group">
                            <label className="oopLabel d-block">Select family status: </label>
                            <div className="custom-control custom-radio custom-control-inline">
                              <input type="radio" id="oopFamilyStatus1" name="oopFamilyStatus" className="custom-control-input" 
                                onChange={() => this.setState({ lives: '1' })} />
                                {/* checked={this.state.lives === '1'} */}
                              <label className="custom-control-label" htmlFor="oopFamilyStatus1">Single</label>
                            </div>
                            <div className="custom-control custom-radio custom-control-inline">
                              <input type="radio" id="oopFamilyStatus2" name="oopFamilyStatus" className="custom-control-input" 
                                onChange={() => this.setState({ lives: '2' })} />
                                {/* checked={this.state.lives === '2'} */}
                              <label className="custom-control-label" htmlFor="oopFamilyStatus2">Family</label>
                            </div>
                          </div>

                          <div className="form-group">
                            <label className="oopLabel">Enter YTD medical spend: </label>
                            <input type="number" className="form-control mt-1" placeholder="YTD medical spend: $$$$$" value={this.state.accumulator || ''} onChange={(e) => this.setState({ accumulator: e.target.value })} disabled={this.state.token === null} pattern="[0-9]*" inputMode="numeric" />
                          </div>

                          <button className="btn btn-orange bannerseachPlaceHoldercolor m-auto d-block roundborder" data-dismiss="modal" disabled={this.state.stateName == '' || this.state.accumulator == '' || this.state.msaName === '' || this.state.lives === '' || this.state.accumulator === '' || !this.numberValid(this.state.accumulator) || this.state.paymentMethod == ''} onClick={this.oopCustomSubmit}>Get OOP Cost</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="container">
              {this.state.isLoaded && (
                <>
                  <div className="row">
                    <div
                      className="col-lg-12 property-section oop-actions-btns"
                      style={{ paddingRight: 0 }}
                    >
                      {
                        this.state.needModal === null || this.state.needModal === undefined ?
                          <ButtonComponent
                            onClick={this.handleExportClickcustom.bind(this)}
                            cssclassName="e-flat"
                            isPrimary={true}
                            className="exportBTN btn-orange"
                            disabled={this.state.hasData || this.state.stateName == '' || this.state.medicalCode == '' || this.state.msaID === ''}
                          >
                            Download <i className="fa fa-download ml-2"></i>
                          </ButtonComponent>
                          :
                          <ButtonComponent
                            onClick={this.handleExportClick.bind(this)}
                            cssclassName="e-flat"
                            isPrimary={true}
                            className="exportBTN btn-orange"
                            disabled={this.state.hasData || this.state.stateName == '' || this.state.medicalCode == '' || this.state.msaID === ''}
                          >
                            Downloads <i className="fa fa-download ml-2"></i>
                          </ButtonComponent>
                      }

                      <div className="oop-send-msg-box">

                        <button className={`btn btn-orange oop-send-msg-btn send-me-text ${this.state.hasOpenPhone ? 'needClose' : ''}`} onClick={this.openNumberPopup} disabled={this.state.hasData || this.state.stateName == '' || this.state.medicalCode == '' || this.state.msaID === ''}>{this.state.hasOpenPhone ? <i className="fa fa-times" aria-hidden="true"></i> : 'Text me a report'}</button>

                        <div className="getPhoneNumberBox" ref={this.phonePopup}>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <select className="custom-select" title="Country Code" defaultValue={this.state.countryNumber} onChange={(e) => this.setState({ countryNumber: e.target.value })}>
                                <option value={"+1"} selected>+1</option>
                                {/* <option value={"+91"} selected>+91</option> */}
                              </select>
                            </div>
                            <input type="tel" className="form-control" placeholder="Enter Phone Number" aria-label="Enter Phone Number" aria-describedby="basic-addon2" pattern="[0-9]*" inputMode="numeric" value={this.state.mobileNumber} onChange={(e) => this.setState({ mobileNumber: e.target.value })} />
                            <div className="input-group-append">
                              <button className="btn btn-outline-secondary" title="Send" type="button" onClick={this.sendSms}><i className="fa fa-paper-plane" aria-hidden="true"></i></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 Ooprates">
                      <PivotViewComponent
                        id="PivotViewFieldList"
                        ref={(d) => (this.pivotObj = d)}
                        enginePopulated={this.afterPivotPopulate.bind(this)}
                        allowExcelExport={true}
                        allowPdfExport={true}
                        enableValueSorting={true}
                        width={"100%"}
                        height={"730"}
                        gridSettings={{
                          columnWidth: 140,
                        }}
                      ></PivotViewComponent>
                      <PivotFieldListComponent
                        id="PivotFieldList"
                        ref={(d) => (this.fieldlistObj = d)}
                        enginePopulated={this.afterPopulate.bind(this)}
                        dataSourceSettings={this.state.dataSourceSettings}
                        renderMode={"Fixed"}
                        allowCalculatedField={false}
                      >
                        <Inject services={[CalculatedField]} />
                      </PivotFieldListComponent>

                    </div>

                    <div className=" text-center font-weight-bold col-md-12 mt-4">
                      <p className="oop_nots">Hospitals can change their prices without notice.  Always get a Good Faith Estimate first to lock in your rate.</p>
                    </div>

                  </div>
                </>
              )}
            </div>
          </div>
        </main>
      </>
    );
  }

  afterPopulate() {
    this.pivotObj =
      document.getElementById("PivotViewFieldList").ej2_instances[0];
    this.fieldlistObj =
      document.getElementById("PivotFieldList").ej2_instances[0];
    this.fieldlistObj.updateView(this.pivotObj);

    this.setState({
      showLoader: false,
      dataSourceSettings: "",
    });
  }
  afterPivotPopulate() {
    this.pivotObj =
      document.getElementById("PivotViewFieldList").ej2_instances[0];
    this.fieldlistObj =
      document.getElementById("PivotFieldList").ej2_instances[0];
    this.fieldlistObj.update(this.pivotObj);

  }
  rendereComplete() {
    this.fieldlistObj.updateView(this.pivotObj);
  }
  ondataBound() {
    this.pivotObj.tooltip.destroy();
    if (Browser.isDevice) {
      this.pivotObj.element.style.width = "100%";
      this.pivotObj.allowCalculatedField = true;
      this.pivotObj.showFieldList = true;
    }
    this.pivotObj.refresh();
  }
  onLoad() {
    if (Browser.isDevice) {
      this.renderMode = "Popup";
      this.target = ".control-section";
      setStyleAttribute(document.getElementById("PivotFieldList"), {
        width: 0,
        height: 0,
        float: "left",
        display: "none",
      });
    }
  }
}
export default withRouter(OopRates);
