import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import BeforeLoginMenu from "../../routes/BeforeLoginMenu";
import AfterLoginMenu from "../../routes/AfterLoginMenu";

class ForgotPassword extends Component {
    constructor(props) {
        super(props);

        const token = localStorage.getItem("token");
        if (token) {
            this.props.history.push("/profile");
        }

        this.state = {
            token: localStorage.getItem("token"),
            email: "",
            errorMessage: "",
            successMessage: "",
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    handleSubmit(event) {
        const { email } = this.state;
        axios
            .post(`${process.env.REACT_APP_API_URL}api/auth/ForgetPassword`, {
                Email: email,
            })
            .then((response) => {
                if (response.data.isSuccess) {
                    this.setState({
                        successMessage: response.data.message,
                        errorMessage: "",
                        email: "",
                    });
                }
            })
            .catch((error) => {
                if (error.response) {
                    this.setState({
                        errorMessage: "User not found",
                        successMessage: "",
                    });
                }
            });
        event.preventDefault();
    }

    render() {
        return (
            <>
                {this.state.token !== null ? (
                    <AfterLoginMenu />
                ) : (
                    <BeforeLoginMenu />
                )}
                <main role="main">
                    <div className="login_bg">
                        <div className="pt-5 pb-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6 mx-auto text-center bg-orange">
                                        <div className="login-wrap p-4 p-md-5">
                                            <h3 className="page-title text-white mb-5 font-weight-bold">
                                                Forgot Password
                                            </h3>

                                            {this.state.errorMessage !== "" && (
                                                <div
                                                    className="alert alert-danger alert-dismissible fade show"
                                                    role="alert"
                                                >
                                                    {this.state.errorMessage}
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="alert"
                                                        aria-label="Close"
                                                    ></button>
                                                </div>
                                            )}
                                            {this.state.successMessage !==
                                                "" && (
                                                <div
                                                    className="alert alert-success alert-dismissible fade show"
                                                    role="alert"
                                                >
                                                    {this.state.successMessage}
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="alert"
                                                        aria-label="Close"
                                                    >
                                                        <span
                                                            aria-hidden="true"
                                                            onClick={
                                                                this.handleClose
                                                            }
                                                        >
                                                            &times;
                                                        </span>
                                                    </button>
                                                </div>
                                            )}
                                            <form
                                                className="login-form"
                                                onSubmit={this.handleSubmit}
                                            >
                                                <p className="small-p">
                                                    Enter the email address you
                                                    used to sign up on
                                                    Healthcost Lab and we will
                                                    send you a link to reset
                                                    your password.
                                                </p>

                                                <div className="form-group">
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        value={this.state.email}
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                        className="form-control text-center mb-3"
                                                        placeholder="Email Address"
                                                        required
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-orange mb-2"
                                                    >
                                                        SUBMIT
                                                    </button>
                                                </div>
                                                <div className="form-group d-md-flex">
                                                    <div className="w-100">
                                                        <Link
                                                            to="/login"
                                                            className="text-white text-bold"
                                                        >
                                                            Click here to login
                                                        </Link>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}

export default withRouter(ForgotPassword);
