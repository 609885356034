import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import BeforeLoginMenu from "../../routes/BeforeLoginMenu";
import AfterLoginMenu from "../../routes/AfterLoginMenu";
import axios from "axios";
const CUSTOM_CSS = `
.donation_btn, .donation_popup 
{
    display:none;
}
`;
class Donation extends Component {
    constructor(props) {
        super(props);
        if (process.env.NODE_ENV !== 'development') {
            document.addEventListener('contextmenu', (e) => {
                e.preventDefault();
            }
            );
            document.onkeydown = function (e) {
                if (e.keyCode == 123) {
                    return false;
                }
                if (e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
                    return false;
                }
                if (e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
                    return false;
                }
                if (e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
                    return false;
                }
                if (e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
                    return false;
                }
            }
        }
        const token = localStorage.getItem("token");
        this.ref = React.createRef()
        this.state = {
            token: token,
            items: [],
            paymentType: "card",
            cardType: "",
            cardName: "",
            cardNumber: "",
            expiryDate: "",
            cvv: "",
            routingNumber: "",
            accountNumber: "",
            accountName: "",
            bankName: "",
            accountType: "",
            firstName: "",
            lastName: "",
            address: "",
            city: "",
            state: "",
            zipcode: "",
            successMessage: "",
            errorMessage: "",
            paymentInProgress: false,
            DonateAmount: null,
            email: ""
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);



    }


    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }


    handlePaymentChange(type) {
        this.setState({
            paymentType: type,
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        
        this.setState({ paymentInProgress: true })
        const {
            paymentType,
            cardType,
            cardName,
            cardNumber,
            expiryDate,
            cvv,
            routingNumber,
            accountNumber,
            accountName,
            bankName,
            firstName,
            lastName,
            address,
            city,
            state,
            zipcode,
            email,
            DonateAmount
        } = this.state;

        console.log(this.state.email)
        if (paymentType === "card") {
            const cardArr = {

                itemscredit: {
                    "data": [
                        {
                            "actualPrice": +DonateAmount
                        }
                    ]
                },
                paymentDetailscredit: {
                    cardType: cardType,
                    cardName: cardName,
                    cardNumber: cardNumber,
                    expiryDate: expiryDate,
                    cvv: cvv
                },
                billingAddresscredit: {
                    firstName: firstName,
                    lastName: lastName,
                    address: address,
                    city: city,
                    state: state,
                    zipcode: zipcode,
                    email: email,
                },
            };
            axios
                .post(
                    `${process.env.REACT_APP_API_URL}api/Payment/DonationCardPayment`,
                    {
                        items: cardArr["itemscredit"],
                        paymentDetails: cardArr["paymentDetailscredit"],
                        billingAddress: cardArr["billingAddresscredit"],
                    }
                )
                .then((response) => {
                    if (response.data.transactionResponse.responseCode == 1) {
                        this.setState({
                            successMessage:
                                response.data.messages.message[0].text,
                            paymentInProgress: false
                        });
                        this.ref.current.click();
                        this.props.history.push("/thankYou");
                    } else {
                        this.ref.current.click()
                        setTimeout(() => {
                            window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                            });
                        }, 800);
                        this.setState({
                            errorMessage:
                                response.data.transactionResponse.errors[0]
                                    .errorText,
                            successMessage: "",
                            paymentInProgress: false
                        });

                    }
                })
                .catch((error) => {
                    // console.log(error)
                    this.ref.current.click();
                    setTimeout(() => {
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                        });
                    }, 800);
                    this.setState({
                        errorMessage: 'Payment request failed. Please provide valid data.',
                        successMessage: "",
                        paymentInProgress: false
                    });

                });
        } else {
            const echeckArr = {
                items: {
                    "data": [
                        {
                            "actualPrice": +DonateAmount
                        }
                    ]
                },
                paymentDetails: {
                    routingNumber: routingNumber,
                    accountNumber: accountNumber,
                    accountName: accountName,
                    bankName: bankName,
                    accountType: "",
                },
                billingAddress: {
                    firstName: firstName,
                    lastName: lastName,
                    address: address,
                    city: city,
                    state: state,
                    zipcode: zipcode,
                    email: email
                },
            };
            axios
                .post(
                    `${process.env.REACT_APP_API_URL}api/Payment/DonationCeckPayment`,
                    {
                        items: echeckArr["items"],
                        paymentDetails: echeckArr["paymentDetails"],
                        billingAddress: echeckArr["billingAddress"],
                    }
                )
                .then((response) => {
                    if (response.data.transactionResponse.responseCode == 1) {

                        this.setState({
                            successMessage:
                                response.data.messages.message[0].text,
                            paymentInProgress: false
                        });
                        this.ref.current.click()

                        this.props.history.push("/thankYou");
                    } else {
                        this.ref.current.click()
                        setTimeout(() => {
                            window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'smooth'
                            });
                        }, 800);
                        this.setState({
                            errorMessage:
                                response.data.transactionResponse.errors[0]
                                    .errorText,
                            successMessage: "",
                            paymentInProgress: false
                        });

                    }
                })
                .catch((error) => {
                    // console.log(error);
                    this.ref.current.click();
                    setTimeout(() => {
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                        });
                    }, 800);

                    this.setState({
                        errorMessage: 'Payment request failed. Please provide valid data.',
                        successMessage: "",
                        paymentInProgress: false
                    });

                });
        }
    }

    componentDidMount() {
        let loggeduid = localStorage.getItem("email");
        let unloggeduid = sessionStorage.getItem("email");
        if (loggeduid == null && unloggeduid == null || loggeduid == undefined && unloggeduid == undefined) {
            this.props.history.push("/");
        } else if (loggeduid != null || loggeduid != undefined) {
            this.setState({ email: loggeduid })
        }
        else {
            this.setState({ email: unloggeduid })
        }
    }

    numberValid(num) {
        const re = /^[1-9]\d*(\.\d{1,2})?$/;
        return !re.test(num)
      }


    render() {
        return (
            <>
                <style>{CUSTOM_CSS}</style>
                {this.state.token !== null ? (
                    <AfterLoginMenu />
                ) : (
                    <BeforeLoginMenu />
                )}
                <main role="main" className="">
                    <div className="pt-3 pb-5">
                        <div className="container">
                            {this.state.errorMessage !== "" && (
                                <div
                                    className="alert alert-danger alert-dismissible fade show"
                                    role="alert"
                                >
                                    {this.state.errorMessage}
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="alert"
                                        aria-label="Close"
                                    ></button>
                                </div>
                            )}
                            {this.state.successMessage !== "" && (
                                <div
                                    className="alert alert-success alert-dismissible fade show"
                                    role="alert"
                                >
                                    {this.state.successMessage}
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="alert"
                                        aria-label="Close"
                                    ></button>
                                </div>
                            )}
                            <form onSubmit={this.handleSubmit}>


                                <div className="row justify-content-center">
                                    <div className="col-12 col-lg-5">
                                        <h3 className="bold_text_no_text_transform font20">
                                            Help us fight the high cost of healthcare
                                        </h3>
                                        <hr className="border border-warning" />
                                        <div className="form-group">
                                            <label className="font-weight-bold">
                                                Enter donate amount.
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend ">
                                                    <span className="input-group-text border border-warning">$</span>
                                                </div>
                                                <input type="text" name="DonateAmount" required className="form-control border border-warning" aria-label="Amount (to the nearest dollar)" onChange={
                                                    this.handleChange}></input>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12"></div>
                                    <aside className="col-lg-6">
                                        <h3 className="bold_text_no_text_transform font20 pl-3">
                                            Payment information
                                        </h3>
                                        <hr className="border border-warning" />
                                        <div className="card-body check-mobile-pad">
                                            <ul
                                                className="nav bg-light nav-pills rounded nav-fill mb-3"
                                                role="tablist"
                                            >
                                                <li
                                                    className="nav-item"
                                                    onClick={() => {
                                                        this.handlePaymentChange(
                                                            "card"
                                                        );
                                                    }}
                                                >
                                                    <a
                                                        className="nav-link active"
                                                        data-toggle="pill"
                                                        href="#nav-tab-card"
                                                    >
                                                        <i className="fa fa-credit-card"></i>{" "}
                                                        Credit / Debit Card
                                                    </a>
                                                </li>
                                                <li
                                                    className="nav-item"
                                                    onClick={() => {
                                                        this.handlePaymentChange(
                                                            "echeck"
                                                        );
                                                    }}
                                                >
                                                    <a
                                                        className="nav-link"
                                                        data-toggle="pill"
                                                        href="#nav-tab-bank"
                                                    >
                                                        <i className="fa fa-university"></i>{" "}
                                                        Echeck Payment
                                                    </a>
                                                </li>
                                            </ul>

                                            <div className="tab-content">
                                                <div
                                                    className="tab-pane fade show active"
                                                    id="nav-tab-card"
                                                >
                                                    <div className="form-group">
                                                        <label className="font-weight-bold">
                                                            Card Type
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <div className="input-group">
                                                            <select
                                                                className="form-control border border-warning"
                                                                name="cardType"
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                            >
                                                                <option value="">
                                                                    Select
                                                                </option>
                                                                <option value="Visa">
                                                                    Visa
                                                                </option>
                                                                <option value="Master Card">
                                                                    Master Card
                                                                </option>
                                                                <option value="Discover">
                                                                    Discover
                                                                </option>
                                                                <option value="American Express">
                                                                    American
                                                                    Express
                                                                </option>
                                                            </select>

                                                            <div className="input-group-append">
                                                                <span className="input-group-text text-muted">
                                                                    <i className="fa fa-cc-visa"></i>{" "}
                                                                    <i className="fa fa-cc-amex ml-1"></i>{" "}

                                                                    <i className="fa fa-cc-mastercard"></i>
                                                                    &nbsp;
                                                                    <i className="fa fa-cc-discover"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="font-weight-bold">
                                                            Name on the Card
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="form-control border border-warning"
                                                            name="cardName"
                                                            placeholder=""
                                                            onChange={
                                                                this
                                                                    .handleChange
                                                            }
                                                        />
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="font-weight-bold">
                                                            Card Number
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <div className="input-group">
                                                            <input
                                                                type="text"
                                                                className="form-control border border-warning"
                                                                name="cardNumber"
                                                                placeholder=""
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-sm-5">
                                                            <div className="form-group">
                                                                <label>
                                                                    <span className="hidden-xs font-weight-bold">
                                                                        Expiry
                                                                        Date
                                                                        <span className="text-danger">
                                                                            *
                                                                        </span>
                                                                    </span>{" "}
                                                                </label>
                                                                <div className="input-group">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control border border-warning "
                                                                        placeholder="MM/YYYY"
                                                                        name="expiryDate"
                                                                        onChange={
                                                                            this
                                                                                .handleChange
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-3">
                                                            <div className="form-group">
                                                                <label
                                                                    className="font-weight-bold"
                                                                    data-toggle="tooltip"
                                                                    title=""
                                                                    data-original-title="3 digits code on back side of the card"
                                                                >
                                                                    CVV
                                                                    <span className="text-danger">
                                                                        *
                                                                    </span>
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control border border-warning"
                                                                    onChange={
                                                                        this
                                                                            .handleChange
                                                                    }
                                                                    name="cvv"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="tab-pane fade"
                                                    id="nav-tab-bank"
                                                >
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label font-weight-bold">
                                                            Routing Number
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="routingNumber"
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label font-weight-bold">
                                                            Account Number
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="accountNumber"
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label font-weight-bold">
                                                            Name on Bank Account
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="accountName"
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label font-weight-bold">
                                                            Bank Name
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                name="bankName"
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group row">
                                                        <label className="col-sm-3 col-form-label font-weight-bold">
                                                            Account type
                                                            <span className="text-danger">
                                                                *
                                                            </span>
                                                        </label>
                                                        <div className="col-sm-9">
                                                            <select
                                                                name="accountType"
                                                                className="form-control"
                                                                onChange={
                                                                    this
                                                                        .handleChange
                                                                }
                                                            >
                                                                <option value="Savings">
                                                                    Savings
                                                                </option>
                                                                <option value="Checking">
                                                                    Checking
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </aside>
                                    <aside className="col-lg-6">
                                        <h3 className="bold_text_no_text_transform font20 pl-3">
                                            Billing information
                                        </h3>
                                        <hr className="border border-warning" />

                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label font-weight-bold">
                                                First Name
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <div className="col-sm-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="firstName"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label font-weight-bold">
                                                Last Name
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <div className="col-sm-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="lastName"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label font-weight-bold">
                                                Address
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <div className="col-sm-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="address"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label font-weight-bold">
                                                City
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <div className="col-sm-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="city"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label font-weight-bold">
                                                State
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <div className="col-sm-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="state"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label className="col-sm-3 col-form-label font-weight-bold">
                                                Zip Code
                                                <span className="text-danger">
                                                    *
                                                </span>
                                            </label>
                                            <div className="col-sm-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="zipcode"
                                                    onChange={this.handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            {
                                                this.state.paymentType === 'card' ?
                                                    <button type="submit" className="btn btn-orange mb-2 float-right"
                                                        disabled={this.state.cardType === '' || this.state.cardName === '' || this.state.cardNumber === '' || this.state.expiryDate === '' || this.state.cvv === '' || this.state.firstName === '' || this.state.lastName === '' || this.state.address === '' || this.state.city === '' || this.state.state === '' || this.state.zipcode === '' || this.state.paymentInProgress || this.numberValid(this.state.DonateAmount)} data-toggle="modal" data-target="#payment_process_Modal">
                                                        Make Payment
                                                    </button>
                                                    :
                                                    <button type="submit" className="btn btn-orange mb-2 float-right"
                                                        disabled={this.state.routingNumber === '' || this.state.accountNumber === '' || this.state.accountName === '' || this.state.bankName === '' || this.state.firstName === '' || this.state.lastName === '' || this.state.address === '' || this.state.city === '' || this.state.state === '' || this.state.zipcode === '' || this.state.paymentInProgress || this.numberValid(this.state.DonateAmount)} data-toggle="modal" data-target="#payment_process_Modal">
                                                        Make Payment
                                                    </button>
                                            }

                                        </div>
                                    </aside>

                                </div>
                            </form>


                            {/* payment Under process modal  */}
                            <div className="modal fade" id="payment_process_Modal" data-backdrop="static">
                                <div className="modal-dialog modal-dialog-scrollable payment_process_modal">
                                    <div className="modal-content ">

                                        <div className="modal-body ">
                                            <span data-dismiss="modal" ref={this.ref} ></span>
                                            <div className="loader"></div>
                                            <p>Your payment is under process. Please do not refresh the page or hit back button now.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </>
        );
    }
}
export default withRouter(Donation);
